export function moveToFirstById(array, targetId) {
  // 先找到目标项
  const item = array.find(item => item.id === targetId)
  if (!item) return array

  // 过滤掉目标项，然后在开头添加
  return [item, ...array.filter(item => item.id !== targetId)]
}


export function getSaStyleByType(type) {
  const styleMap = {
    'toast': 'A',
    'tip': 'B',
    'popover': 'C',
  }
  return styleMap[type]
}


export function copyText(text) {
  navigator.clipboard?.writeText(text)
}
