<template>
  <transition name="cart-newGoodsRecommendToast--fade">
    <div
      v-if="show"
      v-expose="{
        id: 'expose_new_recommend.page_cart',
        data: {
          style: getSaStyleByType('toast'),
        },
      }"
      class="cart-newGoodsRecommendToast not-fsp-element"
      :style="rootStyle"
      @click.stop="onClickView('body')"
    >
      <div class="cart-newGoodsRecommendToast__header">
        <div class="cart-newGoodsRecommendToast__header-left">
          <div class="cart-newGoodsRecommendToast__header-content">
            <div class="cart-newGoodsRecommendToast__header-title">
              <ResizeFont trigger="Mounted">
                <ResizeFontText
                  :init-font-size="14"
                  :resize-font-min="12"
                  :resize-font-step="1"
                >
                  {{ newRecommendExposure.mainText }}
                </ResizeFontText>
              </ResizeFont>
            </div>
            <div class="cart-newGoodsRecommendToast__header-sub">
              <ResizeFont trigger="Mounted">
                <ResizeFontText
                  :init-font-size="11"
                  :resize-font-min="10"
                  :resize-font-step="1"
                >
                  {{ newRecommendExposure.subText }}
                </ResizeFontText>
              </ResizeFont>
            </div>
          </div>
          <div
            class="cart-newGoodsRecommendToast__header-view"
            @click.stop="onClickView('view')"
          >
            {{ newRecommendExposure.btnText }}
          </div>
        </div>
        <div
          class="cart-newGoodsRecommendToast__header-right"
          @click.stop="onClickClose()"
        >
          <sui_icon_close_16px_2
            size="18px"
            color="#959595"
            class="cart-newGoodsRecommendToast__header-close"
          />
        </div>
      </div>
      <div
        v-if="newRecommendExposureItems.length"
        class="cart-newGoodsRecommendToast__content"
      >
        <div
          v-for="(item, index) in newRecommendExposureItems"
          :key="index"
          class="cart-newGoodsRecommendToast__content-item"
        >
          <BSingleImageItem
            :use="singleImageItemUse"
            :constant="constant"
            :language="language"
            :config="singleImageItemConfig"
            :data="item"
            class="cart-newGoodsRecommendToast__single-image-item"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { hexToRgb } from '@shein/common-function'
import { sui_icon_close_16px_2 } from '@shein-aidc/icon-vue2'
import { BSingleImageItem, getConstant } from '@shein-aidc/bs-cart-item-mobile-vue2'
import ResizeFont from '../common/ResizeFont.vue'
import ResizeFontText from '../common/ResizeFontText.vue'
import { useNewGoodsRecommend } from './useNewGoodsRecommend'
import { useStore } from '../../hooks/useStore'
import { getSaStyleByType } from './utils'
import { useCacheClose } from './useCacheClose'

const { state } = useStore()

const constant = computed(() => getConstant())
const language = computed(() => state.language)
const singleImageItemUse = ref([
  'GoodsImg',
  'GoodsBelt',
])
const singleImageItemConfig = ref({
  GoodsImg: {
    props({ data }) {
      return {
        imgUrl: data.value.goodsImg,
        isClick: false,
      }
    },
  },
  GoodsBelt: {
    props({ data }) {
      const belt = data.value.productImgBeltList?.[0]
      if (!belt) {
        return {
          isShow: false,
        }
      }
      return {
        isShow: true,
        isShowMask: false,
        beltText: belt.text || '',
        fontColor: belt.textColor || '',
        bgColor: hexToRgb({ hex: belt.bgColor || '#000000', opa: belt.bgColorAlpha || '0.6' }),
      }
    },
  },
})

const { hasNewRecommendInfo, newRecommendExposure, newRecommendExposureItems, openNewRecommendDrawer } = useNewGoodsRecommend()

const rootStyle = computed(() => ({
  '--bg-img': newRecommendExposure.value.bgImg ? `url(${newRecommendExposure.value.bgImg})` : 'linear-gradient(270deg, #CDF6CA 1.28%, #E3FAE1 100%)',
}))

const { isCacheClose, handleClickClose } = useCacheClose({ cacheKey: 'cart-newGoodsRecommendToast__isClose' })

const show = computed(() => hasNewRecommendInfo.value && !isCacheClose.value)

let timer = null

watch(() => show.value, (val) => {
  if (val) {
    timer = setTimeout(() => {
      onClickClose(true)
    }, 5000)
  }
})

const onClickView = (type) => {
  daEventCenter.triggerNotice({
    id: 'click_new_recommend.page_cart',
    extraData: {
      style: getSaStyleByType('tip'),
      button_type: type,
    },
  })
  onClickClose(true)
  openNewRecommendDrawer({ type: 'toast' })
}

const onClickClose = (skipExpose) => {
  if (!skipExpose) {
    daEventCenter.triggerNotice({
      id: 'click_new_recommend.page_cart',
      extraData: {
        style: getSaStyleByType('toast'),
        button_type: 'close',
      },
    })
  }

  handleClickClose()

  clearTimeout(timer)
  timer = null
}
</script>

<style lang="less">
.cart-newGoodsRecommendToast {
  position: fixed;
  z-index: 20;
  top: 12px;
  margin: 0 12px;
  padding: 8px;
  width: calc(10rem - 24px);
  background-image: var(--bg-img);
  background-color: #E3FAE1;
  background-size: 65px;
  background-position: top 0 right 32px;
  background-repeat: no-repeat;
  border-radius: 12px;
  border: 1px solid #ccf0cc;
  box-shadow: 0px 0px 8px 0px rgba(38, 38, 38, 0.20);

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-left {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;
  }

  &__header-content {
    flex: 1;
    min-width: 0;
    line-height: normal;
    color: @sui_color_success;
  }

  &__header-title {
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
  }

  &__header-sub {
    font-size: 11px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__header-right {
    display: flex;
    align-items: center;
    padding: 8px 8px 0 8px;
    margin: -8px -8px 0 0;
  }

  &__header-view {
    padding: 0 4px;
    min-width: 40px;
    max-width: 49px;
    height: 24px;
    line-height: 24px;
    font-size: 11px;
    font-weight: 590;
    text-align: center;
    color: #fff;
    background: @sui_color_brand;
    border-radius: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__content {
    display: flex;
    gap: 8px;
    margin-top: 4px;
    padding: 8px;
    background: #fff;
    border-radius: 10px;
  }

  &__content-item {
    flex: 1;
    min-width: 0;
    line-height: normal;

    position: relative;
    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__single-image-item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;

    --template-width: 100%;
    --template-top-height: 100%;
    --template-gap: 0;
  }
}

.cart-newGoodsRecommendToast--fade-enter-active {
  transition: transform 0.5s ease;
}
.cart-newGoodsRecommendToast--fade-leave-active {
  transition: opacity 0.5s ease;
}

.cart-newGoodsRecommendToast--fade-enter {
  transform: translateY(-100%);
}
.cart-newGoodsRecommendToast--fade-enter-to {
  transform: translateY(0);
}
.cart-newGoodsRecommendToast--fade-leave-to {
  opacity: 0;
}
</style>
