<template>
  <div 
    ref="cardGoodsItem"
    :class="[
      'cart-list__item cart-list-item',
      `j-cart-item-${item.id}`,
    ]"
  >
    <!-- 商品行新品推荐气泡 -->
    <NewGoodsRecommendPopover v-if="showNewGoodsRecommendPopover" />

    <div
      ref="swiperContainer"
      :class="[
        'cart-swiper',
        { 'not-allow__touch-move': !allowTouchMove }
      ]"
      @scroll.passive="scrollEventHandle"
    >
      <div class="cart-swiper-slide item-content">
        <div
          :class="['cart-item-v2', 'cart-list__item-container']"
          :style="itemStyle"
        >
          <div
            v-if="batchActive"
            class="cart-list__item-checkbox"
          >
            <SCheckboxGroup
              v-model="state.batchEditItemIds"
              class="batch-checkbox"
            >
              <div @click="handleClickItemBatchCheck">
                <SCheckbox
                  :label="item.id"
                  :disabled="item.isNewcomerItem"
                  :margin-r="isNewCartrowVision ? 16 / 75 + 'rem' : 20 / 75 + 'rem'"
                />
              </div>
            </SCheckboxGroup>
          </div>
          <div 
            v-show="!batchActive && !notNeedCheckbox"
            class="cart-list__item-checkbox"
            :style="{ opacity: (item.aggregateProductBusiness.isPresent == '1' || item.isPayMemberGift == '1') ? 0 : 1 }"
            @click="onClickCheckbox"
          >
            <s-checkbox
              :label="item.id"
              :disabled="item.isDisabled"
              :value="checked"
              :margin-r="isNewCartrowVision ? 16 / 75 + 'rem' : 20 / 75 + 'rem'"
              @change="(res) => handleCheckedItem(res, item)"
            />
          </div>
          <div class="cart-list__item-content">
            <BMainCartItem
              :key="item.id"
              class="bs-cart-item"
              :constant="constant"
              :language="language"
              :use="use"
              :data="item"
              :config="config"
              @expose="handleExposeItem"
            />
          </div>
        </div>
      </div>
      <div
        ref="cartSwiperOperationTab"
        :class="[
          'operation-tab',
          'j-operation-tab',
          'cart-swiper-slide',
        ]"
      >
        <div
          v-if="isShowSwiperSimilar"
          ref="findSimilar"
          v-expose="expose_left_findsimilar(item)"
          class="opertation-item similar"
          aria-hidden="true"
          @click="onSwiperSimilar"
        >
          <span class="opertation-text">{{ language.SHEIN_KEY_PWA_16789 }}</span>
        </div>
        <div
          v-if="isShowSwiperM2W"
          ref="moveToWishlistButton"
          class="opertation-item move-to-wishlist"
          aria-hidden="true"
          @click="onSwiperM2W"
        >
          <span class="opertation-text">{{ language.SHEIN_KEY_PWA_33673 }}</span>
        </div>
        <div
          v-if="isShowSwiperShare"
          ref="shareButton"
          v-expose="expose_share(item)"
          class="opertation-item share"
          aria-hidden="true"
          @click="onSwiperShare"
        >
          <span class="opertation-text">{{ language.SHEIN_KEY_PWA_14817 }}</span>
        </div>
        <div
          class="opertation-item delete"
          aria-hidden="true"
          @click="onSwiperDelete"
        >
          <span
            class="opertation-text"
          >{{ language.SHEIN_KEY_PWA_15502 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CartItem',
}
</script>
<script setup> 
import { computed, ref, watch } from 'vue'
import { useStore } from '../../hooks/useStore'
import { useRouter } from '../../hooks/useRouter'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { BMainCartItem } from '@shein-aidc/bs-cart-item-mobile-vue2'
import { Toast } from '@shein/sui-mobile'
import { throttle, template } from '@shein/common-function'
import { markPoint } from 'public/src/services/mark/index.js'
import { getProductDetailUrl } from 'public/src/pages/goods_detail/utils/common.js'
import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import showCartDeleteDailog from 'public/src/pages/cart_v2/components/cartDeleteDialog/showCartDeleteDailog.js'
import customDetailDialogBff from 'public/src/pages/common/customDetailDialogBff/index.js'
import useChecked from 'public/src/pages/cart_v2/hooks/useChecked'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'
import { useItemEvents } from 'public/src/pages/cart_v2/hooks/item/index'
import { animationDelete, animationM2W } from 'public/src/pages/cart_v2/utils/index'
import { click_goods_del_button, click_left_findsimilar, expose_left_findsimilar, expose_share  } from 'public/src/pages/cart_v2/analysis/swiperOpertation.js'
import showCheckedTip from 'public/src/pages/cart_v2/components/checkedTip/showCheckedTip.js'
import { expose_goods_list, getSaGoodsList, click_goods_list } from 'public/src/pages/cart_v2/analysis/item/index.js'
import { getGoodsUrl, isLogin } from 'public/src/pages/common/utils/index.js'
import { getMarketingGmtcShareLink } from 'public/src/pages/cart_v2/utils/fetcher.js'
import { openCartShareDrawer, preloadCartShareImgs } from 'public/src/pages/common/cartShareDrawer/index.js'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import { useNewRecommendFlag } from 'public/src/pages/cart_v2/components/cartNewGoodsRecommend/useNewRecommendFlag.js'

const NewGoodsRecommendPopover = () => import(/* webpackChunkName: "cartv2-new-goods-recommend-popover" */'public/src/pages/cart_v2/components/cartNewGoodsRecommend/NewGoodsRecommendPopover.vue')

const AddItem = useCartAddItem()

const { getChecked } = useChecked()
const { state, getters, commit, dispatch } = useStore()
const { routerPush } = useRouter()

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  index: {
    type: Number,
    default: 0
  },
  notNeedCheckbox: {
    type: Boolean,
    default: false
  },
  actionToRefreshRecommend: {
    type: Boolean,
    default: false
  },
})

const { showNewGoodsRecommendPopover } = useNewRecommendFlag(props.item)

let lastScrollLeft = 0

const isNewCartrowVision = computed(() => getters['cart_v2/isNewCartrowVision'])
const language = computed(() => state.language)
const locals = computed(() => state.locals)
const batchActive = computed(() => state.batchActive)
const openedDeleteItemId = computed(() => state.openedDeleteItemId)
const selectFilterLabel = computed(() => state.selectFilterLabel)
const wishlistInLeftSliding = computed(() => getters['cart_v2/wishlistInLeftSliding'])
const cartAbtInfo = computed(() => state.cartAbtInfo)
const loginStatus = computed(() => state.loginStatus)

const isAddBuyGoodsOrGiftGoods = computed(() => {
  return props.item.aggregateProductBusiness?.isPresent == '1' || props.item.aggregateProductBusiness?.isAddBuy == '1' || props.item.isPayMemberGift == '1'
})
const isSwitchItem = computed(() => {
  return props.item.canChangeMallInfo || props.item.canChangeGoodInfo
})
const isInvalid = computed(() => props.item.isInvalid == '1')

// 是否展示左滑找相似按钮
const isShowSwiperSimilar = computed(() => {
  return !batchActive.value && !isInvalid.value && !props.item.isNewcomerItem
})
// 是否展示左滑移至心愿单按钮
const isShowSwiperM2W = computed(() => {
  return !batchActive.value && !isAddBuyGoodsOrGiftGoods.value && (!isInvalid.value || (isInvalid.value && !isSwitchItem.value)) && wishlistInLeftSliding.value
})
// 是否展示左滑分享按钮
const isShowSwiperShare = computed(() => {
  return !isAddBuyGoodsOrGiftGoods.value && !isInvalid.value
})

const itemStyle = computed(() => ({
  '--item-gap': isNewCartrowVision.value ? '0.213rem' : '0.2667rem',
  '--template-left-width': isNewCartrowVision.value ? '2.133rem' : '1.973rem',
  '--template-left-height': isNewCartrowVision.value ? '2.133rem' : '1.973rem',
  '--checkbox-height': isNewCartrowVision.value ? '2.133rem' : '1.973rem',
}))
const showQuickShip = computed(() => {
  return props.item?.aggregateProductBusiness?.productTags?.some(tag => tag.type === 'quickShip')
})
const checked = computed(() => {
  if (props.item.isDisabled) {
    return props.item.is_checked == '1'
  }
  return getChecked(props.item.id)
})
const click_add_collect = (defaultData) => {
  const postData = {
    index: props.index + 1,
    quickShip: showQuickShip.value ? 1 : 0
  }
  defaultData.postData = {
    ...defaultData.postData,
    ...postData
  }
  return {
    ...defaultData,
    source: wishlistInLeftSliding.value ? 'left' : 'row',
  }
}

const cardGoodsItem = ref(null)
const swiperContainer = ref(null)
const cartSwiperOperationTab = ref(null)
let allowTouchMove = ref(true)
const use = ref([
  'GoodsImg',
  'GoodsDiscountFlag',
  'GoodsBelt',
  'GoodsTitle',
  'GoodsTitleIcon',
  'GoodsSeriesFlag',
  'GoodsPrice',
  'GoodsAddWish',
  'GoodsAlert',
  'GoodsCustomContent',
  'GoodsDeleteButton',
  'GoodsSaleAttr',
  'ReducePriceHint',
  'GoodsQty',
  'IncentiveReducePrice',
  'GoodsStore',
  'BehaviorLabel',
  'ChangeMall',
  'QuickShipTag',
  'FreeShippingTag',
  'SFSTag',
  'EvoluTag',
  'VoucherTag',
  'PromotionTag',
  'DiscountNormalTag',
  'DiscountLimitTag',
  'EstimatedPrice',
  'GoodsNegativeInfo',
  'GoodsRepickButton',
  'FindSimilarText',
  'GoodsAlmostEntry',
  'GoodsAddOnTip',
])
/**
 * 跳转商详页
 * @param data 商品行数据
 */
const jumpDetail = async ({ data }) => {
  if ( batchActive.value ) return
  const {
    mall_code,
    product: { goods_url_name, goods_id, cat_id, goods_img },
    aggregateProductBusiness,
    product
  } = data
  // 付费会员赠品售罄提示语
  const payMemberGiftStockOutTips = aggregateProductBusiness?.productTags?.find(tag => tag.type === 'payMemberGift' && tag.style == '-1')?.view?.text || ''
  if (payMemberGiftStockOutTips) {
    Toast(payMemberGiftStockOutTips)
    return
  }
  markPoint('toNextPageClick', 'public')
  click_goods_list(data, (defaultDate) => {
    return {
      ...defaultDate,
      index: props.index + 1
    }
  })
  commit('addProductDetailBfCache', product)
  const url = await getProductDetailUrl({
    imgSrc: goods_img,
    langPath: gbCommonInfo.langPath,
    goods_id,
    goods_url_name,
    cat_id,
    mall_code
  })
  routerPush(url)
}

const { modifyCartCheckStatus } = useModifyCartCheckStatus()
const onDeleteSuccess = async () => {
  if (cardGoodsItem.value) {
    await animationDelete(cardGoodsItem.value)
  } 
}
const onM2WSuccess = async () => {
  const el = document.querySelector(`.j-cart-item-${props.item.id} .bsc-cart-item-goods-img__content`)
  const to = document.querySelector('.cart-header__icon-right.j-wishlist-btn')
  await animationM2W(el, { to })
}

const onSwiperSimilar = () => {
  retractScroll()
  click_left_findsimilar(props.item)
  SimilarInstance.showModal(props.item.product)
}
const handleM2W = async () => {
  if (props.item.aggregateProductBusiness?.showNewUsersBonus == '1') {
    const { maxLimitPurchaseQuantity = '' } = props.item?.aggregateProductBusiness || {}
    if (!isNaN(+maxLimitPurchaseQuantity) && +maxLimitPurchaseQuantity > 0) {
      Toast(template(maxLimitPurchaseQuantity, language.value.SHEIN_KEY_PWA_26184))
      daEventCenter.triggerNotice({ daId: '1-8-3-34' })
    }
    return
  }
  daEventCenter.triggerNotice({
    daId: '1-8-3-36',
    extraData: {
      source: wishlistInLeftSliding.value ? 'left' : 'row'
    }
  })
  commit('cart_v2/handleModal', {
    text: language.value.SHEIN_KEY_PWA_17954,
    okCallback: async () => {
      if (loginStatus.value) {
        commit('cart_v2/updateState', {
          key: 'loadingShow',
          value: true
        })
        const events = {
          onSuccess: async () => {
            commit('cart_v2/updateState', {
              key: 'loadingShow',
              value: false
            })
            await onM2WSuccess()
          } 
        }
        try {
          const res = await dispatch('cart_v2/fetchCartBatchWish', { params: { id: [props.item.id] }, events })
          commit('cart_v2/updateState', {
            key: 'loadingShow',
            value: false
          })
          if (res.code != '0') {
            Toast(res.msg)
          }
          
          // 【埋点统一】收藏埋点的统一
          daEventCenter.triggerNotice({
            daId: '1-8-1-2',
            extraData: {
              result: { data: { code: res.code } },
              code: 'cart',
              mall_code: props.item.mall_code,
              postData: {
                action: 1, // 选中发1, 取消发0
                goods_id: props.item.product.goods_id,
                sku: props.item.product.goods_sn,
                detail: props.item.product,
                index: props.index + 1,
                quickShip: showQuickShip.value ? 1 : 0
              },
              from: 'cart',
              source: wishlistInLeftSliding.value ? 'left' : 'row',
            }
          })
          return res
        } catch (error) {
          commit('cart_v2/updateState', {
            key: 'loadingShow',
            value: false
          })
        }
      } else {
        const { result, res } = await dispatch('cart_v2/handleCartLogin', { type: 'wishlist' })
        if(res.code == 0){
          if (!result.equalCart) {
            Toast({
              content: language.value.SHEIN_KEY_PWA_17961,
              duration: 5000,
            })
          }
        }
      }
    },
    cancelCallback: () => {
    },
    cancelDaId: '1-8-7-19'
  })
}
const onSwiperM2W = () => {
  retractScroll()
  handleM2W()
}
const onSwiperShare = async () => {
  retractScroll()
  daEventCenter.triggerNotice({
    daId: '2-24-2',
    bindData: {
      share_type: 'page',
      content_id: props.item.product.goods_id
    }
  })
  const { PUBLIC_CDN, langPath, host, IS_RW, SiteUID } = gbCommonInfo
  let shareLink = ''
  let goodsLink = host + langPath + getGoodsUrl(props.item.product?.goods_url_name, props.item.product?.goods_id, props.item.product?.cat_id)

  preloadCartShareImgs()

  if(isLogin()){
    let json
    json = await getMarketingGmtcShareLink({
      shareUrl: goodsLink,
      pageType: 1,
    })
    if (json?.info?.url?.indexOf('url_from') > -1) {
      shareLink = encodeURIComponent(decodeURIComponent(json.info.url) + '&share_from=' + SiteUID)
    }
  }
  if (!shareLink) {
    shareLink = goodsLink + encodeURIComponent('?share_from=' + SiteUID)
  }

  openCartShareDrawer({
    title: language.value.SHEIN_KEY_PWA_25671,
    subDesc: language.value.SHEIN_KEY_PWA_25672,
    shareText: language.value.SHEIN_KEY_PWA_25704,
    shareToast: language.value.SHEIN_KEY_PWA_25660,
    shareLink,
    pinterestImg: props.item.product.goods_img,
    getSaData: data => {
      return {
        share_type: 'page',
        content_id: props.item?.product?.goods_id,
        channel: data.channel,
      }
    },
  })
}
const onSwiperDelete = () => {
  retractScroll()
  click_goods_del_button(props.item)
  showCartDeleteDailog({
    goodsData: props.item,
    type: 'single',
    language: language.value,
    isShowOverLimitToast: true,
    events: {
      onDeleteSuccess,
      onM2WSuccess,
    },
    analysis: {
      click_add_collect
    }
  })
}

const handleChangeMall = async (data) => {
  daEventCenter.triggerNotice({
    daId: '1-8-3-78',
    extraData: {
      sku_code: data.product.sku_code 
    }
  })
  const reqParams = {
    is_checked: data.is_checked,
    append_id_list: data.appendIds,
    sku_code: data.product?.sku_code,
    id: data.id,
    mall_code: data.aggregateProductBusiness?.changeMallInfo?.lastMallCode,
    quantity: data.quantity,
    goods_id: data.product?.goods_id,
  }
  commit('changeRootSLoadingStatus', {
    show: true,
    maskTransparent: true,
  })
  try {
    const res = await dispatch('cart_v2/fetchCartUpdateAttr', reqParams)
    if (res.code == '0') {
      dispatch('showOverLimitToast', { id: data.id, isShowOverLimitToast: true })
    } else {
      Toast(res.msg)
    }
  } catch {
    // 
  } finally {
    commit('changeRootSLoadingStatus', {
      show: true,
      maskTransparent: false,
    })
  }
}

const {
  discountLimitTagEvent,
  discountNormalTagEvent,
  estimatedPriceEvent,
  goodsQtyEvent,
  goodsSaleAttrEvent,
  promotionTagEvent,
  behaviorLabelEvent,
} = useItemEvents()
const config = ref({
  GoodsPrice: {
    props() {
      return {
        pricePopoverConfig: {
          gapTarget: '.j-cart-footer'
        }
      }
    }
  },
  GoodsDiscountFlag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  BehaviorLabel: {
    position: !isNewCartrowVision.value ? 'top' : 'bottom',
    events: {
      onLabelExpose(itemOpts) {
        behaviorLabelEvent.onLabelExpose({ itemOpts }) 
      }
    }
  },
  QuickShipTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  FreeShippingTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  EvoluTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  VoucherTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  SFSTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
  },
  GoodsImg: {
    props() {
      return {
        isLazyLoad: props.index >= 6,
      }
    },
    events: {
      onClick({ data }) {
        jumpDetail({ data: data.value })
      }
    }
  },
  GoodsSeriesFlag: {
    props() {
      return {
        isLazyLoad: props.index >= 6,
      }
    }
  },
  GoodsTitle: {
    events: {
      onClick({ data }) {
        jumpDetail({ data: data.value })
      }
    }
  },
  GoodsAddWish: {
    props({ data }) {
      if (batchActive.value || wishlistInLeftSliding.value) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ data, event }) {
        handleM2W()
      }
    }
  },
  GoodsCustomContent: {
    props() {
      if (batchActive.value) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ event }) {
        daEventCenter.triggerNotice({
          daId: '1-8-7-63',
          extraData: {
            images: event.images?.length || 0,
            preview: event.effects?.length || 0,
            texts: event.texts?.length || 0,
          }
        })

        customDetailDialogBff.open({
          ...event,
        })
      }
    }
  },
  GoodsDeleteButton: {
    props() {
      if (batchActive.value) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ data }) {
        showCartDeleteDailog({
          goodsData: data.value,
          type: 'single',
          language: language.value,
          isShowOverLimitToast: true,
          events: {
            onDeleteSuccess,
            onM2WSuccess,
          },
          analysis: {
            click_add_collect
          }
        })
      }
    },
  },
  GoodsSaleAttr: {
    props({ data }) {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        goodsSaleAttrEvent.onClick({ itemOpts, options: { isShowOverLimitToast: true } })
      }
    }
  },
  GoodsQty: {
    props({}) {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onClickInput(itemOpts) {
        goodsQtyEvent.onClickInput({ itemOpts })
      },
      onDelete: (itemOpts) => {
        goodsQtyEvent.onDelete(
          {
            itemOpts,
            options: {
              isShowOverLimitToast: true,
              events: {
                onDeleteSuccess,
                onM2WSuccess,
              }
            }, 
            analysis: { click_add_collect } }
        )
      },
      onMinus(itemOpts) {
        goodsQtyEvent.onMinus({ itemOpts })
      },
      onPlus(itemOpts) {
        goodsQtyEvent.onPlus({ itemOpts })
      },
      onChange(itemOpts) {
        goodsQtyEvent.onChange({ itemOpts })
      },
    }
  },
  DiscountNormalTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        discountNormalTagEvent.onClick({ itemOpts })
      }
    }
  },
  DiscountLimitTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        discountLimitTagEvent.onClick({ itemOpts })
      }
    }
  },
  EstimatedPrice: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        estimatedPriceEvent.onClick({ itemOpts })
      },
      onExposeChange(itemOpts) {
        estimatedPriceEvent.onExposeChange({ itemOpts })
      }
    }
  },
  GoodsRepickButton: {
    props() {
      if (batchActive.value) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ data }) {
        const promotionId = data.value?.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView?.promotionId
        if (promotionId) {
          commit('cart_v2/changeAppendageDrawerStatus', { promotionId })
        }
      }
    }
  },
  GoodsAlmostEntry: {
    props() {
      if (batchActive.value) {
        return {
          isShow: false
        }
      }
    },
    events: {
      onClick({ data }) {
        const { cartAlmostPopupEntryCartItem } = data.value?.aggregateProductBusiness || {}
        if (cartAlmostPopupEntryCartItem) {
          dispatch('cart_v2/toggleIncentiveProductListDialog', {
            show: true,
            info: {
              ...cartAlmostPopupEntryCartItem,
              pop_entrance_from: 'low_inventory',
            },
          })
        }
      },
    }
  },
  PromotionTag: {
    type: !isNewCartrowVision.value ? 'v1' : 'v2',
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick(itemOpts) {
        promotionTagEvent.onClick({ itemOpts, options: { index: props.index + 1 } })
      },
      onReport(itemOpts) {
        promotionTagEvent.onReport({ itemOpts, options: { index: props.index + 1 } })
      },
      onExpose(itemOpts) {
        promotionTagEvent.onExpose({ itemOpts, options: { index: props.index + 1 } })
      },
    }
  },
  FindSimilarText: {
    props() {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onExpose({ data }) {
        if (!batchActive.value) {
          daEventCenter.triggerNotice({
            daId: '1-8-3-5',
            extraData: {
              goods_id: data.value.product.goods_id,
              activity_from: 'cart',
              is_out_of_stock: 1
            }
          })
        }
      },
      onClick({ data }) {
        SimilarInstance.showModal(data.value.product, {
          similarFrom: 'out_of_stock',
        })
        // 上报埋点
        daEventCenter.triggerNotice({
          daId: '1-8-3-6',
          extraData: {
            sku: data.value.product.goods_sn,
            goods_id: data.value.product.goods_id,
            activeFrom: 'cart',
            code: 'cart',
            isOutOfStock: 1 
          }
        })
      },
    }
  },
  ChangeMall: {
    props() {
      if (batchActive.value) {
        return {
          isShowMask: true,
          isClick: false
        }
      }
    },
    events: {
      onExpose({ data }) {
        daEventCenter.triggerNotice({
          daId: '1-8-3-77',
          bindData: [
            {
              sku_code: data.value.product.sku_code
            }
          ]
        })
      },
      onClick({ data }) {
        handleChangeMall(data.value)
      }
    }
  },
  GoodsStore: {
    events: {
      onClick({ data }) {
        const storeRouting = data.value.storeRouting
        if (storeRouting) {
          markPoint('toNextPageClick', 'public')
          routerPush(`${gbCommonInfo.langPath}${storeRouting}`)
        }
      }
    }
  },
  GoodsAddOnTip: {
    props() {
      if (batchActive.value) {
        return {
          isClick: false
        }
      }
    },
    events: {
      onClick({ data }) {
        const promotionId = data.value?.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView?.promotionId
        if (promotionId) {
          AddItem.show({
            popupInfoId: promotionId,
            state: 'promotion_add',
          })
        }
        
      }
    }
  }
})
const constant = ref({
  RESOURCE_SDK: locals.value.RESOURCE_SDK,
  LAZY_IMG: locals.value.LAZY_IMG,
  GBCssRight: locals.value.GB_cssRight,
})

watch(() => batchActive.value, (n) => {
  if (!n) {
    allowTouchMove.value = true
    return
  }
  if (openedDeleteItemId.value === props.item.id) {
    retractScroll()
  }
  allowTouchMove.value = false
  // this.$refs?.itemBehavior?.changeAutoPlayStatus(!n) 这个需要对行为标签原子组件进行改造。传入一个参数，控制是否播放
})

watch(() => openedDeleteItemId.value, (n) => {
  if(n && n !== props.item.id){
    retractScroll()
  }
})

const retractScroll = () => {
  swiperContainer.value?.scrollTo({
    left: 0,
    behavior: 'smooth',
  })
}

const handleExposeItem = () => {
  if (batchActive.value) return
  expose_goods_list(props.item, (defaultData) => {
    const data = {
      ...defaultData,
      label_id: selectFilterLabel.value?.labelId || '',
      goods_list: getSaGoodsList(props.item, { index: props.index + 1, cartAbtInfo: cartAbtInfo.value, scene: 'cart', el: cardGoodsItem.value, actionToRefreshRecommend: props.actionToRefreshRecommend })
    }
    return data
  })
}

const handleCheckedItem = ({ label = '', checked = false }, item) => {
  modifyCartCheckStatus({ cartIdList: [item.id], operationType: checked ? 1 : 2, type: 'item', isShowOverLimitToast: true })
}
const onClickCheckbox = () => {
  if (isSwitchItem.value) {
    const btnText = props.item.canChangeMallInfo ? props.item.aggregateProductBusiness?.changeMallInfo?.buttonTip :  props.item.aggregateProductBusiness?.changeGoodInfo?.buttonTip
    const content = props.item.canChangeMallInfo ? props.item.aggregateProductBusiness?.changeMallInfo?.toastTip :  props.item.aggregateProductBusiness?.changeGoodInfo?.toastTip
    daEventCenter.triggerNotice({
      daId: '1-8-3-79',
      extraData: {
        type: props.item.canChangeMallInfo ? 1 : 2,
      }
    })
    const events = {
      onClick: async () => {
        daEventCenter.triggerNotice({
          daId: '1-8-3-80',
          extraData: {
            type: props.item.canChangeMallInfo ? 1 : 2,
          }
        })
        if (props.item.canChangeMallInfo) {
          handleChangeMall(props.item)
        } else if (props.item.canChangeGoodInfo) {
          goodsSaleAttrEvent.onClick({ itemOpts: { data: ref(props.item) } })
        }
      }
    }
    showCheckedTip({
      btnText,
      content,
    }, events)
  }
}

const scrollEventHandle = throttle({
  func: function(ev) {
    let width = cartSwiperOperationTab.value.offsetWidth
    let currentScrollLeft = ev.target.scrollLeft //lastScrollLeft
    if (ev.target.scrollLeft >= (width * 3) / 4) {
      if((currentScrollLeft - lastScrollLeft) < 0){
        return
      }
      lastScrollLeft = currentScrollLeft
      commit('cart_v2/updateState', { key: 'openedDeleteItemId', value: props.item.id })
    } else if (ev.target.scrollLeft <= 0) {
      if(openedDeleteItemId.value && openedDeleteItemId.value !== props.item.id){
        return
      }
      commit('cart_v2/updateState', { key: 'openedDeleteItemId', value: '' })
    }
  },
  wait: 300
})

const  handleClickItemBatchCheck = () => {
  if (props.item.isNewcomerItem ) {
    Toast(language.value.SHEIN_KEY_PWA_26189)
    daEventCenter.triggerNotice({ daId: '1-8-3-35' })
    return
  }
}
</script>
<style lang="less">
.cart-list__item{
  background: #fff;
  position: relative;
  /* 设置 Swiper 容器样式 */
  .cart-swiper {
    display: flex;
    overflow: auto;
    scroll-snap-type: x mandatory;
    // overscroll-behavior: none;
    scroll-timeline: --scroller x;
    width: 100%;
    &.not-allow__touch-move{
      overflow: hidden;
    }
    &::-webkit-scrollbar{
      display: none;
    }
  }
  /* 设置滑块 slide 样式 */
  .cart-swiper-slide {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    &.item-content{
      width: 100%;
    }
  }
}
.cart-list__item-container{
  background: #fff;
  padding: 20/75rem var(--item-gap, 0.2667rem);
  display: flex;
  position: relative;
  width: 101%;
  .cart-list__item-checkbox{
    height: var(--checkbox-height, 1.973rem);
    display: flex;
    justify-content: center;
  }
  .cart-list__item-content{
    flex: 1;
    min-width: 0;
  }
  .batch-checkbox{
    display: flex;
    align-items: center;
    height: var(--checkbox-height, 1.973rem);
  }
}
.operation-tab{
  display: flex;
  margin-left: 1px; // 解决 ssr 首屏红线问题
  width: auto;
  height: auto;
  .opertation-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16/75rem 8/75rem;
    font-size: 12px;
    font-weight: 700;
    width: 120/75rem;
    &.delete {
      color: @sui_color_white;
      background: @sui_color_unusual;
    }
    &.share {
      color: @sui_color_white;
      background: #FA6338;
    }
    &.similar {
      color: @sui_color_white;
      background: #FFCE00;
    }
    &.wish {
      color: @sui_color_white;
      background: #FA6338;
    }
    &.move-to-wishlist {
      color: @sui_color_white;
      background: #FF9000;
    }
    .opertation-text {
      max-height: 100%;
      overflow: hidden;
      word-break: break-word;
      text-align: center;
    }
  }
}
</style>
