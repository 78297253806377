import { onMounted } from 'vue'
import couponFilterGuide from './couponFilterGuide.js'

export const useCouponFilterGuide = (ref) => {
  const CACHE_KEY = 'CouponFilterGuide'

  onMounted(() => {
    couponFilterGuide.initialize({
      start: async () => {
        const res = await ref.value?.handleFilterCouponGuide?.()
        if (res) {
          // 满足展示引导条件
          // 设置展示缓存
          window.localStorage.setItem(CACHE_KEY, true)
          couponFilterGuide.end()
        } else {
          // 不满足展示引导条件 跳过引导
          couponFilterGuide.skip()
        }
      },
      isCompleted() {
        if (typeof window === 'undefined') return true
        return !!window.localStorage.getItem(CACHE_KEY)
      },
    })
  })
}
