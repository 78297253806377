<template>
  <s-checkbox-tag
    class="filter-check-tag"
    :class="{
      'style-default': labelInfo.style == '0',
      'style-a': labelInfo.style == '1',
      'style-b': !inModal && labelInfo.style == '2',
      'style-b-modal': inModal && labelInfo.style == '2',
      'scan-lights': showScanLights && labelInfo.groupClassId == 'coupon'
    }"
    style-type="white"
    :hide-icon="true"
    :cancel-mark="cancelMark"
    :label="labelInfo.filterTagId"
  >
    <div class="filter-label">
      <template v-if="showPrefixIcon">
        <Icon
          v-if="prefixIcon"
          size="12px"
          class="prefix-icon custom-prefix-icon"
          :name="prefixIcon"
        />
        <img
          v-else
          class="label-icon"
          :src="getLabelIcon"
        />
      </template>
      <i
        v-if="showDivider"
        class="divider"
      ></i>
      <TransitionGroup 
        name="slide" 
        tag="div"
        class="transition-tag"
      >
        <div 
          v-for="item in slideItems" 
          :key="item"
          class="label-tip"
        >
          {{ labelInfo.tip }}
        </div>
      </TransitionGroup>
      <!-- <span class="label-text">{{ labelInfo.tip }}</span> -->
      <Icon
        v-if="showSuffixIcon && checked && ['1', '2'].includes(labelInfo.style)"
        class="suffix-icon"
        name="sui_icon_close_16px" 
        size="14px"
      />
    </div>
  </s-checkbox-tag>
</template>

<script>
export default {
  name: 'FilterCheckTag',
}
</script>

<script setup>
import { defineProps, computed, ref, watch, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
const props = defineProps({
  labelInfo: {
    type: Object,
    default: () => {}
  },
  checked: {
    type: Boolean,
    default: false,
  },
  // 展示取消按钮
  cancelMark: {
    type: Boolean,
    default: true,
  },
  // 展示标签文字前icon
  showPrefixIcon: {
    type: Boolean,
    default: false,
  },
  prefixIcon: {
    type: String,
    default: '',
  },
  showDivider: {
    type: Boolean,
    default: false,
  },
  // 展示标签文字后关闭icon
  showSuffixIcon: {
    type: Boolean,
    default: false,
  },
  // 是否播放扫光动画
  showScanLights: {
    type: Boolean,
    default: false,
  },
  inModal: {
    type: Boolean,
    default: false,
  },
})
const getLabelIcon = computed(() => {
  if (props.checked) {
    return props.labelInfo.selectIconUrl
  }
  return props.labelInfo.iconUrl
})
const slideItems = ref([props.labelInfo.tip])
watch(() => props.labelInfo.filterTagId, async (n, o) => {
  slideItems.value?.shift()
  await nextTick()
  slideItems.value?.push(n)
})
</script>

<style lang="less" scoped>
.filter-check-tag {
  width: auto;
  /deep/.S-checkbox-tag__wrap {
    line-height: 0;
    overflow: hidden;
  }
  .filter-label {
    display: inline-flex;
    align-items: center;
    height: 100%;
    font-size: 12/37.5rem;
    max-width: 156/37.5rem;
    overflow: hidden;
    .label-icon {
      width: 24/75rem;
      height: 24/75rem;
      margin-right: 8/75rem;
    }
    .divider {
      width: 1/37.5rem;
      height: 10/37.5rem;
      background: #999;
      margin: 0 4/37.5rem;
    }
    .transition-tag {
      flex: 1;
      overflow: hidden;
    }
    .label-tip {
      height: 16px;
      line-height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.style-a, .style-b, .style-b-modal {
  /deep/.S-checkbox__input-custom {
    .icons-share_cancel {
      display: none;
    }
    .S-checkbox-tag__wrap {
      border-radius: 24/37.5rem;
      border: 1px solid #E5E5E5;
      &::after {
        border: none;
      }
    }
  }
  &.scan-lights {
    border-radius: 24/37.5rem;
  }
}
.style-a {
  /deep/.S-checkbox__input-custom_checked {
    .S-checkbox-tag__wrap {
      border: 1px solid #FA6338;
      background: #FFF6F3;
      color: #FA6338;
    }
    .divider {
      background: #FA6338;
      opacity: 0.6;
    }
    .custom-prefix-icon {
      color: #FA6338!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}
.style-b {
  /deep/.S-checkbox__input-custom_checked {
    .S-checkbox-tag__wrap {
      border: 1px solid #222;
      background: #222;
      color: #FFF;
    }
    .prefix-icon, .suffix-icon {
      color: #FFF!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}
.style-b-modal {
  /deep/.S-checkbox__input-custom_checked {
    .S-checkbox-tag__wrap {
      border: 1px solid #000;
      background: #fff;
      color: #000;
    }
    .prefix-icon, .suffix-icon {
      color: #000!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}
.scan-lights {
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, rgba(255, 230, 167, 0), rgba(255, 230, 167, 1), rgba(255, 230, 167, 0));
    animation: ScanLights 1.5s linear 2 forwards;
  }
}
@keyframes ScanLights {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(100%);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all .2s linear;
}
.slide-enter {
  transform: translate3d(0, 100%, 1px);

}
.slide-leave-to {
  opacity: 0;
  transform: translate3d(0, -100%, 1px);
}
</style>
