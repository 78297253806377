<template>
  <div
    class="cart-promotion-coupon-mix c-promotion-coupon-mix"
  >
    <!-- 主体：已满足 -->
    <PromoSatisfied
      v-show="middleInfos.length"
      :info="promotionCouponMix.middleBandInfo"
      :satisfied-item="satisfiedItem"
      :abt-type="abtType"
      @click.native="openZone"
      @is-show="handleExpose(middleInfos.length ? $event : null)"
    />
    <!-- 主体：所有活动入口 -->
    <PromoZoneEntry
      v-show="promotionCouponMix.bottomBandInfo"
      ref="PromoZoneEntry"
      :info="promotionCouponMix.bottomBandInfo"
      :abt-type="abtType"
      @click.native="openZone"
    />

    <!-- 气泡 -->
    <template v-if="bubbleItem">
      <PromoPopover
        v-show="popoverShow"
        :locals="locals"
        :bubble-item="bubbleItem"
        @close="handlePopoverClose"
        @popoverClick="handlePopoverClick"
      />
    </template>
    <!-- promo hub弹窗 -->
    <CustomDrawer 
      :value="showHub"
      :styleConfig="{
        max: '80%',
        min: '40%',
      }"
      attach=".j-cart-checkout"
      @close="toggleVisible(false)"
    >
      <PromoZone
        v-if="showHub"
        :info="promotionCouponMix.popupInfo"
        @clickDetail="handleClickDetail"
      />
    </CustomDrawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import PromoZoneEntry from './PromoZoneEntry.vue'
import CustomDrawer from 'public/src/pages/cart_v2/components/common/CustomDrawer.vue'
import PromoZone from './PromoZone.vue'
import PromoSatisfied from './PromoSatisfied.vue'
import PromoPopover from './PromoPopover.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'

const BUBBLE_CACHE_KEY = new Set()
const SATISFIED_CACHE_KEY = new Set()
const getCache = (cacheKey) => Array.from(cacheKey)
const addCache = (cacheKey, id) => cacheKey.add(id)

export default {
  setup() {
    const AddItem = useCartAddItem()
    return { AddItem }
  },
  name: 'CartPromotionCouponMix',
  components: {
    PromoZoneEntry,
    CustomDrawer,
    PromoZone,
    PromoSatisfied,
    PromoPopover,
  },
  inject: ['fixedUptt'],
  provide() {
    return {
      openAppendageDrawer: this.openAppendageDrawer,
      openAddOn: this.openAddOn,
    }
  },
  data() {
    return {
      popoverShow: false,
      bubbleItem: null,
      satisfiedItem: null,
      isUserClickPopoverClosed: false,
    }
  },
  computed: {
    ...mapState('cart_v2', ['promotionState', 'locals']),
    promotionCouponMix() {
      return this.promotionState.promotionCouponMix || {}
    },
    showHub() {
      return this.promotionState.promoHubVisible
    },
    abtType() {
      return this.promotionCouponMix?.abtStyle
    },
    bubbles() {
      return this.promotionCouponMix?.topBubbleInfos || []
    },
    middleInfos() {
      return this.promotionCouponMix?.middleBandInfo?.middleInfos || []
    }
  },
  watch: {
    'bubbles': {
      handler(val) {
        if (typeof window === 'undefined') return
        this.checkBubbleItem(val)
      },
      deep: true,
    },
    'promotionCouponMix': {
      handler() {
        if (typeof window === 'undefined') return
        if (this.middleInfos.length) {
          this.checkSatisfiedItem(this.middleInfos)
        } else {
          // 如果没有中间部分直接曝光
          this.handleExpose()
        }
        this.fixedUptt()
      },
      immediate: true,
      deep: true,
    },
    showHub(val) {
      if (!val) {
        this.updatePromotionState({ mixSelectedCartIds: [] }) // 清空弹窗内勾选商品缓存
      }
    }
  },
  deactivated() {
    this.clearTimer()
    this.removeCache()
  },
  beforeDestroy () {
    this.clearTimer()
  },
  methods: {
    ...mapMutations('cart_v2', ['updatePromotionState', 'changeAppendageDrawerStatus', 'changeAllDiscountDetailDrawerParams']),
    openZone() {
      this.toggleVisible(true)
    },
    toggleVisible(bool) {
      this.updatePromotionState({ promoHubVisible: bool })
    },
    togglePopoverShow(bool) {
      this.popoverShow = bool
      if (!bool) {
        this.clearTimer()
      }
    },
    // 查询缓存，获取未展示过的气泡
    checkBubbleItem(list) {
      const cacheIds = getCache(BUBBLE_CACHE_KEY)
      const identifyByRoute = this.getIdentifyByRoute()

      let item

      // 如果路由中的利诱没有展示过
      if (identifyByRoute) {
        item = list?.find(v => v.identify == identifyByRoute)
      }

      // 兜底
      if (!item && !this.isUserClickPopoverClosed) {
        item = list?.find(v => !cacheIds.includes(v.identify))
      }

      if (item) {
        this.bubbleItem = item
        // 打开弹窗, 3s后自动消失
        this.$nextTick(() => {
          this.togglePopoverShow(true)
          this.timer = setTimeout(() => {
            this.togglePopoverShow(false)
          }, 3000)
          addCache(BUBBLE_CACHE_KEY, item?.identify)
        })
        return
      } else {
        this.togglePopoverShow(false)
      }
    },
    handlePopoverClick(payload) {
      this.togglePopoverShow(false)
      const { notPick, promotionId } = payload || {}
      if (notPick) {
        this.openAppendageDrawer(promotionId)
      } else {
        this.openZone()
      }
    },
    handlePopoverClose() {
      this.isUserClickPopoverClosed = true
      this.togglePopoverShow(false)
    },
    clearTimer() {
      if (!this.timer) return
      clearTimeout(this.timer)
      this.timer = null
    },
    // 查询缓存，获取未展示过的活动
    checkSatisfiedItem(list) {
      const cacheIds = getCache(SATISFIED_CACHE_KEY)
      const identifyByRoute = this.getIdentifyByRoute()

      let item

      // 如果路由中的利诱没有展示过
      if (identifyByRoute) {
        item = list?.find(v => v.identify == identifyByRoute)
      }

      // 兜底
      if (!item) item = list?.find(v => !cacheIds.includes(v.identify))

      if (item) {
        addCache(SATISFIED_CACHE_KEY, item.identify)
      }
      this.satisfiedItem = item || list[0]
    },
    handleClickDetail() {
      this.changeAllDiscountDetailDrawerParams({
        show: true,
      })
    },
    // 打开附属品弹窗
    openAppendageDrawer(promotionId) {
      this.changeAppendageDrawerStatus({ promotionId: promotionId })

    },
    // 打开凑单
    openAddOn(payload) {
      let options = {}
      if (payload?.couponCode) {
        options = {
          coupon: payload?.couponCode,
          state: 'coupon_helper_add',
        }
      } else if (payload?.popupInfoId) {
        options = {
          popupInfoId: payload.popupInfoId,
          state: payload?.type === 'freeShipping' ? 'shipping_add' : 'promotion_add',
        }
      }
      this.AddItem.show(options)
    },
    handleExpose(middleSa) {
      this.$nextTick(() => {
        const mix_type_arr = []
        const entrySa = this.$refs.PromoZoneEntry?.getSaData()
        if (entrySa) {
          mix_type_arr.push(entrySa?.mix_type)
        }
        if (middleSa) {
          mix_type_arr.push(middleSa?.mix_type)
        }
        daEventCenter.triggerNotice({ 
          daId: '1-8-1-48',
          extraData: {
            mix_type: mix_type_arr.join(','),
            unlock_deals: entrySa?.unlock_deals,
            lock_deals: middleSa?.lock_deals
          }
        })
      })
    },
    getIdentifyByRoute() {
      return this.$route.params?.identify
    },
    removeCache() {
      BUBBLE_CACHE_KEY?.clear()
      SATISFIED_CACHE_KEY?.clear()
      this.isUserClickPopoverClosed = false
    }
  }
}
</script>

<style lang="less" scoped>
.cart-promotion-coupon-mix { /* stylelint-disable-line selector-class-pattern */
  position: relative;
}
</style>
