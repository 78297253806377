<template>
  <s-drawer
    :visible="show"
    max-size="89%"
    :show-close="true"
    :append-to-body="true"
    class="custom-detail-dialog"
    @close-from-icon="closeByIcon"
    @close-from-mask="closeByMask"
  >
    <template slot="top">
      <div class="header-title">
        {{ customizationContentTitle }}
      </div>
    </template>
    <div class="content">
      <template v-if="resizeEffects.length > 0">
        <div class="block-title">
          {{ effectsTip }}:
        </div>
        <div class="block-content">
          <div
            v-for="(item, index) in resizeEffects"
            :key="item+index"
            class="preview-img"
            @click="viewBigPicture('effects', index)"
          >
            <img
              class="lazyload img-content"
              :src="LAZY_IMG"
              :data-src="item"
              alt="shein picture"
            />
          </div>
        </div>
      </template>

      <template v-if="texts.length > 0">
        <div class="block-title">
          {{ textsTip }}:
        </div>
        <div class="block-content">
          <div
            v-for="(text, index) in texts"
            :key="text+index"
            class="text-content"
          >
            {{ text }}
          </div>
        </div>
      </template>

      <template v-if="resizeImages.length > 0">
        <div class="block-title">
          {{ imagesTip }}:
        </div>
        <div class="block-content">
          <div
            v-for="(image, index) in resizeImages"
            :key="image+index"
            class="custom-img"
            @click="viewBigPicture('images', index)"
          >
            <img
              class="lazyload img-content"
              :src="LAZY_IMG"
              :data-src="image"
              alt="shein picture"
            />
          </div>
        </div>
      </template>
    </div>
    <div class="foot">
      <s-button 
        :type="['primary', 'H40PX']"
        :width="'100%'"
        @click="close"
      >
        {{ okTip }}
      </s-button>
    </div>
  </s-drawer>
</template>

<script>
import CustomDaEvent from './utils/analysis.js'

export default {
  name: 'CustomDetail',
  props: {
    previewInstance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDetailDialog: false,
      customizationContentTitle: '',
      okTip: '',
      effectsTip: '',
      resizeEffects: [],
      originEffects: [],
      textsTip: '',
      texts: [],
      imagesTip: '',
      resizeImages: [],
      originImages: [],
      previewStatus: {
        show: false
      },
      analysisData: {},
      LAZY_IMG: LAZY_IMG,
    }
  },
  computed: {
    show: {
      get() {
        return this.showDetailDialog
      },
      set(val){
        if(!val) this.$emit('close')
        this.showDetailDialog = val
        if(!this.showDetailDialog) {
          this.$off()
          CustomDaEvent.click_custom_content_close()
          this.texts = []
          this.resizeEffects = []
          this.resizeImages = []
        }
      }
    },
  },
  methods: {
    open(ctx) {
      this.initData(ctx)
      this.show = true
      // 曝光埋点
      CustomDaEvent.expose_custom_content({
        images: this.resizeImages?.length || 0,
        preview: this.resizeEffects?.length || 0,
        texts: this.texts?.length || 0,
      }, this.analysisData)
    },
    close() { // 外部触发关闭
      this.show = false
    },
    closeByIcon() { // icon触发关闭
      this.show = false
      this.$emit('closeByIcon')
    },
    closeByMask() { // mask触发关闭
      this.show = false
      this.$emit('closeByMask')
    },
    initData(ctx) {
      const { customizationContentTitle, okTip, effectsTip, resizeEffects, originEffects, textsTip, texts, imagesTip, resizeImages, originImages, onEvent, analysisData = {} } = ctx || {}

      this.customizationContentTitle = customizationContentTitle
      this.okTip = okTip

      this.effectsTip = effectsTip
      this.resizeEffects = resizeEffects || []
      this.originEffects = originEffects || []

      this.textsTip = textsTip
      this.texts = texts || []

      this.imagesTip = imagesTip
      this.resizeImages = resizeImages || []
      this.originImages = originImages || []

      this.analysisData = analysisData
      if(onEvent) {
        Object.keys(onEvent).forEach(key => {
          this.$on(key, onEvent[key])
        })
      }
    },
    viewBigPicture(type, index) {
      this.previewInstance.open({
        previewStatus: {
          type,
          index,
        },
        effectsTip: this.effectsTip,
        resizeEffects: this.resizeEffects,
        originEffects: this.originEffects,
        imagesTip: this.imagesTip,
        resizeImages: this.resizeImages,
        originImages: this.originImages,
      })
      CustomDaEvent.click_custom_content_imagedetail()
    },
    closePreviewPicture(val){
      this.previewStatus.show = val
    },
  },
}
</script>

<style scoped lang="less">
.custom-detail-dialog {
  /deep/.icons-nav_close {
    font-size: 16px!important;
  }
}
.header-title {
  height: 80/75rem;
  padding: 18/75rem 0;
  font-size: 14px;
  color: #767676;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  padding: 16/75rem 24/75rem 16/75rem;
  overflow-y: auto;
}
.foot {
  padding: 16/75rem 24/75rem 16/75rem;
}
.block-title {
  font-size: 12px;
  font-weight: 700;
  color: #767676;
  margin-bottom: 16/75rem;
}
.block-content {
  display: flex;
  flex-wrap: wrap;
  gap: 8/75rem;
  margin-bottom: 48/75rem;
  .img-content {
    object-fit: contain;
    display: block;
    width: 100%;
    height: 100%;
  }
  .preview-img {
    width: 122/75rem;
    height: 122/75rem;
    position: relative;
    overflow: hidden;
  }
  .text-content {
    background: #F6F6F6;
    padding: 12/75rem 24/75rem;
    color: #666;
  }
  .custom-img {
    width: 134/75rem;
    height: 134/75rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
}
</style>
