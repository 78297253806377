import { daEventCenter } from 'public/src/services/eventCenter/index'

export const expose_newonly_addpop = (data, callback) => {
  const defaultData = {}
  daEventCenter.triggerNotice({ daId: '1-8-3-33', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_cart_quantity_edit = (data, callback) => {
  const defaultData = {
    scene: 'cart',
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-1', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_goods_add_qty = (data, callback) => {
  const defaultData = {
    scene: 'cart',
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-39', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_goods_sub_qty = (data, callback) => {
  const defaultData = {
    scene: 'cart',
    qty: data.quantity,
    goods_type: 'main', // 如果是主品上报main，如果是赠品上报gift，如果是换购品上报add-on
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-40', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_reachedflashsalelimit = (data, callback) => {
  const defaultData = {}
  daEventCenter.triggerNotice({ daId: '1-8-1-5', extraData: callback?.(defaultData) ?? defaultData })
}

