<script>
import { amountWithSymbolTransform, handleTotalPriceAnimate } from '../../utils/index.js'

/**
 * 金额滚动组件
 * <AmountRoll :amount="{amount: 0, amountWithSymbol: '0.00'}" />
 */
export default {
  name: 'AmountRoll',

  props: {
    // 价格对象 { amount: 0, amountWithSymbol: '0.00' }
    amount: {
      type: Object,
      default: () => ({}),
    },
    // 是否拆分金额和货币符号
    isFormatted: {
      type: Boolean,
      default: true,
    },
    // 渲染包裹标签
    tag: {
      type: String,
      default: 'span',
    },
  },

  data() {
    return {
      transtionAmount: '',
    }
  },

  computed: {
    formattedAmount() {
      if (this.isFormatted) {
        return amountWithSymbolTransform(this.transtionAmount)
      }
      return this.transtionAmount?.amountWithSymbol
    },
  },

  watch: {
    amount: {
      handler(newVal, oldVal) {
        if (!oldVal) {
          this.transtionAmount = newVal
          return
        }
        handleTotalPriceAnimate(newVal, this.transtionAmount, (updateVal) => {
          this.transtionAmount = updateVal
        })
      },
      immediate: true,
      deep: true,
    }
  },

  render(h) {
    return h(this.tag, {
      domProps: {
        innerHTML: this.formattedAmount,
      },
    })
  }
}
</script>
