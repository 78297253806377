var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'cart-rightsFuns-tip_oldA',
    {
      'shipping-and-return': _vm.isFreeReturn && !_setup.hasCountDown,
      'shipping-and-return_countdown': _vm.isFreeReturn && _setup.hasCountDown,
    }
  ]},[_c('div',{staticClass:"left-tip"},[(_vm.cartRights.freeShipping)?_c('div',{staticClass:"free-shipping-rights"},[_c(_setup.FreeShippingIcon),_vm._v(" "),_c('span',{ref:"freeShippingText",staticClass:"free-shipping-text",domProps:{"innerHTML":_vm._s(_vm.cartRights.freeShipping.tip)}})],1):_vm._e(),_vm._v(" "),(_vm.cartRights.freeReturn)?_c('div',{staticClass:"free-return-rights"},[_c(_setup.FreeReturnIcon),_vm._v(" "),_c('span',{ref:"freeReturnText",staticClass:"free-return-text",domProps:{"innerHTML":_vm._s(_vm.cartRights.freeReturn.tip)}})],1):_vm._e()]),_vm._v(" "),(_setup.hasCountDown)?[_c(_setup.ClientOnly,[_c(_setup.CountDown,{directives:[{name:"show",rawName:"v-show",value:(_setup.showCountDown),expression:"showCountDown"}],staticClass:"count-down",attrs:{"font-size":"10px","not-hide":"","not-reload":"","time-stamp":+_setup.hasCountDown}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }