<template>
  <div
    v-tap="analysisData()"
    class="promo-zone-entry"
    :style="styleInfo"
  >
    <div class="ico-box">
      <img
        class="ico"
        :src="info.icon"
      />
    </div>
    <span
      class="tips"
      v-html="info.tip"
    >
    </span>
    <span class="btn">
      {{ info.viewTip }}
    </span>
  </div>
</template>

<script>
const getCssConfig = (val) => {
  if (['planA', 'planA_2'].includes(val)) {
    return {
      '--icon-height': `${16 / 37.5}rem`,
      '--tips-color': '#fff',
      '--view-btn-color': '#FF430E',
      '--view-btn-border': '0.5px solid #fff',
      '--view-btn-bg': 'rgba(255, 255, 255, 0.80)',
    }
  }
  if (['planB', 'planB_2'].includes(val)) {
    return  {
      '--icon-height': `${22 / 37.5}rem`,
      '--tips-color': '#000',
      '--view-btn-color': '#000',
      '--view-btn-border': '1px solid #FFF3F3',
      '--view-btn-bg': 'rgba(255, 242, 242, 0.80)',
    }
  }
}
export default {
  name: 'PromoZoneEntry',
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    abtType: {
      type: String,
      default: '',
    }
  },
  computed: {
    styleInfo() {
      return {
        '--bg-imge': `url(${this.info?.backgroundImage})`,
        ...getCssConfig(this.abtType),
      }
    }
  },
  methods: {
    getSaData() {
      return {
        mix_type: 'unlockdeals',
        unlock_deals: this.info?.unlockDeal
      }
    },
    analysisData() {
      return {
        id: '1-8-1-49',
        data: this.getSaData()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.promo-zone-entry {
  position: relative;
  height: 40/37.5rem;
  background-color: #fff;
  background-image: var(--bg-imge);
  background-size: cover;
  border-radius: 12/37.5rem 12/37.5rem 0 0;
  padding: 6/37.5rem 12/37.5rem 6/37.5rem 10/37.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  .ico-box {
    width: 22/37.5rem;
    height: 22/37.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4/37.5rem;
    overflow: hidden;
  }
  .ico {
    height: var(--icon-height);
  }
  .tips {
    color: var(--tips-color);
    flex: 1;
    padding-right: 12/37.5rem;
    line-height: 15/37.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .btn {
    color: var(--view-btn-color);
    background: var(--view-btn-bg);
    border: var(--view-btn-border);
    width: 53/37.5rem;
    line-height: 14/37.5rem;
    border-radius: 12/37.5rem;
    padding: 4/37.5rem;
    font-weight: 590;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
