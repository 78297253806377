var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"benefit-info"},[_c(_setup.Component,{tag:"component",attrs:{"benefit-info":_setup.benefitInfo,"language":_vm.language,"type":_vm.type}}),_vm._v(" "),_c('div',{class:[
      'benefit-info__content',
      `benefit-info__content_${_vm.type}`
    ]},[_c('div',{staticClass:"benefit-info__title"},[_vm._v("\n      "+_vm._s(_vm.unpaidOrderInfo.waitingPaymentTip)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"benefit-info__bottom"},[(_setup.showCountDown)?_c(_setup.CountDown,{attrs:{"timeStamp":_vm.unpaidOrderInfo.expireCountdown,"styleConfig":{
          '--font-color': '#fff',
          '--num-bg-color': '#FA6338',
          '--divider-color': '#FA6338',
          '--divider-font-weight': 'bold'
        }}}):_vm._e(),_vm._v(" "),(_setup.showCountDown && _setup.savePriceAmountWithSymbol)?_c('span',{staticClass:"benefit-info__divider"}):_vm._e(),_vm._v(" "),(_setup.savePriceAmountWithSymbol)?_c('div',{staticClass:"benefit-info__saved"},[_c('span',{staticClass:"benefit-info__saved-label"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_30913)+":")]),_vm._v(" "),_c('span',{staticClass:"benefit-info__saved-value"},[_vm._v(_vm._s(_setup.savePriceAmountWithSymbol))])]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"benefit-info__button",on:{"click":function($event){$event.stopPropagation();return _setup.onClickView.apply(null, arguments)}}},[_vm._v("\n    "+_vm._s(_vm.language.SHEIN_KEY_PWA_17171)+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }