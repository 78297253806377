import { daEventCenter } from 'public/src/services/eventCenter/index'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'

export const expose_update_popup = (data, callback) => {
  const defaultData = {
    sku_id: data.product.goods_sn,
    scene: 'cart',
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-11', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_update_popup_changemall = (data, callback) => {
  const defaultData = {
    sku_id: data.product.goods_sn
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-12', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_goods_size_select = (data, callback) => {
  const defaultData = {
    goods_status: data.isInvalid == 0 ? 'goods_status_available' : data.aggregateProductBusiness?.goodsStatus,
    goods_id: data.product.goods_id,
  }
  daEventCenterV2.triggerNotice({ id: 'click_goods_size_select.page_cart', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_update_popup_updatebutton = (data, callback) => {
  const defaultData = {
    update_result: '',
    sku_id: data.product.goods_sn,
    mall_code: data.mall_code,
  }
  daEventCenter.triggerNotice({ daId: '1-8-1-19', extraData: callback?.(defaultData) ?? defaultData })
}
