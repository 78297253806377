import { computed } from 'vue'
import { useStore } from '../../hooks/useStore'

export const useNewRecommendFlag = (cartItem) => {
  const { getters } = useStore()
  const showNewGoodsRecommendPopover = computed(() => getters['cart_v2/showNewGoodsRecommendStyleC'] && getters['cart_v2/newGoodsRecommendFlagId'] === cartItem.id)

  return {
    showNewGoodsRecommendPopover,
  }
}
