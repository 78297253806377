<template>
  <div
    v-if="isShow"
    v-expose="{
      id: '1-8-7-65',
      data: {
        order_id: billno,
        benefit_list: savedPrice ? 'discount' : 'common',
      }
    }"
    v-tap="getClickSaData('body')"
    class="cu-order"
    @click="onClick"
  >
    <BenefitInfo
      :unpaid-order-info="unpaidOrderInfo"
      :language="language"
      type="1"
      @viewOrder="onClickView"
    />
    <sui_icon_close_16px_2
      class="cu-order__close"
      size="16px"
      color="#959595"
      @click.stop="onClickClose"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { sui_icon_close_16px_2 } from '@shein-aidc/icon-vue2'
import BenefitInfo from './BenefitInfo.vue'
import { daEventCenter } from '@/public/src/services/eventCenter'
import { CART_CLICK_UN_PAID_ORDER_INFO } from 'public/src/pages/cart_v2/utils/constants.js'
import { useRouter } from 'public/src/pages/cart_v2/hooks/useRouter.js'
import { stringifyQueryString } from '@shein/common-function'
import { updateSessionOrderCache } from 'public/src/pages/cart_v2/utils/index'

const { routerPush }  = useRouter()

const props = defineProps({
  unpaidOrderInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  }
})

const billno = computed(() => props.unpaidOrderInfo?.billno || '')
const savedPrice = computed(() => {
  if(props.unpaidOrderInfo?.savedPrice?.amount <= 0) return ''
  return props.unpaidOrderInfo?.savedPrice?.amountWithSymbol
})

const isShow = ref(true)
const onClickClose = () => {
  isShow.value = false
  daEventCenter.triggerNotice({
    daId: '1-8-7-66',
    extraData: {
      order_id: props.unpaidOrderInfo?.billno,
      benefit_list: savedPrice.value ? 'discount' : 'common',
      button_type: 'close'
    }
  })
}

const handleViewOrder = () => {
  const billno = props.unpaidOrderInfo?.billno
  let queryParams = {
    from_type: 7
  }
  if(props.unpaidOrderInfo?.paymentMethod != 'cod'){
    queryParams.show_selected_pay_method = 1
  }
  window.localStorage.setItem(CART_CLICK_UN_PAID_ORDER_INFO, 
    JSON.stringify({
      billno: props.unpaidOrderInfo?.billno ?? '',
      timeStamp: Date.now(),
    })
  )
  
  const url = `${props.locals.langPath}/user/orders/detail/${billno}?${stringifyQueryString({ queryObj: queryParams })}`
  routerPush(url)
}
const onClick = () => {
  handleViewOrder()
}
const onClickView = () => {
  daEventCenter.triggerNotice({
    daId: '1-8-7-66',
    extraData: {
      order_id: props.unpaidOrderInfo?.billno,
      benefit_list: savedPrice.value ? 'discount' : 'common',
      button_type: 'view'
    }
  })
  handleViewOrder()
}
const getClickSaData = (type = '') => {
  return {
    id: '1-8-7-66',
    data: {
      order_id: props.unpaidOrderInfo?.billno,
      benefit_list: savedPrice.value ? 'discount' : 'common',
      button_type: type || 'body'
    }
  }
}

onMounted(() => {
  updateSessionOrderCache(props.unpaidOrderInfo.billno)
})
</script>

<style lang="less" scoped>
.cu-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6/37.5rem 12/37.5rem;
  width: 100%;
  height: 44/37.5rem;
  background: linear-gradient(90deg, #FFECE9 2.73%, #FFFAF9 100%);
  .cu-order__close {
    flex-shrink: 0;
    margin-left: 10/37.5rem;
  }
}
</style>
