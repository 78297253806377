import { daEventCenter } from 'public/src/services/eventCenter/index'

export const click_goods_del_button = (data, callback) => {
  const defaultData = {
    goods_id: data?.product?.goods_id,
    cart_id: data?.id,
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-54', extraData: callback?.(defaultData) ?? defaultData })
}

export const click_left_findsimilar = (data, callback) => {
  const defaultData = {
    sale_status: 0,
    main_goods_id: data?.product?.goods_id,
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-27', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_left_findsimilar = (data, callback) => {
  const defaultData = {
    sale_status: 0,
    main_goods_id: data?.product?.goods_id,
  }
  return {
    id: '1-8-3-26',
    data: callback?.(defaultData) ?? defaultData,
  }
}

export const expose_share = (data, callback) => {
  const defaultData = {
    share_type: 'page',
    content_id: data?.product?.goods_id,
  }
  return {
    id: '2-24-1',
    data: callback?.(defaultData) ?? defaultData
  }
}
