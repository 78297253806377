<template>
  <div
    :class="[
      'cart-rightsFuns-tip_oldA',
      {
        'shipping-and-return': isFreeReturn && !hasCountDown,
        'shipping-and-return_countdown': isFreeReturn && hasCountDown,
      }
    ]"
  >
    <div class="left-tip">
      <!-- freeshipping权益 -->
      <div
        v-if="cartRights.freeShipping"
        class="free-shipping-rights"
      >
        <FreeShippingIcon />
        <span
          ref="freeShippingText"
          class="free-shipping-text"
          v-html="cartRights.freeShipping.tip"
        ></span>
      </div>
      <!-- freereturn权益 -->
      <div
        v-if="cartRights.freeReturn"
        class="free-return-rights"
      >
        <FreeReturnIcon />
        <span
          ref="freeReturnText"
          class="free-return-text"
          v-html="cartRights.freeReturn.tip"
        ></span>
      </div>
    </div>
    <template v-if="hasCountDown">
      <ClientOnly>
        <count-down
          v-show="showCountDown"
          class="count-down"
          font-size="10px"
          not-hide
          not-reload
          :time-stamp="+hasCountDown"
        />
      </ClientOnly>
    </template>
  </div>
</template>

<script setup>
// 老客权益a样式
import { computed, ref, onMounted, nextTick } from 'vue'
import FreeShippingIcon from './FreeShippingIcon.vue'
import FreeReturnIcon from './FreeReturnIcon.vue'
import CountDown from 'public/src/pages/cart_v2/components/common/countDown.vue'
import ClientOnly from 'vue-client-only'

const props = defineProps({
  cartRights: {
    type: Object,
    default: () => ({})
  }
})

const hasCountDown = computed(() => {
  return props.cartRights.countDownTime || ''
})

const freeShippingText = ref(null)
const freeReturnText = ref(null)
const showCountDown = ref(true)

// 计算内容长度，超出字体缩小为10px
const adjustFontSize = () => {
  if (freeShippingText.value?.scrollWidth > freeShippingText.value?.clientWidth || freeReturnText.value?.scrollWidth > freeReturnText.value?.clientWidth) {
    freeShippingText.value.style.fontSize = '10px'
    freeReturnText.value.style.fontSize = '10px'
  }
  if (freeShippingText.value?.scrollWidth > freeShippingText.value?.clientWidth || freeReturnText.value?.scrollWidth > freeReturnText.value?.clientWidth) {
    if (hasCountDown.value) {
      // document.querySelector('.count-down').style.display = 'none'
      showCountDown.value = false
    }
  }
}

onMounted(async() => {
  await nextTick()
  adjustFontSize()
})
</script>

<style lang="less" scoped>
.cart-rightsFuns-tip_oldA {
  width: 100%;
  display: inline-flex;
  align-items: center;
  height: 64/75rem;
  padding: 16/75rem 24/75rem;
  background: #FFF3D3;
  overflow: hidden;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 16/75rem;
  flex-wrap: nowrap;
  .left-tip {
    max-width: 100%;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 590;
    gap: 0 16/75rem;
  }
  /deep/.cont-down {
    flex-shrink: 0;
    margin-left: 16/75rem;
    span {
      display: inline-block;
      text-align: center;
      background: rgba(0, 0, 0, 1);
      color: rgba(255, 255, 255, 1);
      border-radius: 1px;
      width: 14px;
      height: 14px;
      line-height: 1;
      font-size: 10px;
    }
  }
  .free-shipping-rights,
  .free-return-rights {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    svg {
      flex-shrink: 0;
      margin-right: 8/75rem;
    }
  }
  &.shipping-and-return {
    .left-tip {
      width: 100%;
      .free-shipping-rights,
      .free-return-rights {
        justify-content: center;
        width: 50%;
        .free-shipping-text,
        .free-return-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .free-shipping-rights {
        padding-right: 24/75rem;
      }
      .free-return-rights {
        border-left: solid 1px rgba(241, 221, 165, 1);
        padding-left: 24/75rem;
      }
    }
    svg {
      margin-right: 8/75rem;
    }
  }
  &.shipping-and-return_countdown {
    .left-tip {
      gap: 16/75rem;
      overflow: hidden;
      flex-shrink: 1;
      .free-shipping-rights {
        flex-shrink: 0;
        max-width: 100%;
      }
      .free-return-rights {
        flex-shrink: 1;
        overflow: hidden;
      }
      .free-shipping-text,
      .free-return-text {
        flex-shrink: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .count-down {
      overflow: hidden;
      flex-shrink: 0;
    }
  }
}
</style>
