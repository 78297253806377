import { daEventCenter } from 'public/src/services/eventCenter/index'

export const expose_goods_userbehaviortips = (data, callback) => {
  const defaultData = {
    extraData: {
      userbehavior_tips: (data?.product?.actTags?.filter(item => item.hasAvailableTag === '1') ?? []).map((v) => v.tagId).join(','),
      userbehavior_tips_show: '',
      goods_id: data?.product?.goods_id,
    }
  }
  daEventCenter.triggerNotice({ daId: '1-8-1-40', extraData: callback?.(defaultData) ?? defaultData })
}
