import { computed } from 'vue'
import { Toast } from '@shein/sui-mobile'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
import { expose_update_popup, click_update_popup_changemall, click_goods_size_select, click_update_popup_updatebutton } from 'public/src/pages/cart_v2/analysis/item/goodsSaleAttr'
import { getQuickAddCartInstance, plugins } from 'public/src/pages/components/product/quick_add_v3/common'

export function useGoodsSaleAttrEvent() {
  const { state, commit, dispatch } = useStore()
  const language = computed(() => state.language)
  const monitorUserInteraction = computed(() => state?.cart?.monitorUserInteraction)

  const getSelectedAttrIndex = (data) => {
    const selectedAttrIndex = {}
    const attr = data.value.attr || null
    const sku_sale_attr = data.value.product?.sku_sale_attr || []
    if(attr) {
      selectedAttrIndex[attr.attr_id] = { attr_value_id: attr.attr_value_id }
    }
    sku_sale_attr && sku_sale_attr.forEach(item => {
      if(item.is_main != '1' && !selectedAttrIndex[item.attr_id]) {
        selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
      }
    })
    return selectedAttrIndex
  }
  const onUpdated = async ({ data, context, events, isShowOverLimitToast = false }) => {
    try {
      const reqParams = {
        is_checked: data.value.is_checked,
        append_id_list: data.value.appendIds,
        sku_code: context?.skuInfo?.sku_code,
        id: data.value.id,
        mall_code: context?.mallCode,
        quantity: context?.quantity,
        goods_id: context?.productInfo?.goods_id,
      }
      const res = await dispatch('cart_v2/fetchCartUpdateAttr', reqParams)
      if (res.code == '0') {
        dispatch('cart_v2/showOverLimitToast', { id: data.value.id, isShowOverLimitToast })
        events?.onUpdated?.()
      } else {
        Toast(res.msg)
      }
      click_update_popup_updatebutton(data.value, (defaultData) => {
        return {
          ...defaultData,
          update_result: res.code === '0' ? 1 : 0
        }
      })
      monitorUserInteraction.value?.stop?.({ reason: 'edit goods properties' })
    } catch (e) {
      console.error(e)
    }
  }
  const onClick = async (
    {
      itemOpts: { data, event },
      options: {
        index, 
        code = 'cart',
        from = 'cart',
        source = 'page',
        isShowWishBtn = false,
        isShowOverLimitToast = false,
        events
      } = {},
      analysis
    }
  ) => {
    if (event.isCustomGoods && event.event === 'attr') {
      Toast(language.value.SHEIN_KEY_PWA_26445)
      return
    }
    // 付费会员赠品重选
    const payMemberGiftView = data.value.aggregateProductBusiness?.cartRowViewControl?.payMemberGiftView
    if (payMemberGiftView?.promotionId && event.event === 'reselect') {
      commit('cart_v2/changeAppendageDrawerStatus', { promotionId: payMemberGiftView.promotionId })
      return
    }
    click_goods_size_select(data.value, analysis?.click_goods_size_select)
    const imgRatio = await getImageRatio(data.value.product.goods_img)
    getQuickAddCartInstance().open(
      {
        goods_id: data.value.product.goods_id,
        mallCode: data.value.mall_code,
      },
      {
        plugins: [
          plugins.salesAttrUpdatePlugin(async context => {
            await onUpdated({ data, context, events, isShowOverLimitToast })
          })
        ],
        excludePlugins: ['syncCartInfo'],
        analysisConfig: {
          index,
          code,
          from,
          source,
        },
        salesAttrConfig: {
          selectedAttrIndex: getSelectedAttrIndex(data)
        },
        extendedParam: {
          imgRatio,
          quantity: +data.value.quantity
        },
        featureConfig: {
          footerBar: {
            // 是否需要收藏按钮
            needFavoriteButton: isShowWishBtn,
          },
        },
        callbacks: {
          onDrawerStatusChange: (show) => {
            if (show) {
              expose_update_popup(data.value, analysis?.expose_update_popup)
            }
          },
          onMallClick: () => {
            click_update_popup_changemall(data.value, analysis?.click_update_popup_changemall)
          },
        }
      }
    )
  }

  return {
    onClick
  }
}
