<template>
  <div
    v-if="isShowSatisfyFreeTip || freeShippinpTips"
    class="free-color"
  >
    <div
      v-enterkey
      v-expose="analysisEvent('1-8-7-11')"
      v-tap="analysisEvent('1-8-7-12')"
      class="free-ship"
      :class="{
        'free-ship_activity': showCartActivityColor,
        'old-rights-funs': showCartRights
      }"
      @click="showShipSupplement ? clickCartTip() : ''"
    >
      <div class="icon-tip-wrap">
        <slot>
          <component
            :is="!showShipSupplement ? sui_icon_selected_16px: showCartRights ? sui_icon_freeshipping_16px_color : sui_icon_shipping_16px"
            class="item-icon"
            size="16px"
            :color="showCartRights ? '#0E8C33' : '#222'"
          />
        </slot>
        <transition 
          name="freedisappear"
          mode="out-in"
        >
          <div
            v-if="isShowSatisfyFreeTip || (firstFreeShipping && freeShippinpTips) || freeShippinpTips"
            class="ship-content"
            :class="{'ship-content_activity': showCartActivityColor}"
          >
            <!-- 满足门槛的免邮 -->
            <template v-if="isShowSatisfyFreeTip">
              <div 
                class="tips" 
                v-html="isShowSatisfyFreeTip"
              >
              </div>
            </template>
            <!-- 首单免邮 -->
            <template v-else-if="firstFreeShipping && freeShippinpTips">
              <div 
                class="tips" 
                v-html="freeShippinpTips"
              >
              </div>
              <div
                v-if="firstFreeShipping && firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
                :class="{'float-count-down': floatCountDown}"
              >
                <ClientOnly>
                  <count-down
                    :is-left-time="true"
                    :time-stamp="firstFreeShipping.countDownTime"
                    :type="countDownType"
                  />
                </ClientOnly>
              </div>
            </template>
            <!-- 不满足门槛的免邮 -->
            <template v-else>
              <div 
                class="tips"
                v-html="freeShippinpTips" 
              >
              </div>
            </template>
          </div>
        </transition>
      </div>
      <transition 
        name="freedisappear"
        mode="out-in"
      >
        <a
          v-show="showShipSupplement"
          v-expose="analysisDataEvent()"
          :class="[
            'cart-tip__operation cdcdcd',
            showCartRights ? 'cart-tip__operation_new' : ''
          ]"
          tabindex="0"
          :aria-label="language.SHEIN_KEY_PWA_15091"
        >
          <span
            :class="[
              showCartRights ? 'free-shipping-button' : ''
            ]"
          >
            {{ language.SHEIN_KEY_PWA_15091 }}
          </span>
          <sui_icon_more_right_12px
            size="12px"
            :is-rotate="cssRight"
          />
        </a>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FreeShip',
}
</script>
<script setup>
import { computed } from 'vue'
import { sui_icon_more_right_12px, sui_icon_selected_16px, sui_icon_freeshipping_16px_color, sui_icon_shipping_16px } from '@shein-aidc/icon-vue2'
import { daEventCenter } from 'public/src/services/eventCenterV2'
import CountDown from 'public/src/pages/cart_v2/components/common/countDown.vue'
import ClientOnly from 'vue-client-only'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
const { state, getters } = useStore()

const emit = defineEmits(['addOn'])

const props = defineProps({
  shippingInfo: {
    type: Object,
    default: () => ({})
  },
  showCartRights: {
    type: Boolean,
    default: false
  },
  floatCountDown: {
    type: Boolean,
    default: false,
  },
  cssRight: {
    type: Boolean,
    default: false,
  },
  // 是否在弹窗中
  isInModal: {
    type: Boolean,
    default: false,
  },
  mallCode: {
    type: String,
    default: ''
  }
})

const isPlatForm = computed(() => state.isPlatForm)
const mallShippingInfo = computed(() => state.mallShippingInfo)
const language = computed(() => state.language)
const showCartActivityColor = computed(() => !cartShippingTop.value && getters['cart_v2/isShowCartActivityColor'] && !props.isInModal)
const cartShippingTop = computed(() => getters['cart_v2/cartShippingTop'])

const freeShippinpTips = computed(() => {
  return props.shippingInfo?.tip || ''
})

const showShipSupplement = computed(() => {
  return props.shippingInfo?.isOpenDiff == 1 ? true : false
})

const firstFreeShipping = computed(() => { // 首单免邮
  return props.shippingInfo.freeType == 1 ?  props.shippingInfo || null : null
})

const countDownType = computed(() => {
  return showCartActivityColor.value ? 'black' : props.floatCountDown ? 'green' : 'white'
})

const isShowSatisfyFreeTip = computed(() => {
  if(!isPlatForm.value) {
    return !showShipSupplement.value && freeShippinpTips.value || ''
  }
  // 平台提示语
  let { platform: platformShip } = mallShippingInfo.value || {}
  let platformTips = platformShip?.isOpenDiff == 1 ? '' : ( platformShip?.tip || '')
  let flag = 0
  Object.keys(mallShippingInfo.value).forEach(key => {
    if(key != 'platform') {
      let mall_shipping = mallShippingInfo.value[key]
      if(mall_shipping?.tip && !(mall_shipping?.isOpenDiff == 1)) {
        flag++
      }
    }
  })
  let notPlatfromTips = flag == (Object.keys(mallShippingInfo.value).length - 1) ? language.value.SHEIN_KEY_PWA_20345 : ''
  return notPlatfromTips || platformTips
})

const shippingAnalysisParam = computed(() => {
  return state.mallShippingAnalysisParam?.[`${props.mallCode}`] || {}
})

const analysisEvent = (id) => {
  return {
    id: firstFreeShipping.value ? '' : id,
    data: {
      shipping_free_type: isPlatForm.value ? 0 : 3,
      mall_code: isPlatForm.value ? '' : props.mallCode,
      show_position: props.floatCountDown ? 2 : 3,
      show_way: props.showWay || '-',
    }
  }
}

const analysisDataEvent = (type) => {
  let id = ''
  let { shipping_detail_obj } = props.shippingInfo
  if(type == 'click'){
    id = shipping_detail_obj ? '1-8-5-6' : '1-8-5-4'
  } else {
    id = shipping_detail_obj ? '1-8-5-5' : '1-8-5-3'
  }
  return {
    id: id,
    data: {
      ...shippingAnalysisParam.value,
      mall_code: isPlatForm.value ? '' : props.mallCode,
      show_position: props.showCartRights ? 4 : (cartShippingTop.value ? 1 : (props.floatCountDown ? 2 : 3)),
      show_way: props.showWay || '-',
    }
  }
}

const clickCartTip = () => {
  if(!showShipSupplement.value) {
    return
  }
  daEventCenter.triggerNotice(analysisDataEvent('click'))
  emit('addOn', {
    type: 'activity',
    popupInfoId: props.shippingInfo?.popupInfoId,
  })
}
</script>
<style lang="less" scoped>
.free-color {
  background-color: @sui_color_safety_bg;
}
.free-ship {
  padding: .27rem .32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--shipping-info-background, @sui_color_safety_bg);
    // color: @sui_color_safety;
  &_activity {
    border-radius: 12/37.5rem 12/37.5rem 0 0;
    background: @sui_color_safety;
    color: @sui_color_white;
    .cart-tip__operation {
      color: @sui_color_white;
    }
  }
  .item-icon {
    margin-right: 0.21rem;
  }
  .ship-content{
    flex: 1;
    text-align: left;
    margin-right: .32rem;
    color: #222;
    font-size: 12px;
    overflow: hidden;
    &_activity {
      color: @sui_color_white;
    }
    .tips {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
    .float-count-down {
      position: absolute;
      right: 0rem;
      top: -0.34rem;
    }
  }
  .cart-tip__operation {
    text-decoration: none;
    font-weight: bold;
    color: #222;
    white-space: nowrap;
    .font-dpr(24px);
    .margin-l(0.32rem);
  }
  .icon-tip-wrap{
    display: flex;
    align-items: center;
  }
  &.old-rights-funs {
    padding: 16/75rem 24/75rem;
    background: rgba(255, 243, 211, 1);
    .icon-tip-wrap {
      display: inline;
      overflow: hidden;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      line-height: 34/75rem;
      .item-icon {
        display: inline;
        line-height: 34/75rem!important;
        margin-right: 4/75rem;
      }
      .ship-content {
        display: inline;
        .tips {
          display: inline;
        }
      }
    }
    .ship-content {
      margin-right: 0;
    }
    .cart-tip__operation {
      margin-left: 8/37.5rem;
    }
  }
}

.freedisappear-leave-active {
  transition: all 2.5s cubic-bezier(1,0,1,0);
}
.freedisappear-leave-to {
  opacity: 0.85;
}
.old-rights-funs .tips {
  ::v-deep strong {
    font-weight: 600;
    color: #0E8C33;
  }
}
.cart-tip__operation_new {
  max-width: 140/75rem;
  display: flex;
  align-items: center;
}
.free-shipping-button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
