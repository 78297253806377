<template>
  <div class="recommend-guide-wrap__a">
    <imgStackGallery
      v-if="imgList?.length"
      :img-list="imgList"
      count="99+"
    />
    <div
      class="text-wrap" 
      @click="view"
    >
      <SAdapterText
        class="title"
        min-size="12"
        :text="language.SHEIN_KEY_PWA_32684"
      />
      <SAdapterText
        class="tip"
        min-size="10"
        :text="language.SHEIN_KEY_PWA_32685"
      />
    </div>
    <div
      :class="['view-btn', { 'icon-only': isIconOnly }]"
      @click="view"
    >
      <span
        ref="viewTextRef"
        class="view-text"
      >{{ language.SHEIN_KEY_PWA_32145 }}</span>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M9 3L6 6L3 3"
          stroke="white"
          stroke-linejoin="round"
        />
        <path
          opacity="0.8"
          d="M9 6L6 9L3 6"
          stroke="white"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <Icon
      name="sui_icon_close_16px_2"
      size="16px"
      color="rgba(193, 193, 193, 1)"
      @click="handleClickClose"
    />
  </div>
</template>

<script>
export default {
  name: 'RecommendGuideA',
}
</script>

<script setup>
import { ref, computed, onBeforeUnmount, onMounted, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { scrollToRecommend, analysis } from './utils'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
import ImgStackGallery from '@/public/src/pages/cart_v2/components/unpaidOrderTip/ImgStackGallery.vue'

const { state, commit } = useStore()

const isIconOnly = ref(false)
const language = computed(() => state.language)
const recommendGoods = computed(() => state.recommendGoods)
const cartIds = computed(() => state.cartIds)

const imgList = computed(() => {
  return recommendGoods.value?.slice(0, 3)?.map(goods => goods.goods_img)
})

let timer = ref(null)
const viewTextRef = ref(null)
onMounted(async() => {
  await nextTick()
  analysis('1-8-3-74', 'A')
  isIconOnly.value = viewTextRef.value?.scrollWidth > viewTextRef.value?.clientWidth
  timer.value = setTimeout(() => {
    close()
  }, 5000)
})

onBeforeUnmount(() => {
  if (timer.value) {
    clearTimeout(timer.value)
    timer.value = null
  }
})

const view = () => {
  const lastCartId = cartIds.value[cartIds.value.length - 1]
  const lastDom = document.querySelector(`.j-cart-item-${lastCartId}`)
  // 如果最后一个商品行还没有渲染，表示商品行还没有加载完，此时直接滚动到推荐位会计算不准，需要先加载完商品行再延迟滚动
  if (!lastDom) {
    commit('cart_v2/updateState', {
      key: 'renderToCartId',
      value: lastCartId
    })
    let timer = setTimeout(() => {
      scrollToRecommend('A')
      close()
      clearTimeout(timer)
      timer = null
    }, 300)
  } else {
    scrollToRecommend('A')
    close()
  }
}

const close = () => {
  commit('cart_v2/updateState', {
    key: 'showRecommendGuideA',
    value: false
  })
}

const handleClickClose = () => {
  close()
  analysis('1-8-3-76', 'A')
}
</script>

<style lang="less" scoped>
.recommend-guide-wrap__a {
  width: calc(100% - 24px);
  height: 96/75rem;
  border-radius: 8/75rem;
  margin: 0 12px;
  padding: 16/75rem 18/75rem;
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  .text-wrap {
    flex: 1;
    overflow: hidden;
    margin-left: 16/75rem;
    .title {
      font-size: 14px;
      color: rgba(255, 255, 255, 1);
      line-height: 17px;
    }
    .tip {
      color: rgba(255, 255, 255, .7);
      font-size: 12px;
      line-height: 14px;
    }
  }
  .view-btn {
    width: 104/75rem;
    height: 48/75rem;
    padding: 6/75rem 8/75rem;
    border-radius: 26/75rem;
    margin: 0 16/75rem;
    background: rgba(250, 99, 56, 1);
    color: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24/75rem;
    .view-text {
      flex: 1;
      width: fit-content;
      overflow: hidden;
      text-align: center;
    }
    &.icon-only {
      width: 48/75rem;
      height: 48/75rem;
      border-radius: 16/75rem;
      .view-text {
        display: none;
      }
    }
  }
}
</style>
