import { watch } from 'vue'

export const useRouterLevel = () => {
  if (typeof window === 'undefined') return
  let unwatch
  const register = (callback) => {
    unwatch = watch(() => _gb_app_?.$route, () => {
      callback?.()
    })
  }
  return {
    register,
    unregister: () => {
      unwatch?.()
    }
  }
}
