var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promo-guide",on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_c('div',{staticClass:"promo-guide-container",style:({
      'bottom': `${_vm.styleInfo.bottom}px`,
    })},[_c('div',{staticClass:"guide-info"},[_c('span',{directives:[{name:"tap",rawName:"v-tap",value:({
          id: '1-8-1-47',
        }),expression:"{\n          id: '1-8-1-47',\n        }"}],staticClass:"btn"},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_20715)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"guide-tips"},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_32757)+"\n      ")]),_vm._v(" "),_c('img',{staticClass:"guide-img",attrs:{"src":"http://img.ltwebstatic.com/images3_acp/2024/08/09/23/1723198024f013957327a761ce4477143483e535d5.png","width":"20"}})]),_vm._v(" "),_c('div',{staticClass:"promo-guide-ele",style:({
        'width': `${_vm.styleInfo.w}px`,
        'height': `${_vm.styleInfo.h}px`,
      })})])])
}
var staticRenderFns = []

export { render, staticRenderFns }