import { daEventCenter } from 'public/src/services/eventCenter/index'

export const click_estimated_price = (data, callback) => {
  const defaultData = {
    scene: 'cart',
    is_checked: data?.is_checked || '-',
    goods_id: data?.product?.goods_id || '-',
    sku_code: data?.id || '-',
    countdown_promotion_id: data?.aggregateProductBusiness?.estimatedPricePopUp?.biData?.countdown_promotion_id ?? '-',
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-48', extraData: callback?.(defaultData) ?? defaultData })
}

export const expose_estimated_price = (data, callback) => {
  const defaultData = {
    scene: 'cart',
    is_checked: data?.is_checked || '-',
    goods_id: data?.product?.goods_id || '-',
    sku_code: data?.id || '-',
    is_change: '-',
  }
  daEventCenter.triggerNotice({ daId: '1-8-3-47', extraData: callback?.(defaultData) ?? defaultData })
}

