<!-- 券助手入口气泡(悬浮在购物车底部) -->
<template>
  <div
    id="coupon-helper-wrapper"
    class="coupon-helper-wrapper"
  >
    <template v-if="cartShowCouponSwitch">
      <div class="wrapper-left">
        <div
          v-if="usableCouponList.length > 0"
          class="red-dot"
        >
        </div>
        <CircleProgress
          v-else-if="bestCoupon"
          class="circle-progress"
          :value="progress"
          :size="35"
          :stroke-width="3"
          color="#FA6338"
          bg-color="#EDD1C1"
        />
        <sui_icon_coupon_24px
          class="coupon-icon"
          size="20px"
          color="#fa6338"
          :class="[
            usableCouponList.length > 0 ? 'show-red-dot' : 'hide-red-dot'
          ]"
        />
      </div>
      <div class="wrapper-right">
        <!-- 有可用券 -->
        <span 
          v-if="usableCouponList.length > 0"
          class="transform-price" 
          v-html="tipText"
        >
        </span>
        <!-- 无可用券 有最优券 -->
        <div
          v-else-if="bestCoupon"
          class="disable-coupon"
        >
          <div
            class="need-tips"
            v-html="tipText"
          ></div>
          <LineProgress
            v-if="bestCoupon && progress < 100"
            class="line-progress"
            color="#FC4070"
            bg-color="#FFF7F9"
            :value="progress"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="wrapper-left">
        <div
          v-if="usableCouponList.length > 0"
          class="red-dot"
        ></div>
        <sui_icon_coupon_24px
          class="coupon-icon"
          :class="[
            usableCouponList.length > 0 ? 'show-red-dot' : 'hide-red-dot'
          ]"
          size="24px"
          color="#fa6338"
        />
      </div>
      <div class="wrapper-right">
        <div class="wrapper-right__main blod">
          {{ isShowPrice ? couponMaxPrice : language.SHEIN_KEY_PWA_17187 }}
        </div>
        <div
          v-if="usableCouponList.length > 0"
          class="wrapper-right__second"
          v-html="isShowPrice ? language.SHEIN_KEY_PWA_20710 : couponInfo?.couponTip"
        >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CouponHelperEntryBubble',
}
</script>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore.js'
import { sui_icon_coupon_24px } from '@shein-aidc/icon-vue2'
import CircleProgress from 'public/src/pages/cart_v2/components/common/progress/CircleProgress.vue'
import LineProgress from 'public/src/pages/cart_v2/components/common/progress/LineProgress.vue'

const { state } = useStore()

const language = computed(() => state.language)
const couponInfo = computed(() => state.couponInfo)
const cartShowCouponSwitch = computed(() => state.cartAbtInfo?.Cartshowcoupon?.param?.cart_show_coupon_switch == 'on')

const usableCouponList = computed(() => couponInfo.value?.cmpUsableCoupons || [])
const bestCoupon = computed(() => couponInfo.value?.bestCoupon)

const tipText = computed(() => {
  const { cartCouponTip = '', priceShowStyle = null } = bestCoupon.value
  if (priceShowStyle) return cartCouponTip.replace(priceShowStyle, `<span class="price-amount-decimal">${priceShowStyle}</span>`) + ' >'
  return cartCouponTip
})

const progress = computed(() => {
  const totalAmount = bestCoupon?.value?.realDiscountPrice?.amount
  if (totalAmount <= 0) return 0
  const needAmount = bestCoupon?.value?.needPrice?.amount
  return ((totalAmount - needAmount) / totalAmount) * 100
})

const couponMaxPrice = computed(() => couponInfo.value?.couponMaxPrice)
const isShowPrice = computed(() => {
  const CouponHelperTypenew = state.cartAbtInfo['CartCouponHelpernew']?.p || ''
  return couponMaxPrice.value && CouponHelperTypenew.split('&').includes('style=new')
})

onMounted(() => {
})
</script>

<style lang="less" scoped>
.coupon-helper-wrapper {
  width: 100%;
  height: 86/75rem;
  background-color: rgba(250, 99, 56, .95);
  display: flex;
  align-items: center;
  padding: 4/75rem;
  overflow: hidden;
  border-radius: 9999px;
  .wrapper-left {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .red-dot {
      width: 8/75rem;
      height: 8/75rem;
      background-color: rgba(255, 65, 28, 1);
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      top: -18%;
      left: 54%;
    }
    .coupon-icon {
      &.show-red-dot {
        margin-left: -3px;
      }
    }
    .circle-progress {
      position: absolute;
      left: -1/75rem;
      top: -1/75rem;
      opacity: 0;
    }
  }
  .wrapper-right {
    height: 100%;
    color: #fff;
    text-align: left;
    margin-right: 24/75rem;
    margin-left: 16/75rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-weight: unset;
      margin-left: unset;
      margin-right: unset;
    }
    .transform-price {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      /deep/.price-amount-decimal {
        font-size: 17px!important;
      }
    }
    .disable-coupon {
      width: 100%;
      .need-tips {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        white-space: normal;
      }
    }
    .line-progress {
      margin: 8/75rem 0;
    }
    .wrapper-right__main {
      font-size: 14px;
      line-height: 36/75rem;
    }
    .wrapper-right__second {
      font-size: 12px;
      line-height: 24/75rem;
    }
    .blod {
      margin-left: 4/75rem;
      font-weight: bold;
    }
  }
}
</style>
