<template>
  <div
    class="mcart-item"
    :class="{'platform-save-all':isPlatForm}"
  >
    <div tabindex="0">
      {{ language.SHEIN_KEY_PWA_31159 }}
    </div>
    <div
      v-if="loginStatus && !batchActive"
      v-enterkey
      v-tap="{
        id: '1-8-7-32',
        once: false,
        data: {
          goods_num: soldoutIds.length
        }
      }"
      v-expose="{
        id: '1-8-7-33',
        once: false,
        data: {
          goods_num: soldoutIds.length
        }
      }"
      class="save-all j-animation-shake"
      tabindex="0"
      @click="handleSaveAll()"
    >
      {{ language.SHEIN_KEY_PWA_15791 }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'CartSaveAll'
}
</script>
<script setup>
import { computed } from 'vue'
import { useStore } from '../../hooks/useStore'
import { Toast } from '@shein/sui-mobile'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { template } from '@shein/common-function'

const { state, dispatch, commit } = useStore()

const soldoutIds = computed(() => state.soldoutIds)
const isPlatForm = computed(() => state.isPlatForm)
const loginStatus = computed(() => state.loginStatus)
const batchActive = computed(() => state.batchActive)
const language = computed(() => state.language)

const handleSaveAll = () =>{
  commit('cart_v2/handleModal', {
    text: language.value.SHEIN_KEY_PWA_17954,
    okCallback: () => {
      delAllSoldOut()
    },
    cancelCallback: () => {
    },
    cancelDaId: '1-8-7-19'
  })
}

const delAllSoldOut = ()=>{
  commit('cart_v2/updateState', {
    key: 'loadingShow',
    value: true
  })
  dispatch('cart_v2/fetchCartBatchWish', { params: { id: soldoutIds.value } })
    .then(res => {
      commit('cart_v2/updateState', {
        key: 'loadingShow',
        value: false
      })
      if (res.code == 0) {
        Toast(language.value.SHEIN_KEY_PWA_15790)
        daEventCenter.triggerNotice({ daId: 'expose_save_tips:simple' })
      } else {
        if (res.code == -4 || res.code == -2) {
          Toast(language.value.SHEIN_KEY_PWA_17639)
        } else if (Number(res.code) === 400427 && res.info){
          Toast(template(res.info.limit, language.value.SHEIN_KEY_PWA_18556))
        } else {
          Toast(res.msg)
        }
      }
    })
}
</script>
<style lang="less" scoped>
.mcart-item {
  line-height: 1;
  padding: 20/75rem;
  background: #fff;
  color: #222;
  .flexbox();
  .space-between();
  .align-center();
  .font-dpr(28px);
  font-weight: bold;
  position: relative;
  &.platform-save-all{
    border-radius: 16/75rem 16/75rem 0 0;
  }
  .save-all{
    flex-shrink: 0;
    margin-left: 32/75rem;
    color: @color_brand;
    font-weight: bold;
    .font-dpr(24px);
    transition: all ease 0.1s;
  }
}
</style>
