var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.showCanceledOrderTip)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-3-66'
  }),expression:"{\n    id: '1-8-3-66'\n  }"},{name:"tap",rawName:"v-tap",value:({
    id: '1-8-3-67'
  }),expression:"{\n    id: '1-8-3-67'\n  }"}],staticClass:"canceled-order-tip",on:{"click":_setup.handleView}},[(_setup.imgList.length)?_c(_setup.ImgStackGallery,{attrs:{"img-list":_setup.imgList,"count":_setup.totalNums}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"canceled-content"},[_c('div',{staticClass:"content-up"},[_c('span',{staticClass:"canceled-tip",domProps:{"innerHTML":_vm._s(_vm.canceledOrderInfo.canceledOrderTip)}})]),_vm._v(" "),(_setup.lureInfo.type)?_c('div',{staticClass:"content-down",style:({
        color: _setup.lureInfo.textColor,
      })},[(_setup.lureInfo.prefixIcon)?_c('img',{attrs:{"src":_setup.lureInfo.prefixIcon,"width":"12","height":"12"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"lureInfo-text",domProps:{"innerHTML":_vm._s(_setup.lureInfo.text)}})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"view-button"},[_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_18965))])]),_vm._v(" "),_c('div',{directives:[{name:"tap",rawName:"v-tap",value:({
      id: '1-8-3-68'
    }),expression:"{\n      id: '1-8-3-68'\n    }"}],staticClass:"close-button",on:{"click":function($event){$event.stopPropagation();return _setup.onClickClose.apply(null, arguments)}}},[_c(_setup.sui_icon_close_16px_2,{attrs:{"size":"16px","color":"#959595"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }