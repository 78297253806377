<template>
  <div
    class="incentive-popover-content"
    @click="onClick"
  >
    <div
      v-if="contentType === 'goods'"
      class="popover-left img-block"
    >
      <div
        v-for="(item, index) in bubbleGoods"
        :key="item.cartId"
        class="incentive-img-container"
      >
        <img
          class="popover-img"
          :src="item.goodsImg"
        />
        <div
          v-if="item.discountSubscript"
          class="img-discount"
        >
          {{ item.discountSubscript.value }}
        </div>
        <div
          v-if="bubbleGoods.length > 1 && index === bubbleGoods.length - 1 && moreNums"
          class="more-nums-mask"
        >
          <span>{{ moreNums }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="[
        'popover-left',
        'coupon-block',
        `cartCheckoutPopover-${contentType}`,
      ]"
    >
      <template v-if="contentType == 'free_shipping'">
        <div class="free-shipping-text">
          {{ bubble.iconText }}
        </div>
      </template>
      <template v-else>
        <!-- <div class="coupon-up">
          {{ content.couponUpText }}
        </div> -->
        <ShrinkText
          class="coupon-up"
          :content="bubble.iconText"
          :max-width="54"
          :options="{
            fontSize: 14,
            step: 1,
            minFontSize: 10
          }"
        />
        <div class="coupon-down">
          {{ bubble.iconTextOff }}
        </div>
      </template>
    </div>
    <div class="tip-content">
      <img
        v-if="bubble.prefixIcon"
        class="content-left"
        :src="bubble.prefixIcon"
      />
      <span class="content-text">
        <span v-html="bubble.clickText"></span>
        <Icon
          v-if="contentType === 'goods'"
          class="content-text-icon"
          name="sui_icon_more_right_12px_2"
          size="12px"
        />
      </span>
      <span
        v-if="bubble.timestamp"
        class="content-right"
      >
        <CartCountdown
          :time-stamp="Number(bubble.timestamp)"
          not-reload
          not-hide
          class="incentive-countdown"
          font-size="10px"
        />
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { useStore } from '../../hooks/useStore'
import { scrollToCartItem } from '../../utils'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ShrinkText from 'public/src/pages/common/add-on/comps/base/ShrinkText.vue'
import CartCountdown from '../common/CartCountDownTime.vue'
import checkoutIncentive from './checkoutIncentive.js'

const props = defineProps({
  show: Boolean,
})

const store = useStore()
const { state, commit, dispatch } = store

const cartLureInfo = computed(() => state.checkoutState.cartLureInfo)
const content = computed(() => state.checkoutState.currentCartLure || {})
const contentType = computed(() => {
  switch (content.value.type) {
    case '5':
    case '6':
      return 'coupon'
    case '7':
      return 'free_shipping'
    case '8':
      return 'discount_shipping'
    default:
      return 'goods'
  }
})
const bubble = computed(() => content.value.bubble || {})
const bubbleGoods = computed(() => content.value.bubble?.goods || [])
const goodsNum = computed(() => content.value.bubble?.goodsNum ?? 0)
const moreNums = computed(() => {
  let num = goodsNum.value - 2
  if(num > 99) return '+99'
  if(num > 0) return '+' + num
  return ''
})

const onClick = () => {
  daEventCenter.triggerNotice({
    daId: '1-8-3-60',
    extraData: {
      ...getExtraAnalysisData('tap', content.value),
    },
  })
  if(contentType.value !== 'goods') return
  if(goodsNum.value > 1) {
    const target = cartLureInfo.value.popupInfoConfig.find(item => item.type === content.value.type)
    if (!target) return
    dispatch('cart_v2/toggleIncentiveProductListDialog', {
      show: true,
      info: {
        pop_entrance_from: 'benefit_bubble',
        type: content.value.type === '4' ? 'flash_sale' : '',
        cartIds: content.value.cartIds,
        titleIcon: target.titleIcon,
        title: target.title,
        description: target.description,
        backgroundImg: target.backgroundImg,
      },
    })
  } else if(goodsNum.value === 1) {
    dispatch('cart_v2/clearFilter')

    checkoutIncentive.triggerHide()

    const cartId = content.value.cartIds[0]
    commit('cart_v2/updateState', {
      key: 'renderToCartId',
      value: cartId,
    })
    setTimeout(() => {
      scrollToCartItem({
        id: cartId,
        highlightAnimate: true,
      })
    }, 300)
  }
  return
}

const getAnalysisAvailablePoint = () => {
  // 兜底 当为空时，说明所有利诱都展示完了
  if (!checkoutIncentive.displayedCartLureListMap.size) return ''
  return checkoutIncentive.vaildCartLureList.filter(item => !checkoutIncentive.displayedCartLureListMap.get(item.type)).map(item => item.biData.name).join(',')
}

const getExtraAnalysisData = (type = 'tap', content) => {
  const data = {
    actual_point: content.biData?.name,
    information: content.biData?.information || '',
  }

  if (type === 'expose') {
    data.available_point = getAnalysisAvailablePoint()
  }

  return data
}

watch(() => props.show, (val) => {
  if (typeof window === 'undefined') return
  if (val) {
    daEventCenter.triggerNotice({
      daId: '1-8-3-59',
      extraData: {
        ...getExtraAnalysisData('expose', content.value),
      },
    })
  }
}, {
  immediate: true,
})
</script>
<style lang="less" scoped>
.incentive-popover-content {
  display: flex;
  gap: 12/75rem;
  align-items: center;
}
.img-block {
  display: flex;
  gap: 8/75rem;
  .incentive-img-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 76/75rem;
    height: 76/75rem;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
    }
    .img-discount {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 8px;
      color: @sui_color_promo_bg;
      background: var(---sui_color_discount, #FA6338);
      padding: 1/75rem 4/75rem;
      direction: ltr /* rtl:ltr */;
    }
    .more-nums-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
.coupon-block {
  width: 120/75rem;
  height: 80/75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: contain;
  .coupon-up {
    font-weight: 860;
    text-align: center;
  }
  .coupon-down {
    font-size: 8px;
    font-weight: 700;
  }
}
.cartCheckoutPopover-free_shipping, .cartCheckoutPopover-discount_shipping {
  color: rgba(22, 158, 0, 1);
  background-image: url(https://img.ltwebstatic.com/images3_acp/2024/05/29/29/17169741487d35c0ea6f7c3e25207a824980b6e5f3.png);
  .coupon-down {
    color: rgba(22, 158, 0, 0.86);
  }
}
.cartCheckoutPopover-free_shipping {
  .free-shipping-text {
    font-size: 10px;
    font-weight: 860;
    color: rgba(22, 158, 0, 1);
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
.cartCheckoutPopover-coupon {
  color: rgba(250, 99, 56, 1);
  background-image: url(https://img.ltwebstatic.com/images3_acp/2024/05/29/4e/17169741368156f49839a54ed3b121e95ce263b150.png);
  .coupon-down {
    color: rgba(255, 100, 56, 0.8);
  }
}
.tip-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-width: 284/75rem;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 14px;
  .content-left, .content-right {
    white-space: nowrap;
  }
  .content-left {
    margin-right: 4px;
    height: 12px;
    vertical-align: text-bottom;
  }
  .content-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    & + .content-right {
      margin-left: 4px;
    }
    b {
      font-weight: 700;
    }
  }
  .content-text-icon {
    margin-left: 4px;
    transform: rotate(0) /* rtl: rotate(-180deg)*/;
  }
}
.incentive-countdown {
  display: inline;
}
.incentive-countdown ::v-deep .time-content {
  color: rgba(250, 99, 56, 1);
  display: inline;
  .count-number {
    background: rgba(250, 99, 56, 1);
    border-radius: 1px;
    color: #fff;
    padding: 0 2/75rem;
  }
}
</style>
