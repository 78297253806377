<template>
  <div 
    class="circle-wrapper"
    :style="{ 
      'width': transformRem(size),
      'height': transformRem(size)
    }"
  >
    <svg 
      :width="transformRem(size)"
      :height="transformRem(size)" 
      class="circle"
    >
      <circle
        :r="transformRem(radius)"
        :cx="transformRem(cx)"
        :cy="transformRem(cy)"
        fill="transparent"
        :stroke="_bgColor"
        :stroke-width="strokeWidth"
      />
      <circle
        :r="transformRem(radius)"
        :cx="transformRem(cx)"
        :cy="transformRem(cy)"
        fill="transparent"
        :stroke="_color"
        :stroke-width="transformRem(strokeWidth)"
        stroke-linecap="round"
        :stroke-dasharray="transformRem(circumference)"
        :stroke-dashoffset="transformRem(progress)"
      />
    </svg>
  </div>
</template>

<script>

export default {
  name: 'CircleProgress',
  props: {
    size: {
      type: [String, Number],
      default: 50
    },
    strokeWidth: {
      type: [String, Number],
      default: 1
    },
    value: {
      type: [String, Number],
      default: 0
    },
    color: {
      type: String,
      default: 'green',
    },
    bgColor: {
      type: String,
      default: '#DDD',
    },
  },
  computed: {
    cx() {
      return this.size / 2 // x轴坐标
    },
    cy() {
      return this.size / 2 // y轴坐标 
    },
    radius() {
      return (this.size - this.strokeWidth) / 2 // 半径
    },
    circumference() {
      return 2 * Math.PI * this.radius // 周长
    },
    progress() {
      return (1 - this.value / 100) * this.circumference // 进度
    },
    _color() {
      return this.color
    },
    _bgColor() {
      return this.bgColor
    }
  },
  methods: {
    transformRem(num) {
      return num * 2 / 75 + 'rem'
    }
  },
}
</script>

<style lang="less" scoped>
.circle-wrapper {
  display: inline-block;
  .circle {
    transform: rotate(-90deg);
  }
}
</style>
