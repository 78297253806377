<template>
  <div
    ref="container"
    v-expose="{
      id: '1-8-3-73',
      data: {
        ...getExposeAnalysisData()
      }
    }"
    class="cart-rightsFuns-tip_new"
  >
    <div
      :class="[
        'left-tip', // 存在右侧文案new user或returnee bonus
        {
          'only-one-rights': !rightItemText && ((showFreeShipping && !isFreeReturn) || (!showFreeShipping && isFreeReturn)), // 无右侧文案，且仅存在freeshipping或freereturn一个权益：居中展示
          'shipping-and-return': (!rightItemText && showFreeShipping && isFreeReturn) && !textOverflow, // 无右侧文案，同时存在freeshipping和freereturn权益：以｜平分宽度居中展示，溢出取消平分｜和居中
        }
      ]"
    >
      <div
        v-if="cartRights.freeShipping"
        class="free-shipping-rights"
      >
        <FreeShippingIcon />
        <span
          ref="leftItem1"
          class="free-shipping-text"
          v-html="cartRights.freeShipping.tip"
        >
        </span>
      </div>
      <div
        v-if="cartRights.freeReturn"
        class="free-return-rights"
      >
        <FreeReturnIcon />
        <span
          ref="leftItem2"
          class="free-return-text"
          v-html="cartRights.freeReturn.tip"
        >
        </span>
      </div>
    </div>
    <div
      v-if="rightItemText"
      ref="rightItem"
      class="tip-right"
      v-html="rightItemText"
    >
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick, watch, computed } from 'vue'
import FreeShippingIcon from './FreeShippingIcon.vue'
import FreeReturnIcon from './FreeReturnIcon.vue'

const props = defineProps({
  cartRights: {
    type: Object,
    default: () => ({})
  }
})


const textOverflow = ref(false)

watch(() => props.cartRights.freeShipping, () => {
  if (rightItemText.value) {
    adjustFontSizeAndLayout()
  }
})

const container = ref(null)
const leftItem1 = ref(null)
const leftItem2 = ref(null)
const rightItem = ref(null)

const showFreeShipping = computed(() => props.cartRights.freeShipping)
const isFreeReturn = computed(() => props.cartRights.freeReturn)

const rightItemText = computed(() => {
  let { rightInfo, newUserOnly } = props.cartRights
  return rightInfo?.tip || newUserOnly?.tip || ''
})

async function adjustFontSizeAndLayout() {
  await nextTick()
  if (rightItemText.value) {
    container.value.classList.remove('flow-layout')
    if (leftItem1.value) leftItem1.value.style.fontSize = '12px'
    if (leftItem2.value) leftItem2.value.style.fontSize = '12px'
    if (rightItem.value) rightItem.value.style.fontSize = '14px'
    if (rightItem.value.scrollWidth > rightItem.value.clientWidth || rightItem.value.clientWidth == 0) {
      if (leftItem1.value) leftItem1.value.style.fontSize = '10px'
      if (leftItem2.value) leftItem2.value.style.fontSize = '10px'
      rightItem.value.style.fontSize = '12px'
      container.value.classList.add('flow-layout')
    }
  } else {
    if (leftItem1.value) leftItem1.value.style.fontSize = '12px'
    if (leftItem2.value) leftItem2.value.style.fontSize = '12px'
    if (leftItem1.value?.scrollWidth > leftItem1.value?.clientWidth || leftItem2.value?.scrollWidth > leftItem2.value?.clientWidth) {
      if (leftItem1.value) leftItem1.value.style.fontSize = '10px'
      if (leftItem2.value) leftItem2.value.style.fontSize = '10px'
      textOverflow.value = true
    }
  }
}

function getExposeAnalysisData() {
  let type = []
  if(props.cartRights.freeReturn) type.push('freereturn')
  if(props.cartRights.freeShipping) type.push('freeship')
  return {
    right_type: type.join(',')
  }
}

onMounted(() => {
  adjustFontSizeAndLayout()
  window.addEventListener('resize', adjustFontSizeAndLayout)
})

</script>
<style lang="less" scoped>
.cart-rightsFuns-tip_new {
  width: 100%;
  min-height: 64/75rem;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 8/75rem 24/75rem;
  background: #FFF3D3;
  gap: 0 20/75rem;
  .left-tip {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    padding:  8/75rem 0;
    gap: 0 16/75rem;
    max-width: 100%;
    color: #0E8C33;
    font-size: 12px;
    font-weight: 590;
    flex-wrap: nowrap;
    overflow: hidden;
    .free-shipping-rights,
    .free-return-rights {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow: hidden;
      svg {
        flex-shrink: 0;
        margin-right: 2/75rem;
      }
      .free-shipping-text,
      .free-return-text {
        flex-shrink: 1;
        // line-height: 32/75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .free-shipping-rights {
      flex-shrink: 0;
      max-width: 100%;
    }
    .free-return-rights {
      flex-shrink: 1;
    }
    &.only-one-rights {
      width: 100%;
      .free-shipping-rights,
      .free-return-rights {
        justify-content: center;
        width: 100%;
      }
    }
    &.shipping-and-return {
      width: 100%;
      .free-shipping-rights,
      .free-return-rights {
        justify-content: center;
        width: 50%;
      }
      .free-return-rights {
        border-left: solid 1px rgba(241, 221, 165, 1);
      }
    }
  }
  .tip-right {
    font-size: 14px;
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.flow-layout {
    flex-wrap: wrap;
    .left-tip {
      padding: 0;
    }
  }
}
</style>
