<template>
  <div
    ref="CustomDrawer"
    class="custom-drawer"
    :style="styles"
  >
    <div 
      v-show="isShowMask"
      class="drawer-mask" 
      @click="handleClose"
    >
    </div>
    <Transition 
      name="slide"
      @after-enter="onAfterEnter"
    >
      <div 
        v-show="value"
        ref="drawerContent"
        class="drawer-content"
        :style="drawerContentStyle"
      >
        <slot :distanceToBottom="distanceToBottom"></slot>
        <Icon
          v-if="!hideCloseIcon"
          class="close-icon"
          :color="iconColor"
          name="sui_icon_close_16px"
          size="16px"
          @click="handleClose" 
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue2'
import { debounce } from '@shein/common-function'
import { useRouterLevel } from 'public/src/pages/cart_v2/hooks/useRouterLevel.js'

export default {
  name: 'CustomDrawer',
  setup() {
    const { register, unregister } = useRouterLevel()
    return {
      register,
      unregister,
    }
  },
  components: {
    Icon
  },
  props: {
    value: { // 隐藏显示
      type: Boolean,
      default: false,
    },
    attach: { // 附属于某个dom，用于定位
      type: [String, Object],
      default: null,
    },
    iconColor: {
      type: String,
      default: '#222',
    },
    styleConfig: {
      type: Object,
      default: () => ({
        top: '10%',
        height: 'auto'
      }),
    },
    hideCloseIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentY: 0,
      distanceToBottom: 0, // 默认0, 全屏
      contentHeight: '',
      key: null,
    }
  },
  computed: {
    ...mapState('cart_v2', [
      'customDrawerKeys'
    ]),
    attachDom() {
      return typeof this.attach === 'string' ? document.querySelector(this.attach) : this.attach
    },
    styles() {
      return {
        '--drawer-bottom': `${this.distanceToBottom}px`,
        '--drawer-height': this.styleConfig.height || this.contentHeight,
        '--drawer-max': this.styleConfig.max || '100%',
        '--drawer-min': this.styleConfig.min || '0%',
        '--drawer-z-index': this.styleConfig.zIndex || '',
      }
    },
    drawerContentStyle() {
      return {
        'borderRadius': this.styleConfig.borderRadius || `${12 / 37.5}rem ${12 / 37.5}rem 0 0`,
      }
    },
    isShowMask() {
      return this.value && this.customDrawerKeys[0] === this.key
    }
  },  
  watch: {
    value: {
      handler(n) {
        if (n) {
          this.$nextTick(() => {
            this.register(this.handleClose)
            document.body.classList.add('cart-hidden-to-body')
            this.calcDistanceToBottom()
            if (n && !this.styleConfig.height) {
              this.calcHeight()
            }
            this.key = Symbol()
            this.updateCustomDrawers({ key: this.key, action: 'add' })
            this.toggleUptt(false)
          })
        } else {
          this.unregister()
          this.updateCustomDrawers({ key: this.key, action: 'remove' })
          if (this.customDrawerKeys.length === 0) {
            document.body.classList.remove('cart-hidden-to-body')
            this.toggleUptt(true)
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations('cart_v2', ['updateCustomDrawers']),
    init() {
      this.$nextTick(() => {
        this.calcDistanceToBottom()
        this.initMutationObserver()
      })
    },
    // 计算弹窗距离底部距离
    calcDistanceToBottom() {
      if (!this.attachDom) return
      const rect = this.attachDom.getBoundingClientRect()
      this.currentY = rect.y
      this.distanceToBottom = (window?.innerHeight - rect.y) || 0
    },
    calcHeight() {
      this.contentHeight = this.$refs.drawerContent.clientHeight + this.distanceToBottom + 'px'
    },
    initMutationObserver() {
      if (!this.attachDom) return
      const debounceFn = debounce({
        func: () => {
          this.calcDistanceToBottom()
        },
        wait: 17,
      })

      const observer = new MutationObserver(() => {
        const rect = this.attachDom.getBoundingClientRect()
        if (this.currentY !== rect.y) {
          debounceFn()
        }
      })
      observer.observe(this.attachDom, { 
        attributes: true, 
        attributeFilter: ['style', 'class'],
        childList: true,
        subtree: true,
      })
    },
    handleClose() {
      this.$emit('input', false)
      this.$emit('close')
    },
    onAfterEnter() {
      this.$emit('afterEnter')
    },
    toggleUptt(show) {
      if (typeof window === 'undefined' || !document.querySelector('.j-uptt')) return
      document.querySelector('.j-uptt').style.visibility = show ? 'visible' : 'hidden'
    }
  },
}
</script>

<style lang="less" scoped>
.custom-drawer {
  position: fixed;
  z-index: var(--drawer-z-index);
  .drawer-mask {
    position: fixed;
    width: 100%;
    top: 0;
    bottom: var(--drawer-bottom);
    background: rgba(0,0,0,0.4);
  }
  .drawer-content {
    position: fixed;
    width: 100%;
    max-width: 10rem;
    bottom: var(--drawer-bottom);
    height: calc(var(--drawer-height) - var(--drawer-bottom));
    min-height: calc(var(--drawer-min) - var(--drawer-bottom));
    max-height: calc(var(--drawer-max) - var(--drawer-bottom));
    background: #fff;
    border-radius: 12/37.5rem 12/37.5rem 0 0;
    overflow: hidden;
    z-index: 9;
    .close-icon {
      position: absolute;
      width: 26/37.5rem;
      height: 26/37.5rem;
      right: 0;
      top: 0;
      padding: 4/37.5rem 4/37.5rem 0 0;
    }
  }
}

.slide-enter,
.slide-leave-active {
  transform: translate3d(0, 100%, 0);
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter-to,
.slide-leave {
  transform: translate3d(0, 0, 0);
}
</style>
