import Vue from 'vue'

let instance
function mountComponent() {
  return new Promise(resolve => {
    import(/* webpackChunkName: "cart_delete" */'./CartDeleteDialog.vue').then(module => {
      const component = module.default
      const Constructor = Vue.extend(component)
      instance = new Constructor()
      document.body.appendChild(instance.$mount().$el)
      resolve()
    })
  })
}
/**
 * 打开删除挽留弹窗
 * @param {object} options 
 * @param {string} options.type 删除类型，'single' 单个删除，'batch' 批量删除
 * @param {object} [options.data ] 删除弹窗的数据，批量删除时必传
 * @param {object} [options.goodsData] 商品行数据，单个删除时必传
 * @param {object} options.language 多语言
 * @param {string[]} [options.ids] 删除的商品id，批量删除时必传
 * @param {boolean} [options.isShowOverLimitToast] 是否展示超限提示
 * @param {object} [options.events] 回调事件
 * @param {function} [options.events.onAfterOpen] 打开弹窗后回调
 * @param {function} [options.events.onBeforeDelete] 删除前回调
 * @param {function} [options.events.onDeleteSuccess] 删除成功未更新数据回调
 * @param {function} [options.events.onAfterDelete] 删除后回调
 * @param {function} [options.events.onBeforeClose] 关闭弹窗前回调
 * @param {function} [options.events.onAfterClose] 关闭弹窗后回调
 * @param {function} [options.events.onBeforeM2W] 收藏前回调
 * @param {function} [options.events.onM2WSuccess] 收藏成功未更新数据回调
 * @param {function} [options.events.onAfterM2W] 收藏后回调
 * @param {function} [options.events.onBeforeDeleteAndSimilar] 删除并找相似前回调
 * @param {function} [options.events.onAfterDeleteAndSimilar] 删除并找相似后回调
 * @param {function} [options.events.onBeforeUnChecked] 取消商品勾选状态前回调
 * @param {function} [options.events.onAfterUnChecked] 取消商品勾选状态后回调
 * @param {function} [options.events.onBeforeCancel] 取消删除弹窗前回调
 * @param {function} [options.events.onAfterCancel] 取消删除弹窗后回调
 */
export default async function showCartDeleteDailog(options) {
  if (!instance) {
    await mountComponent()
  }
  instance.show(options)
}
