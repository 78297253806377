class PromiseActuator {
  constructor() {
    this.promise = null
    this.resolve = null

    this.init()
  }

  init() {
    this.promise = new Promise(r => {
      this.resolve = r
    })
  }
}

export const CHECKOUT_REFETCH_CART_API = new PromiseActuator()
