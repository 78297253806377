var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"incentive-popover-content",on:{"click":_setup.onClick}},[(_setup.contentType === 'goods')?_c('div',{staticClass:"popover-left img-block"},_vm._l((_setup.bubbleGoods),function(item,index){return _c('div',{key:item.cartId,staticClass:"incentive-img-container"},[_c('img',{staticClass:"popover-img",attrs:{"src":item.goodsImg}}),_vm._v(" "),(item.discountSubscript)?_c('div',{staticClass:"img-discount"},[_vm._v("\n        "+_vm._s(item.discountSubscript.value)+"\n      ")]):_vm._e(),_vm._v(" "),(_setup.bubbleGoods.length > 1 && index === _setup.bubbleGoods.length - 1 && _setup.moreNums)?_c('div',{staticClass:"more-nums-mask"},[_c('span',[_vm._v(_vm._s(_setup.moreNums))])]):_vm._e()])}),0):_c('div',{class:[
      'popover-left',
      'coupon-block',
      `cartCheckoutPopover-${_setup.contentType}`,
    ]},[(_setup.contentType == 'free_shipping')?[_c('div',{staticClass:"free-shipping-text"},[_vm._v("\n        "+_vm._s(_setup.bubble.iconText)+"\n      ")])]:[_c(_setup.ShrinkText,{staticClass:"coupon-up",attrs:{"content":_setup.bubble.iconText,"max-width":54,"options":{
          fontSize: 14,
          step: 1,
          minFontSize: 10
        }}}),_vm._v(" "),_c('div',{staticClass:"coupon-down"},[_vm._v("\n        "+_vm._s(_setup.bubble.iconTextOff)+"\n      ")])]],2),_vm._v(" "),_c('div',{staticClass:"tip-content"},[(_setup.bubble.prefixIcon)?_c('img',{staticClass:"content-left",attrs:{"src":_setup.bubble.prefixIcon}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"content-text"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.bubble.clickText)}}),_vm._v(" "),(_setup.contentType === 'goods')?_c(_setup.Icon,{staticClass:"content-text-icon",attrs:{"name":"sui_icon_more_right_12px_2","size":"12px"}}):_vm._e()],1),_vm._v(" "),(_setup.bubble.timestamp)?_c('span',{staticClass:"content-right"},[_c(_setup.CartCountdown,{staticClass:"incentive-countdown",attrs:{"time-stamp":Number(_setup.bubble.timestamp),"not-reload":"","not-hide":"","font-size":"10px"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }