var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"promo-zone"},[_c('h3',{staticClass:"title ellipsis"},[_vm._v("\n    "+_vm._s(_vm.info.title)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"container"},[(_vm.bestChoiceGroup)?[_c('PromoStickyHead',{staticClass:"best-deals-head",attrs:{"bg-color":'linear-gradient(180deg,#ffecec -100%,#f3f2f3)',"radius-color":'#FFECEC',"title":_vm.bestChoiceGroup.title,"num":_vm.bestChoiceGroup.titleTopRightCornerNum},scopedSlots:_vm._u([{key:"topRight",fn:function(){return [(_vm.bestChoiceGroup.rightTips)?_c('span',{directives:[{name:"tap",rawName:"v-tap",value:({
              id: '1-8-1-55',
            }),expression:"{\n              id: '1-8-1-55',\n            }"}],staticClass:"top-right",style:({
              color: _vm.bestChoiceGroup.rightTips.textColor,
            }),on:{"click":function($event){return _vm.$emit('clickDetail')}}},[_c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(_vm.bestChoiceGroup.rightTips.text))]),_vm._v(" "),_c('Icon',{attrs:{"name":"sui_icon_more_down_12px_2","size":"14px"}})],1):_vm._e()]},proxy:true}],null,false,511272548)}),_vm._v(" "),_c('div',{staticClass:"list best-choice-groups"},[(_vm.bestChoiceGroup.bestExtraInfoText)?_c('div',{staticClass:"list-item all-best-tips"},[_c('img',{attrs:{"src":_vm.bestChoiceGroup.bestExtraUrl,"width":"14","height":"14"}}),_vm._v(" "),_c('span',{staticClass:"ellipsis"},[_vm._v("\n            "+_vm._s(_vm.bestChoiceGroup.bestExtraInfoText)+"\n          ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.bestChoiceGroup.subItems),function(item,index){return _c('ItemBestChoice',{directives:[{name:"tap",rawName:"v-tap",value:({
            id: '1-8-1-54',
            data: {
              best_deals: _vm.getSaDataCode(item)
            }
          }),expression:"{\n            id: '1-8-1-54',\n            data: {\n              best_deals: getSaDataCode(item)\n            }\n          }"}],key:index,staticClass:"list-item",attrs:{"item":item}})})],2)]:_vm._e(),_vm._v(" "),(_vm.moreDealsGroup)?[_c('PromoStickyHead',{attrs:{"bg-color":'#FFF',"radius-color":'#FFECEC',"title":_vm.moreDealsGroup.title,"num":_vm.moreDealsGroup.titleTopRightCornerNum}}),_vm._v(" "),_c('div',{staticClass:"list"},_vm._l((_vm.moreDealsGroup.subItems),function(item,index){return _c('ItemMoreDeals',{directives:[{name:"tap",rawName:"v-tap",value:({
            id: '1-8-1-54',
            data: {
              unlock_deals: _vm.getSaDataCode(item)
            }
          }),expression:"{\n            id: '1-8-1-54',\n            data: {\n              unlock_deals: getSaDataCode(item)\n            }\n          }"}],key:index,staticClass:"list-item",attrs:{"item":item,"expandPid":_vm.expandPid},on:{"handleExpand":_vm.handleExpand}})}),1)]:_vm._e(),_vm._v(" "),(_vm.otherPromoGroup)?[_c('PromoStickyHead',{attrs:{"bg-color":'#FFF',"radius-color":_vm.moreDealsGroup ? '#FFF' : '#FFECEC',"title":_vm.otherPromoGroup.title,"num":_vm.otherPromoGroup.titleTopRightCornerNum},scopedSlots:_vm._u([{key:"topRight",fn:function(){return [(_vm.otherPromoGroup.rightTips)?_c('span',{staticClass:"top-right",style:({
              color: _vm.otherPromoGroup.rightTips.textColor,
            }),on:{"click":_vm.viewMore}},[_c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(_vm.otherPromoGroup.rightTips.text))]),_vm._v(" "),_c('Icon',{attrs:{"is-responsive-name":"","name":_vm.expandOtherPromoList ? 'sui_icon_more_up_12px_2' : 'sui_icon_more_down_12px_2',"size":"14px"}})],1):_vm._e()]},proxy:true},{key:"bottom",fn:function(){return [_c('p',{staticClass:"optional-sub-tips ellipsis"},[_vm._v("\n            "+_vm._s(_vm.otherPromoGroup.subTitle)+"\n          ")])]},proxy:true}],null,false,189787658)}),_vm._v(" "),_c('div',{ref:"otherPromoRef",staticClass:"list"},[(_vm.expandOtherPromoList)?_vm._l((_vm.otherPromoGroup.subItems),function(item,index){return _c('ItemOptionalDeals',{key:index,staticClass:"list-item",attrs:{"item":item}})}):_vm._e()],2)]:_vm._e(),_vm._v(" "),(_vm.info.bottomTip)?_c('div',{staticClass:"no-more-tips"},[_c('span',{staticClass:"txt ellipsis"},[_vm._v(_vm._s(_vm.info.bottomTip))])]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }