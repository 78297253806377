<template>
  <SSticky class="sticky">
    <div 
      class="promo-sticky-head"
      :style="{
        '--bg': bgColor,
        '--radius-bg': radiusColor,
      }"
    >
      <p class="top">
        <span class="top-left">
          <span class="title">{{ title }}</span>
          <span 
            v-if="num >= 2"
            class="num" 
          >
            {{ num }}
          </span>
        </span>
        <slot name="topRight"></slot>
      </p>
      <slot name="bottom"></slot>
    </div>
  </SSticky>
</template>

<script>
import { Sticky as SSticky } from '@shein/sui-mobile'

export default {
  name: 'PromoStickyHead',
  components: { SSticky },
  props: {
    bgColor: {
      type: String,
      default: 'transparent',
    },
    radiusColor: {
      type: String,
      default: 'transparent',
    },
    title: {
      type: String,
      default: '',
    },
    num: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" scoped>
.sticky {
  z-index: 8;
}
.promo-sticky-head {
  position: relative;
  padding: 10/37.5rem 12/37.5rem;
  box-sizing: content-box;
  background: var(--bg);
  border-top-left-radius: 12/37.5rem;
  border-top-right-radius: 12/37.5rem;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--radius-bg);
    z-index: -1;
  }
  .top {
    display: flex;
    align-items: center;
    height: 20/37.5rem;
    .top-left { /* stylelint-disable-line selector-max-specificity */
      flex: 1;
      font-size: 14px;
      font-weight: bold;
      color: #000;
      display: flex;
      align-items: center;
      overflow: hidden;
      .title { /* stylelint-disable-line selector-max-specificity */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .num { /* stylelint-disable-line selector-max-specificity */
        padding-left: 4/37.5rem;
      }
    }
  }
}
</style>
