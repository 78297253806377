<template>
  <div
    v-enterkey
    class="cart-checkout__price-wrapper isRight"
    tabindex="0"
    role="button"
    @click="handleClick"
  >
    <div class="j-totalAmount cart-checkout__price">
      <div
        v-if="crossedPrice"
        class="cart-checkout__price-del"
      >
        <span class="tips">
          <template v-if="crossedPrice.description">{{ crossedPrice.description }} </template>
          <AmountRoll
            class="amount"
            :amount="crossedPrice.price"
            tag="del"
          />
        </span>
      </div>
      <div
        v-if="totalPrice"
        :style="{
          'color': totalPrice.color
        }"
        class="cart-checkout__price-total"
      >
        <span class="tips">
          <span
            v-if="cartInfo.withCouponTip"
            class="prefix"
          >{{ cartInfo.withCouponTip }}</span>
          <AmountRoll
            class="amount"
            :amount="totalPrice.price"
          />
          <i
            v-if="showTotalPriceDetailEnter"
            class="cart-checkout__arrow"
            :class="[isOpened && 'active']"
          >
            <sui_icon_more_down_12px_2
              size="12px"
              :color="totalPrice.color"
            />
          </i>
        </span>
      </div>
    </div>
    <div
      v-show="(localLang == 'au' || localLang == 'nz') && isShowGST"
      class="price-gst-new"
    >
      (GST)
    </div>
    <div 
      v-if="cartInfo.taxInclusiveTip && isShowTax"
      class="cart-checkout__tax-info"
    >
      {{ cartInfo.taxInclusiveTip }}
    </div>

    <div
      v-show="showSavePriceDetailEnter"
      v-expose="{id: '1-8-2-1'}"
      class="j-totalDiscountAmount cart-checkout__total-discount"
    >
      <span
        v-if="saveAmount"
        class="cart-checkout__price-discount"
      >
        <span class="total-discount-txt">{{ saveAmount.description }}</span>
        <AmountRoll
          class="amount"
          :amount="saveAmount.price"
        />
      </span>
      <i
        v-if="saveAmount"
        class="cart-checkout__arrow"
        :class="[isOpened && 'active']"
      >
        <sui_icon_more_down_12px_2
          size="12px"
          :color="saveAmount.color"
        />
      </i>
    </div>
  </div>
</template>
<script>
/**
 * @description 购物车/结算BAR/价格小组件
 */
export default {
  name: 'CartCheckoutPrice',
}
</script>

<script setup>
import { computed } from 'vue'
import { useStore } from '../../hooks/useStore'
import AmountRoll from '../common/AmountRoll.vue'
import { sui_icon_more_down_12px_2 } from '@shein-aidc/icon-vue2'

const { state } = useStore()

const emit = defineEmits(['click'])
defineProps({
  isShowGST: {
    type: Boolean,
    default: true
  },
  isShowTax: {
    type: Boolean,
    default: true
  },
  isOpened: {
    type: Boolean,
    default: false,
  }
})

const localLang = computed(() => state.locals.lang)

const cartInfo = computed(() => state.cartInfo)
// 整车价格模块
const mallCartPriceData = computed(() => {
  return cartInfo.value?.mallCartPriceData || {}
})
// 划线价
const crossedPrice = computed(() => {
  return mallCartPriceData.value?.crossedPrice || null
})
// 到手价
const estimatedPrice = computed(() => {
  return mallCartPriceData.value?.estimatedPrice || null
})

// 节省金额
const savedPrice = computed(() => {
  return mallCartPriceData.value?.savedPrice || null
})
// 总价
const totalPrice = computed(() => {
  return mallCartPriceData.value?.totalPrice || null
})
const saveAmount = computed(() => {
  if (mallCartPriceData.value?.priceMode !== 'normal') {
    return estimatedPrice.value
  }
  return savedPrice.value
})

/**
 * 优惠提示右侧是否展示整车优惠明细箭头
 */
const showSavePriceDetailEnter = computed(() => {
  return !!saveAmount.value && cartInfo.value.showPromotionDetailPopUp == '1'
})
/**
 * 总价右侧是否展示整车优惠明细箭头
 */
const showTotalPriceDetailEnter = computed(() => {
  return !showSavePriceDetailEnter.value && cartInfo.value.showPromotionDetailPopUp == '1' ? 1 : 0
})
const handleClick = () => {
  emit('click')
}
</script>

<style lang="less">
.cart-checkout {
  &__price-wrapper {
    flex: 1;
    line-height: 1.5;
    overflow: hidden;
    max-width: 100%;

    .price-gst-new {
      font-size: 12px;
      line-height: 1;
      color: #222222;
    }

    &.isRight {
      text-align: right;
      .cart-checkout__price-del {
        direction: rtl;
      }
      .tips {
        direction: ltr;
        unicode-bidi: bidi-override;
      }
      .cart-checkout__price {
        justify-content: flex-end;
      }
      .cart-checkout__total-discount {
        justify-content: flex-end;
      }
      .total-discount-txt {
        direction: rtl;
      }
    }
  }

  &__price {
    display: flex;
    align-items: baseline;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }

  &__price-del {
    flex: 1;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    margin-right: 4/75rem;
    font-size: 10px;
    color: @sui_color_gray_dark3;
  }

  &__total-discount {
    color: @sui_color_discount_dark;
    font-size: 12px;
    padding-top: 4/75rem;
    line-height: 1;
    display: flex;
    .total-discount-txt {
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      max-width: 100%;
      word-break: break-all;
      flex:1;
      padding-right: 4/75rem;
    }
  }

  &__price-discount {
    display: flex;
    white-space: nowrap;
  }

  &__price-total {
    max-width: 100%;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 1;
    .amount {
      display: inline-flex;
      align-items: baseline;
      font-weight: bold;
      .price-amount {
        font-size: 17px;
      }
      .price-amount-decimal {
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        max-width: 100%;
        word-break: break-all;
        font-size: 10px;
      }
    }
    &.discount {
      color: @sui_color_discount;
    }
    .tips {
      max-width: 100%;
      overflow: hidden;
      display: inline-flex;
      line-height: 40/75rem;
      .prefix {
        overflow: hidden;
        white-space: nowrap;
        flex: 1;
        flex-shrink: 1;
        text-overflow: ellipsis;
        line-height: 44/75rem;
        color: rgba(118, 118, 118, 1);
        margin-right: 4/75rem;
        font-size: 24/75rem;
      }
      .amount {
        flex-shrink: 0;
      }
    }
  }

  &__arrow {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    transform: rotate(0deg);
    transition: transform 0.2s linear;
    width: 12px;
    height: 12px;
    &.active {
      transform: rotate(180deg);
    }
    &.arrow-color{
      color: @sui_color_discount;
    }
  }
}
</style>
