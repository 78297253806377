import { expose_goods_userbehaviortips } from 'public/src/pages/cart_v2/analysis/item/behaviorLabel.js'

/**
 * BehaviorLabel 原子组件操作
 */
export function useBehaviorLabelEvent() {

  /**
   * 曝光
   */
  const onLabelExpose = ({ itemOpts: { data, event }, analysis = {} }) => {
    const hasLabels = data.value.product?.actTags?.find(item => item.hasAvailableTag === '1')
    if (hasLabels) {
      expose_goods_userbehaviortips(data.value, (defaultData) => {
        const data = {
          ...defaultData,
          userbehavior_tips_show: event.exposeLabel.join(','),
        }
        return analysis.expose_goods_userbehaviortips?.(data) ?? data
      })
    }
  }

  return {
    onLabelExpose,
  }
}

