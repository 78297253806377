var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCountDown),expression:"showCountDown"}],staticClass:"new-down-time",class:[{active: _vm.diffDays == 0}]},[_c('div',{staticClass:"time-content"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.diffDays),expression:"diffDays"}],class:[
        _vm.mode === 'block' && 'count-number',
        'count-down-days'
      ]},[_vm._v(_vm._s(_vm.daysComputed)+" ")]),_vm._v(" "),_c('span',{class:[
        _vm.mode === 'block' && 'count-number',
        'count-down-hours'
      ],style:({fontSize: _vm.fontSize})},[_vm._v(_vm._s(_vm.twoNums(_vm.diffHours)))]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.delimiter)}}),_vm._v(" "),_c('span',{class:[
        _vm.mode === 'block' && 'count-number',
        'count-down-mins'
      ],style:({fontSize: _vm.fontSize})},[_vm._v(_vm._s(_vm.twoNums(_vm.diffMinutes)))]),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.delimiter)}}),_vm._v(" "),_c('span',{class:[
        _vm.mode === 'block' && 'count-number',
        'count-down-secs'
      ],style:({fontSize: _vm.fontSize})},[_vm._v(_vm._s(_vm.twoNums(_vm.diffSeconds)))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }