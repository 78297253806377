<template>
  <div
    v-if="promotionTip"
    v-expose="analysisData('expose_cartpromotionadd.page_cart')"
    class="cart_item-header new-promotion-header"
    :class="[`j-promotion-header-${promotionInfo.data.promotion_id}`, {
      'new-cartrow-vision': isNewCartrowVision
    }]"
    :type-id="promotionInfo.data.type_id"
  >
    <!-- 横幅类型 -->
    <div class="new-promotion-header__content">
      <slot></slot>
      <section
        class="cart_item-banner"
        tabindex="0"
        :aria-label="promotionTip"
      >
        <div class="title-action-operation">
          <i
            class="new-promotion-header__icon"
          >
            <component
              :is="[2,28].includes(+promotionInfo.data.type_id) ? sui_icon_gift_18px:sui_icon_activity_18px"
              size="15px"
            />
          </i>
          <div class="promotion-header-content">
            <p
              class="cart_item-tip"
              :class="{'cart_tip-red': promotionInfo.data.overLimit == 1}"
              tabindex="0"
              v-html="promotionTip"
            >
            </p>
            <div
              v-if="promotionInfo.data.is_count_down && promotionInfo.data.end_time"
              class="client-only-height"
            >
              <ClientOnly>
                <count-down-time :time-stamp="+promotionInfo.data.end_time" />
              </ClientOnly>
            </div>
          </div>
          <div
            v-if="action > 0 && !batchActive"
            v-enterkey
            class="cart_item-action"
            tabindex="0"
            role="button"
            @click="clickPick"
          >
            <span style="vertical-align: text-bottom">{{ handleText }}</span>
            <sui_icon_more_right_12px
              size="14px"
              :is-rotate="locals.GB_cssRight"
            />
          </div>
        </div>
      </section>
    </div>
    <FullGiftList
      v-if="showFullGiftsList"
      :gift-list="giftList"
      :promotion-id="promotionInfo.data.promotion_id"
      :disabled-tip="action !=2 ? promotionTip : ''"
      :type="1"
      :promotion-type="promotionInfo.data.type_id"
    />
  </div>
</template>
<script>
export default {
  name: 'PromotionHeader',
}
</script>
<script setup>
import { computed } from 'vue'
import { sui_icon_more_right_12px, sui_icon_gift_18px, sui_icon_activity_18px } from '@shein-aidc/icon-vue2'
import CountDownTime from './../common/CartCountDownTime.vue'
import { useStore } from '../../hooks/useStore'
import ClientOnly from 'vue-client-only'
import { markPoint } from 'public/src/services/mark/index.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
import FullGiftList from 'public/src/pages/cart_v2/components/cartList/FullGiftList.vue'
import { useRouter } from 'public/src/pages/cart_v2/hooks/useRouter.js'
const { commit, state } = useStore()
const { routerPush } = useRouter()
const AddItem = useCartAddItem()

const props = defineProps({
  promotionInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  },
  isNewCartrowVision: {
    type: Boolean,
    default: false
  },
  batchActive: {
    type: Boolean,
    default: false
  }
})

const promotionTip = computed(() => {
  return props.promotionInfo?.promotionTips?.html_text || ''
})

const isPicked = computed(() => {
  return props.promotionInfo?.data?.isPicked == 1
})

const giftList = computed(() => {
  return props.promotionInfo.data?.promotionGoods || []
})

const showFullGiftsList = computed(() => {
  return !isPicked.value && giftList.value.length && !props.batchActive
})

// 0: 无操作; 1: Add，可以添加商品; 2: Pick，可以选择商品
const  action = computed(() =>{
  let actionOpt = 0
  const { type_id, range, next, isMeet  } = props.promotionInfo.data || {}
  if (type_id == 1) {
    actionOpt = 1
  }
  if ([2, 13, 28].includes(+type_id)) {
    actionOpt = isMeet == 1 ? 2 : 1
  }
  if ([9, 14, 15, 20, 21, 25].includes(+type_id)) {
    actionOpt = range <= 0 ? 1 : next != '0' ? 1 : 0
  }
  return actionOpt
})

const handleText = computed(() => {
  if( action.value == 1 ) {
    return props.language.SHEIN_KEY_PWA_15091
  } else if(action.value == 2) {
    return isPicked.value ? props.language.SHEIN_KEY_PWA_15831 : props.language.SHEIN_KEY_PWA_15830
  }
  return ''
})

const openToAddOn = (promotion) => {
  if (!promotion?.popupInfoId) return
  AddItem.show({
    popupInfoId: promotion?.popupInfoId,
    state: 'promotion_add',
  })
}

const clickPick = async () => {
  if (props.batchActive) return
  const { promotion_id, type_id, sc_id, popupInfoId, promotionJumpLink = '' } = props.promotionInfo.data
  const addGoodToReach = sc_id && action.value == 1
  const buyGiftPick = type_id == 2 && (action.value == 2)
  const addBuyPick = type_id == 13 && (action.value == 2)
  const partFullGifts = type_id == 28 && (action.value == 2)

  const sendClickAnalysis = () => {
    daEventCenter.triggerNotice(analysisData('click_cartpromotionadd.page_cart'))
  }

  if(
    [9, 25, 20, 1, 15, 14].includes(+type_id) || ([13, 2, 28].includes(+type_id) && action.value == 1)
  ) {
    openToAddOn({ popupInfoId })
    sendClickAnalysis()
    return
  }

  if (buyGiftPick) {
    handlePickItem(promotion_id)
    sendClickAnalysis()
  } else if (addBuyPick) {
    handlePickItem(promotion_id)
    sendClickAnalysis()
  } else if (partFullGifts) {
    handlePickItem(promotion_id)
    sendClickAnalysis()
  } else if (addGoodToReach) {
    sendClickAnalysis()
    markPoint('toNextPageClick', 'public')
    if(promotionJumpLink){
      routerPush(`${gbCommonInfo.langPath}${promotionJumpLink}`)
    }
    // newWin.location = url
  }
}

const handlePickItem = (promotionId) => {
  commit('cart_v2/changeAppendageDrawerStatus', { promotionId })
}

const analysisData = (id) => {
  let { promotion_id, mainProductRange, is_shop_group, store_id = '-' } = props.promotionInfo.data
  const { isFullPromotion, typeId, is_couponbag } = state.promotionState.cartPromotionAnalysis?.[promotion_id] || {}
  return {
    id,
    data: {
      typeId,
      isFullPromotion: isFullPromotion || 0,
      is_brand_product: mainProductRange == 4 ? 1 : 0,
      is_couponbag: is_couponbag,
      is_member_gift: 0,
      is_all_store: is_shop_group == 1 ? 1 : 0,
      store_id: store_id,
    }
  }
}

</script>
<style lang="less" scoped>
.new-promotion-header {
  display: block;
  &__content {
    display: flex;
  }
  .cart_item-tip /deep/ .store-in-promo {
    color: #626262;
    margin-left: 4/37.5rem;
  }
}
.new-promotion-header__content{
  width: 100%;
}
</style>
