<script setup>
import { ref, computed, onUnmounted, nextTick, watch } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

const props = defineProps({
  showFreeShippingTag: {
    type: [Boolean, String],
    default: false,
  },
  showQuickShipTag: {
    type: [Boolean, String],
    default: false,
  },
  language: {
    type: Object,
    default: () => ({}),
  },
  // 轮播间隔时间 ms
  interval: {
    type: Number,
    default: 3000,
  },
  // 动画时长 ms
  duration: {
    type: Number,
    default: 250,
  },
  // 伸展方向
  stretchDirection: {
    type: String,
    default: 'ltr',
  },
  // 主题变量
  themeVars: {
    type: Object,
    default: () => ({}),
  },
  show: { // 是否展示, 用于重新计算宽高
    type: Boolean,
    default: true
  }
})

const flag = ref(false)
let timer = null
const index = ref(0)
const changeIndex = () => {
  index.value = (index.value + 1) % tags.value.length
}

const width = ref(0)
const height = ref(0)
const setSize = (el) => {
  if (!el) return
  const { width: _width, height: _height } = el.getBoundingClientRect()
  width.value = _width
  height.value = _height
}

const containerRef = ref(null)
// 解决初始渲染时，宽高为0，伪元素出现的问题
const isHide = ref(true)
const start = (length) => {
  if (length <= 0) {
    width.value = 0
    height.value = 0
    isHide.value = true
  }
  flag.value = false
  index.value = index.value >= length ? 0 : index.value
  nextTick(() => {
    setSize(containerRef.value?.children?.[index.value])
    isHide.value = !(width.value && height.value)
  })
  if (length <= 1) {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    return
  }
  flag.value = true
  !timer && (timer = setInterval(changeIndex, props.interval))
}
const tags = computed(() => {
  let tags = []
  if (props.showQuickShipTag) {
    tags.push({
      icon: 'sui_icon_qucikship_flat_24px',
      text: typeof props.showQuickShipTag === 'string' ? props.showQuickShipTag : props.language.SHEIN_KEY_PWA_22276,
      tagType: 'quickShip',
      id: ''
    })
  }
  if (props.showFreeShippingTag) {
    tags.push({
      icon: 'sui_icon_free_shipping_12px',
      text: typeof props.showFreeShippingTag === 'string' ? props.showFreeShippingTag : props.language.SHEIN_KEY_PWA_23920,
      tagType: 'freeShipping',
      id: '1-8-3-21'
    })
  }
  start(tags.length)
  return tags
})

const onEnter = (el) => {
  setSize(el)
}
watch(() => props.show, (val) => {
  if (val) {
    nextTick(() => {
      start(tags.value.length)
    })
  } else {
    clearInterval(timer)
    timer = null
  }
}, { immediate: true })
onUnmounted(() => {
  clearInterval(timer)
})
</script>

<template>
  <div
    v-show="tags.length > 0"
    :class="[
      'swiper-tag',
      {
        'swiper-tag_hide': isHide,
      }
    ]"
    :style="{
      ...themeVars
    }"
  >
    <div
      ref="containerRef"
      class="swiper-tag__container"
      :style="{
        '--duration': `${duration}ms`,
        width: width ? `${width}px` : undefined,
        height: height ? `${height}px` : undefined,
      }"
    >
      <template
        v-for="(tag, i) in tags"
      >
        <Transition
          :key="i"
          :name="!isHide && flag ? 'slide' : ''"
          @enter="onEnter"
        >
          <div
            v-show="i === index"
            v-expose="{ id: tag.id }"
            :class="[
              'swiper-tag__item',
              {
                'swiper-tag__item_ltr': stretchDirection === 'ltr',
                'swiper-tag__item_rtl': stretchDirection === 'rtl',
                'swiper-tag__item_quick-ship': tag.tagType === 'quickShip',
              }
            ]"
          >
            <Icon
              v-if="tag.icon"
              :name="tag.icon"
              is-responsive-name
              size="12px"
              class="swiper-tag__icon"
            />
            <span
              class="swiper-tag__text"
            >
              {{ tag.text }}
            </span>
          </div>
        </Transition>
      </template>
    </div>
  </div>
</template>

<style lang="less" scoped>
.swiper-tag {
  &.swiper-tag_hide {
    opacity: 0;
  }
  .swiper-tag__container {
    position: relative;
    overflow: hidden;
    transition: all var(--duration) linear;
    background-color: @sui_color_success;
    color: @sui_color_white;
    border-radius: var(--border-radius);
  }
  .swiper-tag__item {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 1px 8/75rem;
    width: max-content;
    max-width: 234/75rem;
  }
  .swiper-tag__item_ltr {
    left: 0;
  }
  .swiper-tag__item_rtl {
    right: 0;
  }
  .swiper-tag__item_quick-ship {
    font-style: italic;
    .swiper-tag__text {
      padding: 0 1px 0 0;
    }
  }
  .swiper-tag__icon {
    margin-right: 4/75rem;
  }
  .swiper-tag__text {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all var(--duration) linear;
}

.slide-enter {
  transform: translate3d(0, 100%, 1px);
}
.slide-leave-to {
  transform: translate3d(0, -100%, 1px);
}
</style>
