import Vue from 'vue'

let instance
function mountComponent() {
  return new Promise(resolve => {
    import(/* webpackChunkName: "checked_tip" */'./CheckedTip.vue').then(module => {
      const component = module.default
      const Constructor = Vue.extend(component)
      instance = new Constructor()
      document.body.appendChild(instance.$mount().$el)
      resolve()
    })
  })
}
export default async function showCheckedTip({ btnText, content }, events) {
  if (!instance) {
    await mountComponent()
  }
  instance.show({ btnText, content }, events)
}
