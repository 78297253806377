<template>
  <div>
    <s-popover
      v-model="showCheckoutBtnPopover"
      trigger-type="user"
      placemen="top-end"
      :show-close-icon="false"
      :prop-style="popoverStyle"
    >
      <span>{{ language.SHEIN_KEY_PWA_23995 }}</span>
      <div
        slot="reference"
        :class="[
          'checkout-btn-contain',
        ]"
      >
        <slot></slot>
      </div>
    </s-popover>
  </div>
</template>
<script>
export default {
  name: 'CheckoutFilterPopover',
}
</script>
<script setup>
import { computed, defineEmits } from 'vue'
import { useStore } from '../../hooks/useStore'

const { state } = useStore()

const props = defineProps({
  showFilterLabelPopover: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['set-popover-type'])
const language = state.language
const popoverStyle = computed(() => {
  const locals = state.locals
  return {
    zIndex: 30,
    bottom: '64px',
    top: 'unset',
    position: 'fixed',
    right: locals.GB_cssRight ? 'auto' : '0.32rem',
    left: locals.GB_cssRight ? '0.32rem' : 'auto',
  }
})

const showCheckoutBtnPopover = computed({
  get() {
    return props.showFilterLabelPopover == 'filter'
  },
  set(val) {
    if(!val) emit('set-popover-type', '')
  }
})

</script>

