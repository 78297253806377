function bindContainerScrollListener () {
  // 优惠券助手悬浮窗
  const animateStart = (configs, duration = 361) => {
    configs.forEach(item => {
      const element = document.querySelector(item.attr)
      if (element) {
        const keyframes = [
          { opacity: window.getComputedStyle(element).opacity }
        ]
        keyframes.push(item.style)
  
        element.animate(
          keyframes,
          { duration: duration, fill: 'forwards', easing: 'linear' }
        )
      }
    })
  }

  let lastTimeScrollTop = 0
  const setCouponHelperBehavior = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    if ((lastTimeScrollTop && scrollTop) || (lastTimeScrollTop <= 0 && scrollTop <= 0)) return lastTimeScrollTop = scrollTop
    lastTimeScrollTop = scrollTop
    if (scrollTop == 0) {
      if (document.querySelector('.is-old-style')?.length) {
        document.querySelector('.j-coupon-helper-bubble').classList.remove('bubble-fold')
      } else {
        animateStart([
          { attr: '.wrapper-right', style: { opacity: 1 } },
          { attr: '.circle-progress', style: { opacity: 0 } },
          { attr: '#coupon-helper-wrapper', style: { width: '100%', height: `${86 / 75}rem` } },
          { attr: '.wrapper-left', style: { width: '1rem', height: '1rem' } },
        ])
        // document.querySelector('.circle-progress')?.__vue__ 这样可以获取进度条的组件
      }
    } else {
      if (document.querySelector('.is-old-style')?.length) {
        document.querySelector('.j-coupon-helper-bubble').classList.add('bubble-fold')
      } else {
        animateStart([
          { attr: '.wrapper-right', style: { opacity: 0 } },
          { attr: '.circle-progress', style: { opacity: 1 } },
          { attr: '#coupon-helper-wrapper', style: { width: `${68 / 75}rem`, height: `${68 / 75}rem` } },
          { attr: '.wrapper-left', style: { width: '.8rem', height: '.8rem' } },
        ])
      }
    }
  }

  const handleScroll = () => {
    if (!this.$router) return 
    const routerName = this.$router.currentRoute.name
    const switching = this.$router.switching

    // 路由切换时 or 路由非cart时 return
    if(switching || routerName !== 'cart') return

    if(this.carts?.length){
      // fullGiftSticky()
      setCouponHelperBehavior()
    }
  }
  window.onscroll = handleScroll
}

export {
  bindContainerScrollListener
}
