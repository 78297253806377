export default function useLoadingWithDelay(requestFunc, loadingFunc, { delay = 800 } = {}) {
  const doRequest = async (...args) => {
    let result
    let timer = setTimeout(() => {
      loadingFunc(true)
    }, delay)
    try {
      result = await requestFunc(...args)
    } catch (error) {
      result = {
        code: '-1',
        error
      }
    } finally {
      clearTimeout(timer)
      timer = null
      loadingFunc(false)
    }
    return result
  }
  return {
    doRequest
  }
}
