import { computed, nextTick } from 'vue'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'
import { Toast } from '@shein/sui-mobile'
import { template, debounce } from '@shein/common-function'
import showCartDeleteDailog from 'public/src/pages/cart_v2/components/cartDeleteDialog/showCartDeleteDailog'
import { expose_newonly_addpop, click_cart_quantity_edit, expose_reachedflashsalelimit, click_goods_add_qty, click_goods_sub_qty } from 'public/src/pages/cart_v2/analysis/item/goodsQty'
import useChecked from 'public/src/pages/cart_v2/hooks/useChecked'
import useLoadingWithDelay from 'public/src/pages/cart_v2/hooks/useLoadingWithDelay'

export function useGoodsQtyEvent() {
  const { setLocalChecked } = useChecked()
  const { state, dispatch, commit } = useStore()
  const batchActive = computed(() => state.batchActive)
  const language = computed(() => state.language)
  const monitorUserInteraction = computed(() => state?.monitorUserInteraction)

  const isNewUserGoods = (goodsData, analysis) => {
    const result = goodsData.aggregateProductBusiness?.showNewUsersBonus == '1'
    if (result) {
      const { maxLimitPurchaseQuantity = '' } = goodsData?.aggregateProductBusiness || {}
      if (!isNaN(+maxLimitPurchaseQuantity) && +maxLimitPurchaseQuantity > 0) {
        Toast(template(maxLimitPurchaseQuantity, language.value.SHEIN_KEY_PWA_26184))
        expose_newonly_addpop({}, analysis?.expose_newonly_addpop)
      }
    }
    return result
  }
  const handleError = (res, analysis) => {
    const { langPath } = gbCommonInfo
    if (res.code == 300206) {
      window.location = langPath + '/user/logout'
      return
    }
    if (['300714', '500302', '500306', '302444', '300417', '500303'].includes(res.code)) {
      expose_reachedflashsalelimit({}, analysis?.expose_reachedflashsalelimit)
    }
    Toast(res.msg)
  }

  const onChange = async ({ itemOpts: { data, event } = {}, options: { isShowOverLimitToast = false } = {}, analysis }) => {
    if (batchActive.value) return
    if (isNewUserGoods(data.value)) return
    const oldQuantity = data.value.quantity
    const resetQuantity = () => {
      nextTick(() => {
        data.value.quantity = oldQuantity
      })
    }
    const { quantity, action } = event
    if (quantity === oldQuantity) return
    data.value.quantity = quantity
    if (quantity > 99) {
      Toast(template('99', language.value.SHEIN_KEY_PWA_17829))
      resetQuantity()
      return
    }
    if (quantity > data.value.realTimeInventory) {
      data.value.quantity = data.value.realTimeInventory
    }
    if (data.value.is_checked == '0') {
      setLocalChecked({ ids: [data.value.id], checked: true })
    }
    const reqParams = {
      cart_id: data.value.id,
      quantity,
      is_checked: data.value.is_checked,
      append_id_list: data.value.appendIds || []
    }
    const { doRequest } = useLoadingWithDelay(
      () => dispatch('cart_v2/fetchCartUpdate', reqParams),
      (show) => {
        commit('changeRootSLoadingStatus', {
          show,
          maskTransparent: true,
        })
      }
    )
    try {
      const res = await doRequest()
      if (res.code == '0') {
        if (action === 'plus') {
          click_goods_add_qty(data.value, analysis?.click_goods_add_qty)
        } 
        if (action === 'minus') {
          click_goods_sub_qty(data.value, analysis?.click_goods_sub_qty)
        }
        dispatch('cart_v2/showOverLimitToast', { id: data.value.id, isShowOverLimitToast })
      } else {
        setLocalChecked({ ids: [data.value.id], checked: undefined })
        resetQuantity()
        handleError(res, analysis)
      }
      // 当编辑商品数量时，停止低效用户监测
      monitorUserInteraction.value?.stop?.({ reason: 'change goods number' })
    } catch(error) {
      if (error.code === 'ERR_CANCELED') return
      setLocalChecked({ ids: [data.value.id], checked: undefined })
      resetQuantity()
    }
  }
  const onDelete = ({ itemOpts: { data } = {}, options: { type = 'single', events = {}, isShowOverLimitToast = false } = {}, analysis }) => {
    if (batchActive.value) return
    if (isNewUserGoods(data.value)) return
    showCartDeleteDailog({
      type,
      goodsData: data.value,
      language: language.value,
      isShowOverLimitToast,
      events,
      analysis
    })
  }
  const onMinus = ({ itemOpts: { data } = {} }) => {
    if (batchActive.value) return
    if (isNewUserGoods(data.value)) return
  }
  const onPlus = ({ itemOpts: { data } = {} }) => {
    if (batchActive.value) return
    if (isNewUserGoods(data.value)) return
  }
  const onClickInput = ({ itemOpts: { data } = {}, analysis }) => {
    if (batchActive.value) return
    if (isNewUserGoods(data.value, analysis)) return
    click_cart_quantity_edit({}, analysis?.click_cart_quantity_edit)
  }

  return {
    onChange: debounce({ func: onChange, wait: 200 }),
    onDelete,
    onMinus,
    onPlus,
    onClickInput,
  }
}
