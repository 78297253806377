<template>
  <div
    v-infinite-scroll="infiniteScroll"
    class="cart-list j-cart-list"
    :infinite-scroll-distance="10000"
    infinite-scroll-nodata="noMoreData"
    :style="{
      '--checkbox-margin-right': isNewCartrowVision ? 16/75+'rem' : 20/75+'rem'
    }"
  >
    <!-- 筛选栏 -->
    <CartListFilter
      v-if="showCartListFilter && allFilterLabels.length > 0"
      v-show="!batchActive"
      ref="CartListFilterRef"
      :all-filter-labels="allFilterLabels"
      :model-value="filterTagId"
      @update:modelValue="handleFilterClick"
    />
    <!-- 筛选补充信息 -->
    <FilterAdditionalInfo v-show="!batchActive" />
    <!-- loading -->
    <EmbedLoading
      top
      :show="embedLoadingShow"
    />
    <div
      v-for="mallCartItem in mallCartList"
      :key="mallCartItem.mall_code"
      class="warehouse-group"
      :class="[
        isPlatForm? 'platform-warehouse-group':'noplatform-warehouse-group',
        {
          'noplatform-show-store': !isPlatForm && !cartShowStore,
          'warehouse-group__opt-bg': cartIds && cartIds.length < 3,
        }
      ]"
    >
      <MallHeader
        v-if="isPlatForm"
        v-show="!batchActive"
        :key="mallCartItem.mall_code"
        :mall-name="mallCartItem.mall_name"
        :mall-desc="mallCartItem.mallDesc"
        :language="language"
        :shipping-activity-data="mallCartItem.shippingActivityData"
        :promotion-data="mallCartItem.promotionData"
        :locals="locals"
        :mall-code="mallCartItem.mall_code"
        :mall-quick-operation="mallCartItem.mallQuickOperation"
        @anchorToMall="anchorToMall(mallCartItem.mallQuickOperation)"
      >
        <s-checkbox
          :label="mallCartItem.mall_code"
          :value="getChecked(mallCartItem.cart_id_list)"
          :margin-r="isNewCartrowVision ? 16/75+'rem' : 20/75+'rem'"
          :disabled="isDisabled(mallCartItem.cart_id_list)"
          @change="(res) => handleGroupCheckedItem(res, mallCartItem)"
        />
      </MallHeader>
      <template
        v-for="shop in mallCartItem.shops"
      >
        <template v-if="shop.store_code">
          <store-header
            :key="mallCartItem.mall_code+'-'+shop.store_code + '-' + shop.shopName"
            :store-code="shop.store_code"
            :shop-name="shop.shopName"
            :store-type="shop.store_type"
            :store-trend-logo="shop.store_trend_logo"
            :has-store-coupon="shop?.hasStoreCoupon == '1'"
            :preferred-seller-store="shop.preferred_seller_store"
            :store-routing="shop.storeRouting"
            :is-new-store-icon="isNewStoreIcon"
            :locals="locals"
            :is-new-cartrow-vision="isNewCartrowVision"
            :mall-code="mallCartItem.mall_code"
          >
            <s-checkbox
              :label="mallCartItem.mall_code +','+shop.store_code"
              :value="batchActive ? isChecked(shop.cart_id_list) : getChecked(shop.cart_id_list)"
              :disabled="isDisabled(shop.cart_id_list)"
              :margin-r="isNewCartrowVision ? 16/75+'rem' : 20/75+'rem'"
              @change="(res) => handleGroupCheckedItem(res, shop)"
            />
          </store-header>
        </template>
        <template
          v-for="(content, index) in shop.contentData"
        >
          <div
            v-if="index > 0"
            :key="mallCartItem.mall_code + '-' +(shop.store_code) +'-'+ index"
            class="store-group-gap"
          ></div>
          <full-gift-header
            v-if="!isPlatForm && content.groupHeadInfo && content.groupHeadInfo.data && content.groupHeadInfo.data.type_id == 4"
            v-show="!batchActive"
            :key="'full-'+content.groupHeadInfo.data.promotion_id"
            :promotion-info="content.groupHeadInfo"
            :language="language"
            :mall-code="mallCartItem.mall_code"
            :locals="locals"
          />
          <full-add-on-header
            v-else-if="!isPlatForm && content.groupHeadInfo && content.groupHeadInfo.data && content.groupHeadInfo.data.type_id == 22"
            v-show="!batchActive"
            :key="'addon-'+content.groupHeadInfo.data.promotion_id"
            :promotion-info="content.groupHeadInfo"
            :language="language"
            :mall-code="mallCartItem.mall_code"
            :locals="locals"
          />
          <promotion-header
            v-else-if="content.groupHeadInfo && content.groupHeadInfo.data"
            :key="'promotion-'+content.groupHeadInfo.data.promotion_id"
            :promotion-info="content.groupHeadInfo"
            :language="language"
            :locals="locals"
            :batch-active="batchActive"
            :is-new-cartrow-vision="isNewCartrowVision"
          >
            <s-checkbox
              v-if="content.groupHeadInfo.data.is_shop_group != 1"
              :label="content.groupHeadInfo.data.promotion_id"
              :value="batchActive ? isChecked(content.groupHeadInfo.data.cart_id_list) : getChecked(content.groupHeadInfo.data.cart_id_list)"
              :disabled="isDisabled(content.groupHeadInfo.data.cart_id_list)"
              :margin-r="isNewCartrowVision ? 16/75+'rem' : 20/75+'rem'"
              @change="(res) => handleGroupCheckedItem(res, content.groupHeadInfo.data)"
            />
          </promotion-header>
          <CartItem
            v-for="item in content.productLineInfoList.filter(i => cartIds.indexOf(i.id) < ssrRenderNum)"
            :key="item.id"
            :item="item"
            :index="cartIds.indexOf(item.id)"
            :action-to-refresh-recommend="item.id == actionToRefreshRecCartId"
            class="j-valid-cart-item"
          />
          <ClientOnly
            v-for="item in content.productLineInfoList.filter(i => cartIds.indexOf(i.id) >= onlySsrRenderNum && renderCount > cartIds.indexOf(i.id))"
            :key="'item'+item.id"
          >
            <CartItem
              :key="item.id"
              :item="item"
              :index="cartIds.indexOf(item.id)"
              :action-to-refresh-recommend="item.id == actionToRefreshRecCartId"
              class="j-valid-cart-item"
            />
          </ClientOnly>
        </template> 
      </template>
      <div 
        v-if="!batchActive && mallCartItem.clientMultiLangKeyTip && mallCartItem.clientMultiLangKeyTip.negativeInfoEnter && negativeInformation[mallCartItem.mall_code]"
        v-tap="analysisData('1-8-3-19', mallCartItem.mall_code)"
        v-expose="analysisData('1-8-3-20', mallCartItem.mall_code)"
        class="negative-information-enter"
        :class="{'multi-mall': isPlatForm}"
        @click="hanlderNegativeInformation(mallCartItem.mall_code)"
      >
        <i>
          <sui_icon_info_12px_2
            size="15px"
            :is-rotate="locals.GB_cssRight"
          />
        </i>
        <span class="information-enter-tip">{{ mallCartItem.clientMultiLangKeyTip.negativeInfoEnter }}</span>
        <span class="information-enter-action">
          {{ mallCartItem.clientMultiLangKeyTip.negativeInfoView }}
        </span>
      </div>
    </div>
    <div
      v-if="soldoutItems.length"
      v-expose="exposeSoldOuts()"
      class="warehouse-group"
      :class="[isPlatForm? 'platform-warehouse-group':'noplatform-warehouse-group']"
    >
      <cart-save-all />
      <CartItem
        v-for="(item) in soldoutItems.filter(i => cartIds.indexOf(i.id) < ssrRenderNum)"
        :key="item.id"
        :item="item"
        :index="cartIds.indexOf(item.id)"
        :not-need-checkbox="true"
        :action-to-refresh-recommend="item.id == actionToRefreshRecCartId"
      />
      <ClientOnly>
        <CartItem
          v-for="(item) in soldoutItems.filter(i => cartIds.indexOf(i.id) >= onlySsrRenderNum && renderCount > cartIds.indexOf(i.id))"
          :key="item.id"
          :item="item"
          :index="cartIds.indexOf(item.id)"
          :not-need-checkbox="true"
          :action-to-refresh-recommend="item.id == actionToRefreshRecCartId"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartList',
}
</script>
<script setup>  
import { computed, ref, onActivated, onMounted, nextTick, watch } from 'vue'
import { useStore } from '../../hooks/useStore'
import { useRouter } from '../../hooks/useRouter'
import MallHeader from '../../components/cartList/MallHeader.vue'
import StoreHeader from '../../components/cartList/StoreHeader.vue'
import FullGiftHeader from '../../components/cartList/FullGiftHeader.vue'
import FullAddOnHeader from '../../components/cartList/FullAddOnHeader.vue'
import PromotionHeader from '../../components/cartList/PromotionHeader.vue'
import CartSaveAll from '../../components/cartList/CartSaveAll.vue'
import CartItem from './CartItem.vue'
import ClientOnly from 'vue-client-only'
import CartListFilter from 'public/src/pages/cart_v2/components/cartListFilter/index.vue'
import FilterAdditionalInfo from 'public/src/pages/cart_v2/components/filterAdditionalInfo/index.vue'
import EmbedLoading from '@/public/src/pages/components/EmbedLoading.vue'
import { sui_icon_info_12px_2 } from '@shein-aidc/icon-vue2'
import { useCouponFilterGuide } from '@/public/src/pages/cart_v2/components/cartGuideManager/couponFilter/useCouponFilterGuide.js'
import useModifyCartCheckStatus from 'public/src/pages/cart_v2/hooks/useModifyCartCheckStatus'
import useChecked from 'public/src/pages/cart_v2/hooks/useChecked'
import { getUserActionTrackerData } from 'public/src/pages/common/userActionTracker/core'

const { state, getters, commit, dispatch } = useStore()
const { route } = useRouter()
const { getChecked } = useChecked()

let CartListFilterRef = ref(null)
useCouponFilterGuide(CartListFilterRef)

const emit = defineEmits(['cartListIsMounted', 'renderRecommend', 'noMoreItemRender', 'scrollToCartItem'])

let renderCount = ref(0)
let onlySsrRenderNum = ref(6)
let initRenderCount = ref(0)
let isShowFilterTips = ref(false) // show only once

const soldoutItems = computed(() => state.soldoutItems)
const isPlatForm = computed(() => state.isPlatForm)
const language = computed(() => state.language)
const mallCartList = computed(() => state.mallCartList)
const cartIds = computed(() => state.cartIds)
const carts = computed(() => state.carts)
const locals = computed(() => state.locals)
const renderToCartId = computed(() => state.renderToCartId)
const batchActive = computed(() => state.batchActive)
const ssrPageType = computed(() => state.ssrPageType)
const batchEditItemIds = computed(() => state.batchEditItemIds)
const cartItemMap = computed(() => state.cartItemMap)

const isNewCartrowVision = computed(() => getters['cart_v2/isNewCartrowVision'])
const isNewStoreIcon = computed(() => getters['cart_v2/isNewStoreIcon'])
const cartShowStore = computed(() => getters['cart_v2/cartShowStore'])
const negativeInformation = computed(() => getters['cart_v2/negativeInformation'])
const cartLinkedRecoLine = computed(() => getters['cart_v2/cartLinkedRecoLine'])
const cartLinkedRecoCount = computed(() => getters['cart_v2/cartLinkedRecoCount'])

const ssrRenderNum = computed(() => {
  if(ssrPageType.value == 'cart') return onlySsrRenderNum.value
  return Math.min(onlySsrRenderNum.value, renderCount.value)
})

const noMoreData = computed(() => {
  return renderCount.value && renderCount.value >= cartIds.value.length
})

const batchEditCartItemIds = computed( {
  get () {
    return batchEditItemIds.value
  },
  set (arr) {
    commit('cart_v2/updateState', {
      key: 'batchEditItemIds',
      value: arr || []
    })
  }
})

watch(() => noMoreData.value, (n, o) => {
  if(typeof window == 'undefined') return
  if(n){
    emit('noMoreItemRender')
  }
}, {
  immediate: true
})

watch(()=> renderToCartId.value, (n, o) => {
  if(n) {
    const count = getRenderCountByCartId(n)
    renderCount.value = Math.max(renderCount.value, count)
  }
})

const trackObserve = ref(null)
const triggerRecommendRefreshDom = ref(null) // 触发推荐刷新的商品行
const currentTrackInfo = ref({}) // 当前收集到的用户行为数据
const currentTriggerCount = ref(0) // 当前触发刷新的次数
const refreshRecommendFlag = computed(() => state.refreshRecommendFlag)
// 是否监测到新的用户行为数据
const checkUpdateUserTrack = () => {
  currentTrackInfo.value = getUserActionTrackerData('cart_v2').rec_ctx || {}
  const lastTrackInfo = JSON.parse(window.sessionStorage.getItem('cart_v2_track_info_last')) || { rt_f_cart_clk: '', rt_f_cart_delete: '' }
  if (currentTrackInfo.value?.rt_f_cart_clk == lastTrackInfo?.rt_f_cart_clk && currentTrackInfo.value?.rt_f_cart_delete == lastTrackInfo?.rt_f_cart_delete) return false
  return true
}
// 更新用户行为信息及触发次数
const updateTrackInfo = () => {
  const { rt_f_cart_clk = '', rt_f_cart_delete = '' } = currentTrackInfo.value
  window.sessionStorage.setItem('cart_v2_track_info_last', JSON.stringify({ rt_f_cart_clk, rt_f_cart_delete }))
  window.sessionStorage.setItem('cart_v2_track_trigger_count', currentTriggerCount.value + 1)
  checkClearObserve()
}
const checkClearObserve = () => {
  // 如果触发次数已经达到，取消对dom的监测
  if (+window.sessionStorage.getItem('cart_v2_track_trigger_count') >= +cartLinkedRecoCount.value) {
    trackObserve.value?.disconnect()
    trackObserve.value = null
  }
}
const setObserve = async () => {
  const cartBottom = document.querySelector('.j-cart-footer')?.clientHeight || 0
  const cartHeader = document.querySelector('.j-cart-header-wrap')?.clientHeight || 0
  const observerOptions = {
    root: null,
    rootMargin: `-${cartHeader}px 0px -${cartBottom}px 0px`,
    threshold: 1
  }
  trackObserve.value = new IntersectionObserver((entries, observer) => {
    if (entries[0].isIntersecting) {
      checkClearObserve()
      // 对比数据是否发生变化
      const isUpdate = checkUpdateUserTrack()
      currentTriggerCount.value = +window.sessionStorage.getItem('cart_v2_track_trigger_count')
      if (isUpdate && currentTriggerCount.value < +cartLinkedRecoCount.value) {
        // 满足刷新推荐数据条件，在重置refreshRecommendFlag之前记录选中的商品id，供onTrigger获取
        const checkedGoodsIds = carts.value.filter(c => c.is_checked == '1')?.map(c => c.product?.goods_id)
        window.sessionStorage.setItem('cart_v2_track_info_select', checkedGoodsIds.join(','))
        const oldValue = refreshRecommendFlag.value
        commit('cart_v2/updateState', {
          key: 'refreshRecommendFlag',
          value: !oldValue
        })
        updateTrackInfo()
      }
    }
  }, observerOptions)
  triggerRecommendRefreshDom.value && trackObserve.value?.observe(triggerRecommendRefreshDom.value)
}

const actionToRefreshRecCartId = computed(() => cartIds.value[cartIds.value.length - (+cartLinkedRecoLine.value)]) // 目标商品行id
watch(() => renderCount.value, async(n, o) => {
  await nextTick()
  triggerRecommendRefreshDom.value = document.querySelector(`.j-cart-item-${actionToRefreshRecCartId.value}`)
  if (!triggerRecommendRefreshDom.value) return
  const index = cartIds.value.length - +cartLinkedRecoLine.value
  const currentTrackCount = +(window.sessionStorage.getItem('cart_v2_track_trigger_count') || 0)
  // 目标商品行已渲染 && 目标商品行未被监测 && abt配置的触发刷新次数未超出
  if (n >= index && trackObserve.value == null && currentTrackCount < cartLinkedRecoCount.value) {
    setObserve()
  }
})

onMounted(() => {
  emit('cartListIsMounted')
  initRenderCount.value = getRenderCountByCartId(route.query.to)
  if(ssrPageType.value == 'cart') {
    renderCount.value = initRenderCount.value
    nextTick(() => {
      emit('renderRecommend')
    })
  } else {
    setListItem()
  }
  if (typeof window !== 'undefined') {
    let voucherFilterTipShowed = Boolean(localStorage.getItem('voucherFilterTipShowed'))
    if (voucherFilterTipShowed) { // show only once
      isShowFilterTips.value = false
    } else { // show only once
      isShowFilterTips.value = true
    }
  }
})

onActivated(() => {
  initRenderCount.value = getRenderCountByCartId(route.query.to)
  renderCount.value = Math.max(renderCount.value, initRenderCount.value)
})

const getRenderCountByCartId = (id) => {
  const toIndex = cartIds.value.indexOf(id) + 1
  const renderCount = Math.max(onlySsrRenderNum.value, toIndex)
  return renderCount
}

const isDisabled = (cart_id_list) =>{
  return !cart_id_list.some(id => !cartItemMap.value[id].isDisabled)
}

const { modifyCartCheckStatus } = useModifyCartCheckStatus()
const handleGroupCheckedItem = ({ label = '', checked = false }, item) => {
  if(batchActive.value){
    let is_checked = isChecked(item.cart_id_list)
    item.cart_id_list.forEach(id => {
      if(!is_checked){
        if(!batchEditCartItemIds.value.includes(id)){
          batchEditCartItemIds.value.push(id)
        }
      } else {
        let site = batchEditCartItemIds.value.indexOf(id)
        site > -1 && (batchEditCartItemIds.value.splice(site, 1))
      }
    })
    return
  }

  const onBeforeModify = () => {
    daEventCenter.triggerNotice({
      daId: '1-8-3-7',
      extraData: {
        select_type: 2,
        is_select: checked ? 0 : 1,
        position: 'page',
        goods_sn: carts.value.filter(cartItem =>item.cart_id_list.includes(cartItem.id)).map(item1 => item1.product.goods_sn).join(),
      }
    })
  }
  modifyCartCheckStatus({ cartIdList: item.cart_id_list, operationType: checked ? 1 : 2, type: 'section', onBeforeModify })
}

const infiniteScroll = () => {
  if (noMoreData.value || renderCount.value < 6) return
  renderCount.value = renderCount.value + 6
}

const setListItem = () => {
  if (renderCount.value >= initRenderCount.value || noMoreData.value) {
    emit('renderRecommend')
    return
  }
  requestAnimationFrame(() => {
    renderCount.value++
    setListItem()
  })
}

const analysisData = (id, mallCode) => {
  return {
    id: id,
    once: false,
    data: {
      mall_code: mallCode
    }
  }
}

const exposeSoldOuts = () =>{
  const goodsInfo = []
  soldoutItems.value.forEach((item) => {
    const showReselect = item.aggregateProductBusiness?.changeGoodInfo?.isOtherAttr === '1'

    let saleStatus = ''
    if (showReselect) {
      saleStatus = 0
    } else {
      saleStatus = +item.quantity > +item.realTimeInventory ? 2 : 1
    }

    let member_gift_info = '-'
    if (item.isPayMemberGift == 1) {
      member_gift_info = item.isInvalid == '1' ? 'invalid' : 'no_stock'
    }

    goodsInfo.push(`${item.product.goods_id}\`${saleStatus}\`${member_gift_info}\`${item.aggregateProductBusiness.goodsStatus || ''}`)
  })
  return {
    id: '1-8-3-28',
    once: false,
    data: {
      goods_info: goodsInfo.join(','),
    }
  }
}

const hanlderNegativeInformation = (mall_code) => {
  commit('cart_v2/updateState', {
    key: 'negativeInformationDraw',
    value: {
      show: true,
      mallCode: mall_code
    }
  })
}

let embedLoadingShow = ref(false)
const filterTagId = computed(() => state?.filterState?.selectFilterLabel?.filterTagId)
const allFilterLabels = computed(() => state?.filterState?.allFilterLabels)
// 总商品数小于等于4不展示筛选
const showCartListFilter = computed(() => filterTagId.value == 'all' ? cartIds.value.length > 4 : true)
const monitorUserInteraction = computed(() => state?.monitorUserInteraction)
const handleFilterClick = async (value) => {
// if(typeof window !== 'undefined' && !navigator.onLine) {
  // this.$toast(language.value?.SHEIN_KEY_PWA_31762)
  // return
// }
  embedLoadingShow.value = true
  // 筛选状态更新后页面回到顶部重新刷新数据
  if (window.scrollY) { window.scrollTo({ top: 0 }) }
  let params = {}
  if (value !== 'all') {
    params = { filterTagId: value }
  }
  try {
    await dispatch('cart_v2/fetchCartIndex', params)
    // 当进行筛选操作，停止低效用户监测
    monitorUserInteraction.value?.stop?.({ reason: 'click filter tag' })
    embedLoadingShow.value = false
  } catch(e) {
    console.log('error', e)
  }
  
}

const isChecked = (goodsIdList = [])  => {
  return goodsIdList.filter(id => !batchEditCartItemIds.value.includes(id)).length ? false : true
}

const anchorToMall = (operation) => {
  const mallHeadEle = document.querySelector(`.j-warehouse-container-${operation?.mallCode}`)
  const mallFirstHeadEle = document.querySelectorAll('.j-warehouse-container')?.[0]
  if (!mallHeadEle || !mallFirstHeadEle) return
  commit('cart_v2/updateState', {
    key: 'renderToCartId',
    value: cartIds.value?.[cartIds.value?.length - 1],
  })
  nextTick(() => {
    const fitTop = document.querySelector('.j-cart-header-wrap')?.clientHeight + (filterTagId.value == 'all' ? 0 : (CartListFilterRef.value?.$el?.clientHeight ?? 0)) + 8
    if (mallFirstHeadEle === mallHeadEle) {
      window.scrollTo({ top: 0,  behavior: 'smooth'  })
    } else {
      window.scrollTo({ top: mallHeadEle.getBoundingClientRect()?.top + window.scrollY - fitTop,  behavior: 'smooth'  })
    }
  })
}

</script>
<style lang="less">
.warehouse-group{
  margin: 16/75rem 12/75rem 0;
  .full-type-group{
    margin: 0;
  }
  .store-group-gap{
    margin-top: 4/75rem;
    padding: 0 0.32rem;
    height: 1px;
    background: #fff;
  }
  .S-checkbox{
    margin-right: var(--checkbox-margin-right, 20/75rem);
  }
}
.noplatform-warehouse-group{
  .cart-list-item + .cart_item-header, .cart-list-item + .store-header-container, .cart-list-item + .full-type-group{
    position: relative;
    margin-top: 16/75rem;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100% - .533335rem");
      border-bottom: none;
      top: 0;
    }
  }
  .full-type-group + .cart_item-header, .full-type-group + .store-header-container{
    position: relative;
    margin-top: 16/75rem;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100% - .533335rem");
      border-bottom: none;
      top: 0;
    }
  }
}
.noplatform-show-store{
  .cart-list-item + .cart_item-header{
    position: relative;
    margin-top: 0;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100% - .533335rem");
      border-bottom: .5px solid #E5E5E5;
      top: 0;
    }
  }
}
.platform-warehouse-group{
  .cart-list-item + .cart_item-header{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100% - .533335rem");
      border-bottom: .5px solid #E5E5E5;
      top: 0;
    }
  }
  .cart-list-item:last-child{
    background: transparent;
    >div{
      border-radius: 0 0 16/75rem 16/75rem;
    }
  }
  .cart-list-item + .store-header-container{
    border-top: .5px solid #E5E5E5;
  }
  .not-promotion-item  + .cart_item-header{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100%");
      border-bottom: .5px solid #E5E5E5;
      top: 0;
      left: 0;
    }
  }
}
.header-platform + .header-platform{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    width: calc(~"100%");
    border-bottom: .5px solid #E5E5E5;
    top: 0;
  }
}
.full-type-group{
  padding-bottom: 32/75rem;
  background: #fff;
  margin: 0 16/75rem;
  & +  .full-type-group{
    margin-top: 20/75rem;
  }
  &.header-platform{
    margin: 0;
    padding: 0;
    & +  .full-type-group{
      margin-top: 0;
    }
    .cart_item-header{
      margin: 0;
      padding: 0;
    }
    .cart_item-banner{
      padding: 20/75rem;
      border-bottom: 0.5px solid #E5E5E5;
    }
    .no-border{
      border-bottom: 0
    }
    .is-single-mall{
      padding: 20/75rem 24/75rem;
    }
  }
}
.cart_item-header{
  padding: 32/75rem 24/75rem 0 20/75rem;
  background-color: #FFFFFF;
  display: flex;
  &.new-promotion-header{
    background-color: @sui_color_promo_bg;
    padding: 20/75rem;
    &.new-cartrow-vision {
      padding-left: 16/75rem;
    }
    .cart_item-banner{
      padding: 0;
    }
  }
}
.cart-item__title-banner,.cart_item-banner{
  background-color: @sui_color_promo_bg;
  padding: 32/75rem 24/75rem;
  flex: 1;
  .cart_item-title{
    font-size: 14px;
    color: #222;
    font-weight: 700;
  }
  .cart_item-tip{
    color: #222;
    font-size: 12px;
    flex: 1;
    line-height: 1.2;
    &.cart_tip-red {
      color: #bf4123;
    }
    em {
      color: @sui_color_welfare_dark;
    }
  }
  .cart_item-desc{
    color: #222;
    font-size: 12px;
    line-height: 1.2;
    em{
      color: @sui_color_welfare_dark;
    }
  }
  .count-down-time{
    .time-content{
      font-weight: bold;
    }
  }
}
.title-action-operation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .cart_item-action{
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    color: #222;
    display: inline-flex;
    align-items: center;
    i{
      font-weight: bold;
    }
  }
  .promotion-tip-time{
    flex: 1;
    text-align: left;
    .cart_item-desc,.down-time{
      padding: 0 20/75rem 0;
    }
  }
  .is-single-mall{
    .cart_item-desc,.down-time{
      padding: 0 24/75rem 0 16/75rem;
    }
  }
  .new-promotion-header__icon{
    margin-right: 8/75rem;
    width: 30/75rem;
    height: 30/75rem;
    line-height: 1;
    font-size: 15px!important; /* stylelint-disable-line declaration-no-important */
  }
  .promotion-header-content{
    flex: 1;
  }
}
.item-content-icon {
  width: 32/75rem;
  height: 32/75rem;
  line-height: 1;
}
.add-completed-img {
  width: 10rem;
  display: block;
  position: fixed;
  top: 0;
  opacity: .5;
  z-index: 10000; /* stylelint-disable-line declaration-property-value-blacklist */
  &.add-completed-keyframes {
    animation-name: add-completed-transform-keyframes;
    animation-duration: 500ms;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: 0 0;
  }
}

@keyframes add-completed-transform-keyframes {
  0% {transform:translate(0px, 0px) scale(1) ;animation-timing-function: cubic-bezier(.25,.25,.75,.75);}
  100% {transform:translate(8.6rem, .4rem) scale(0);}
}

.add-completed__img-reverse {
  &.add-completed-keyframes {
    animation-name: add-completed-transform-keyframes-reverse;
    animation-duration: 500ms;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: 0 0;
  }
}
@keyframes add-completed-transform-keyframes-reverse {
  0% {transform:translate(0px, 0px) scale(1) ;animation-timing-function: cubic-bezier(.25,.25,.75,.75);}
  100% {transform:translate(1.3rem, .4rem) scale(0);}
}

.negative-information-enter{
  display: flex;
  align-items: center;
  padding: 20/75rem 8/75rem 20/75rem 0;
  font-size: 12px;
  color: @sui_color_gray_dark3;
  i{
      margin-right: 8/75rem;
  }
  .information-enter-tip{
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    padding-right: 4/75rem;
  }
  .information-enter-action{
    color: @sui_color_link;
  }
  &.multi-mall{
    background-color: #fff;
    border-top: 0.5px solid #e5e5e5;
    border-radius: 0 0 0.21333333rem 0.21333333rem;
    padding: 20/75rem;
  }
}
</style>
