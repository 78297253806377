var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"warehouse-container",class:`j-warehouse-container j-warehouse-container-${_vm.mallCode}`},[_c('div',{staticClass:"warehouse-content"},[_vm._t("default"),_vm._v(" "),_c('div',{staticClass:"warehouse-title"},[_c('span',{staticClass:"mall-name"},[_vm._v(_vm._s(_vm.mallName))]),_vm._v(" "),(_vm.mallDesc)?_c('s-label',{staticStyle:{"font-weight":"normal"},attrs:{"type":"success"}},[_vm._v("\n        "+_vm._s(_vm.mallDesc)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.mallQuickOperation && _vm.mallQuickOperation.switchTips)?_c('span',{directives:[{name:"expose",rawName:"v-expose",value:({
          id: 'expose_mall_quickoperation.page_cart',
          data: {
            mall_code: _vm.mallCode
          }
        }),expression:"{\n          id: 'expose_mall_quickoperation.page_cart',\n          data: {\n            mall_code: mallCode\n          }\n        }"},{name:"tap",rawName:"v-tap",value:({
          id: 'click_mall_quickoperation.page_cart',
          data: {
            mall_code: _vm.mallCode
          }
        }),expression:"{\n          id: 'click_mall_quickoperation.page_cart',\n          data: {\n            mall_code: mallCode\n          }\n        }"}],staticClass:"quick-anchor",on:{"click":function($event){return _setup.emits('anchorToMall')}}},[_c('span',{staticClass:"text"},[_vm._v("\n          "+_vm._s(_vm.mallQuickOperation.switchTips)+"\n        ")]),_vm._v(" "),_c('img',{attrs:{"src":_vm.mallQuickOperation.switchIcon,"width":"12","height":"12"}})]):_vm._e()],1)],2),_vm._v(" "),_c(_setup.MallShipping,{attrs:{"mall-code":_vm.mallCode,"language":_vm.language,"shipping-activity-data":_vm.shippingActivityData,"locasl":_vm.locals}}),_vm._v(" "),(_setup.fullPromotion.fullCourtMap && _setup.fullPromotion.fullCourtMap.length)?_c(_setup.MallFullCourtMap,{attrs:{"mall-code":_vm.mallCode,"language":_vm.language,"full-court-map":_setup.fullPromotion.fullCourtMap,"locasl":_vm.locals}}):_vm._e(),_vm._v(" "),_vm._l((_setup.fullPromotion.additionGoodsPromotionList),function(promotion){return [(promotion.data.type_id == 4)?_c(_setup.FullGiftHeader,{key:promotion.data.promotion_id,attrs:{"promotion-info":promotion,"language":_vm.language,"mall-code":_vm.mallCode,"locals":_vm.locals,"is-single-mall":false}}):_vm._e(),_vm._v(" "),(promotion.data.type_id == 22)?_c(_setup.FullAddOnHeader,{key:promotion.data.promotion_id,attrs:{"promotion-info":promotion,"language":_vm.language,"mall-code":_vm.mallCode,"locals":_vm.locals,"is-single-mall":false}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }