<template>
  <div
    v-if="checkoutBtnLureInfo"
    v-show="showCheckoutBtnLureInfo"
    class="button-incentive-text"
  >
    <img
      :src="checkoutBtnLureInfo.prefixIcon"
      is-responsive-name
      class="incentive-icon"
    />
    <span
      :class="[
        'incentive-text',
      ]"
      :style="{
        color: checkoutBtnLureInfo.textColor || '#fa6338'
      }"
    >
      {{ checkoutBtnLureInfo.text }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from '../../hooks/useStore'

const props = defineProps({
  controlCheckoutNameText: {
    type: String,
    default: ''
  }
})

const { state } = useStore()

const checkoutBtnLureInfo = computed(() => {
  return state.checkoutState.cartLureInfo?.checkoutBtnLureInfo
})
const showCheckoutBtnLureInfo = computed(() => {
  if(props.controlCheckoutNameText) return ''
  return true
})

</script>

<style scoped lang="less">
.button-incentive-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4/75rem;

  max-width: 268/75rem;
  overflow: hidden;
  .incentive-text {
    font-size: 11px;
    font-weight: 510;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .incentive-icon{
    height: 22/75rem;
    max-width: 22/75rem;
    min-width: 16/75rem;
  }
}
</style>
