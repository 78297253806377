<template>
  <div
    v-if="shippingTip"
    class="shipping-header-container"
  >
    <div
      class="shipping-header"
    >
      <sui_icon_shipping_18px
        class="item-content-icon"
        size="18px"
        :is-rotate="locals.GB_cssRight"
      />
      <div class="shipping-content">
        <span
          class="shipping-tip"
          v-html="shippingTip"
        ></span>
        <ClientOnly>
          <count-down-time
            v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
            :time-stamp="+firstFreeShipping.countDownTime"
            :is-left-time="true"
          />
        </ClientOnly>
      </div>
      <span
        v-if="showShipSupplement"
        class="shipping-operation"
        role="button"
        tabindex="0"
        da-event-expose="1-8-7-11"
        da-event-click="1-8-7-12"
        :data-shipping_free_type="firstFreeShipping ? '' : '3'"
        :data-mall_code="mallCode"
        @keydown.stop.enter="clickCartAdd"
        @click="clickCartAdd"
      >
        {{ language.SHEIN_KEY_PWA_15091 }}
        <sui_icon_more_right_12px
          size="12px"
          :is-rotate="locals.GB_cssRight"
        />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MallShipping',
}
</script>
<script setup>
import { computed, defineProps } from 'vue'
import { sui_icon_shipping_18px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'
import CountDownTime from './../common/CartCountDownTime.vue'
import ClientOnly from 'vue-client-only'
import { useCartAddItem }  from 'public/src/pages/cart_v2/hooks/useCartAddItem.js'
const AddItem = useCartAddItem()

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  shippingActivityData: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  }
})

const firstFreeShipping = computed(() => { // 首单免邮
  return props.shippingActivityData?.freeType == 1 ?  props.shippingActivityData || {} : {}
})

const shippingTip = computed(() => { // 平台型免邮文案 
  return props.shippingActivityData?.tip || ''
})

const showShipSupplement = computed(() => {
  return props.shippingActivityData?.isOpenDiff == 1 ? true : false
})
const clickCartAdd = () => {
  AddItem.show({
    popupInfoId: props.shippingActivityData?.popupInfoId,
    state: 'shipping_add',
  })

}
</script>
<style lang="less">
.shipping-header-container{
  background-color: #FFFFFF;
  .shipping-header{
    color: #222222;
    display: flex;
    background: @sui_color_safety_bg;
    padding: 20/75rem;
    align-items: center;
    justify-content: space-between;
    border-bottom: .5px solid #E5E5E5;
  }
  .shipping-tip{
    flex: 1;
    font-size: 12px;
    line-height: 1.5;
    word-break: break-word;
    strong{
      font-weight: 400;
      color: @sui_color_highlight;
    }
  }
  .shipping-operation{
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  .shipping-content{
    flex: 1;
    padding: 0 20/75rem 0;
  }
}
</style>
