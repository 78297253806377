import { ref, onMounted } from 'vue'

export const useCacheClose = ({ cacheKey = '', defaultClose = true } = {}) => {
  const isCacheClose = ref(defaultClose)

  onMounted(() => {
    isCacheClose.value = !!sessionStorage.getItem(cacheKey)
  })

  const handleClickClose = () => {
    sessionStorage.setItem(cacheKey, 'true')
    isCacheClose.value = true
  }

  return {
    isCacheClose,
    handleClickClose,
  }
}
