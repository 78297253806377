<template>
  <div>
    <s-popover
      v-model="showCheckoutBtnPopover"
      trigger-type="user"
      placemen="top-end"
      :show-close-icon="true"
      :prop-style="popoverStyle"
      @handleCloseIconClick="handleCloseIconClick"
    >
      <IncentivePopoverContent :show="showCheckoutBtnPopover" />
      <div
        slot="reference"
        :class="[
          'checkout-btn-contain',
        ]"
      >
        <slot></slot>
      </div>
    </s-popover>
  </div>
</template>

<script>
export default {
  name: 'CheckoutIncentivePopover',
}
</script>

<script setup>
import { computed, defineEmits } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { useStore } from '../../hooks/useStore'
import IncentivePopoverContent from '../cartCheckoutIncentive/IncentivePopoverContent.vue'
import checkoutIncentive from '../cartCheckoutIncentive/checkoutIncentive.js'

const props = defineProps({
  showFilterLabelPopover: {
    type: String,
    default: ''
  }
})

const { state } = useStore()

const content = computed(() => state.checkoutState.currentCartLure || {})

const emits = defineEmits(['set-popover-type'])

const popoverStyle = computed(() => {
  const locals = state.locals
  return {
    zIndex: 30,
    bottom: '64px',
    top: 'unset',
    position: 'fixed',
    right: locals.GB_cssRight ? 'auto' : '0.32rem',
    left: locals.GB_cssRight ? '0.32rem' : 'auto',
  }
})

const showCheckoutBtnPopover = computed({
  get() {
    return Boolean(props.showFilterLabelPopover)
  },
  set(val) {
    if(!val) emits('set-popover-type', '')
  }
})

const handleCloseIconClick = () => {
  checkoutIncentive.triggerHide()

  daEventCenter.triggerNotice({
    daId: '1-8-3-61',
    extraData: {
      actual_point: content.value.biData?.name,
      information: content.value.biData?.information || '',
    }
  })
}
</script>
