<template>
  <div 
    class="free-ship-bar"
    :class="{
      'free-ship-bar_activity': isBlackFriday
    }"
  >
    <div class="wrapper">
      <slot name="prefix">
        <Icon
          class="ico"
          :name="showAdd ? 'sui_icon_shipping_16px' : 'sui_icon_selected_16px'"
          is-responsive-name
          size="16px" 
        />
      </slot>
      <div class="middle">
        <div 
          class="tips" 
          v-html="htmlTips"
        >
        </div>
        <div
          v-if="countDown.show"
          :class="{'float-count-down': floatCountDown}"
        >
          <CountDown
            :time-stamp="countDown.timeStamp"
            :type="countDown.type"
          />
        </div>
      </div>
      <div 
        v-if="actionText"
        class="suffix"
        @click="handleAction"
      >
        <span>
          {{ actionText }}
        </span>
        <Icon
          name="sui_icon_more_right_12px"
          size="12px" 
          :is-rotate="GB_cssRight"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from '@shein-aidc/icon-vue2'
import CountDown from 'public/src/pages/cart_v2/components/common/countDown.vue'
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { GB_cssRight } = gbCommonInfo

export default {
  name: 'FreeShipBar',
  components: {
    Icon,
    CountDown,
  },
  props: {
    showWay: {
      type: String,
      default: '-',
    },
    promoInfo: {
      type: Object,
      default: () => ({})
    },
    floatCountDown: {
      type: Boolean,
      default: false,
    },
    isInModal: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['handleAddOn'],
  data() {
    return {
      GB_cssRight,
    }
  },
  computed: {
    ...mapState('cart_v2', ['language']),
    ...mapGetters('cart_v2', ['isShowCartActivityColor']),
    isBlackFriday() {
      return this.isShowCartActivityColor && !this.isInModal
    },
    showAdd() {
      return this.promoInfo.isAddItem
    },
    htmlTips() {
      return this.promoInfo.freeShippingTips
    },
    countDown() {
      return {
        show: !!this.promoInfo.showCountDown,
        timeStamp: this.promoInfo.countDownTime,
        type: this.floatCountDown ? 'green' : 'white',
      }
    },
    actionText() { // 操作按钮文案
      return this.showAdd ? this.language.SHEIN_KEY_PWA_15091 : null
    },
  },
  watch: {
    htmlTips: {
      handler() {
        if (typeof window == 'undefined') return
        this.sendSa(this.promoInfo?.isFreight ? '1-8-5-5' : '1-8-5-3')
      },
      immediate: true,
    }
  },
  methods: {
    handleAction() {
      this.sendSa(this.promoInfo?.isFreight ? '1-8-5-6' : '1-8-5-4')
      this.handleAddOn(this.promoInfo)
    },
    sendSa(daId) {
      const extraData = {
        type: this.promoInfo.type,
        is_fullshippingactivity: this.showAdd ? 0 : 1,
        show_way: this.showWay,
        show_position: this.floatCountDown ? 2 : 3,
        origin_price: this.promoInfo?.originPrice?.amountWithSymbol,
        mall_code: this.promoInfo?.mallCode,
        shipping_method: this.promoInfo?.shippingMethod,
        shipping_free_type: this.promoInfo?.shippingFreeType,
      }
      daEventCenter.triggerNotice({
        daId: daId,
        extraData,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.free-ship-bar {
  position: relative;
  background: @sui_color_safety_bg;
  .wrapper {
    padding: .27rem .32rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ico {
    padding-right: .21rem;
  }
  .middle {
    flex: 1;
    text-align: left;
    .tips {
      color: #222;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: initial;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .suffix {
    padding-left: .32rem;
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    color: #222;
  }

  .float-count-down {
    position: absolute;
    right: 0rem;
    top: -0.34rem;
  }
}

.free-ship-bar.free-ship-bar_activity {
  border-radius: 12/37.5rem 12/37.5rem 0 0;
  background: @sui_color_safety;
  color: @sui_color_white;
  .tips, .suffix {
    color: @sui_color_white;
  }
  /deep/ font {
    color: @sui_color_white;
    font-weight: bold;
  }
}
</style>
