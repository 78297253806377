<template>
  <div
    class="store-header-container"
    :class="{'new-cartrow-vision': isNewCartrowVision}"
    da-expose-code="cart-shop-name"
  >
    <div class="store-header_left">
      <slot></slot>
      <div
        v-tap="analysisData('1-8-7-45')"
        v-expose="analysisData('1-8-7-46')"
        class="store-header-content"
        @click="toShop"
      >
        <div class="store-header">
          <template v-if="preferredSellerStore == 1">
            <div class="seller-store-icon">
              <preferred-seller-store-icon :locals="locals" />
            </div>
          </template>
          <template v-else>
            <component
              :is="isNewStoreIcon ? sui_icon_me_shop_15px : sui_icon_store_15px"
              size="15px"
              class="store-icon"
            />
          </template>
          <span class="store-title">{{ shopName }}</span>
          <img
            v-if="!!storeTrendLogo"
            class="trend-icon"
            :src="storeTrendLogo"
          />
          <sui_icon_more_right_12px
            v-if="shopName.toLowerCase() != 'shein' && storeCode != 'shein' && storeCode != 1"
            size="12px"
            :is-rotate="locals.GB_cssRight"
          />
        </div>
      </div>
    </div>

    <!-- 领取优惠券入口 -->
    <ClientOnly>
      <GetCoupon
        v-if="showGetCouponEntry"
        class="store-get-coupon"
        :store-code="storeCode"
        :mall-code="mallCode"
      />
    </ClientOnly>
  </div>
</template>
<script>
export default {
  name: 'StoreHeader',
}
</script>

<script setup>
import { computed, defineProps, ref } from 'vue'
import preferredSellerStoreIcon from 'public/src/pages/cart_v2/components/common/preferredSellerStoreIcon.vue'
import ClientOnly from 'vue-client-only'
import GetCoupon from '@/public/src/pages/cart_v2/components/getCoupon/index.vue'
import { stringifyQueryString } from '@shein/common-function'
import { sui_icon_me_shop_15px, sui_icon_store_15px, sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'
import { useRouter } from '../../hooks/useRouter'  
import { markPoint } from 'public/src/services/mark/index.js'
import { useStore } from 'public/src/pages/cart_v2/hooks/useStore'

const { state } = useStore() 
const { langPath } = gbCommonInfo
const { router } = useRouter()

const props = defineProps({
  shopName: {
    type: String,
    default: ''
  },
  storeCode: {
    type: String,
    default: ''
  },
  storeType: {
    type: [String, Number],
    default: ''
  },
  storeTrendLogo: {
    type: String,
    default: ''
  },
  hasStoreCoupon: {
    type: Boolean,
    default: false,
  },
  preferredSellerStore: {
    type: [String, Number],
    default: 0
  },
  storeRouting: {
    type: String,
    default: ''
  },
  isNewStoreIcon: {
    type: Boolean,
    default: false
  },
  isNewCartrowVision: {
    type: Boolean,
    default: false
  },
  locals: {
    type: Object,
    default() {
      return {}
    }
  },
  mallCode: {
    type: [String, Number],
    default: ''
  }
})

const toShop = () => {
  if (props.storeRouting){
    let url = props.storeRouting + (props.storeRouting.indexOf('?') > -1 ? '&' : '?') + stringifyQueryString({
      queryObj: {
        rule_poskey: 'CartShopItemList',
      }
    }) 
    markPoint('toNextPageClick', 'public')
    router.push(langPath + url)
  }
}

const analysisData = (id) =>{
  return {
    id: id,
    once: false,
    data: {
      store_code: props.storeCode,
      store_tp: props.storeType == 1 ? 1 : 2,
      preferred_seller: props.preferred_seller_store == 1 ? 1 : 0,
      store_label: props.storeTrendLogo ? 'trends' : '-',
    }
  }
}

// shop领券入口
const isPlatForm = computed(() => state.isPlatForm)
const showGetCouponsByAbt = computed(() => state.cartAbtInfo?.CartGetCoupons?.p === 'request=yes&show=yes')
// const hasPlatformInMallTags = computed(() => state.hasPlatformInMallTags)
let showGetCouponEntry = computed(() => {
  // console.log('--showGetCouponEntry', isPlatForm.value, showGetCouponsByAbt.value, hasPlatformInMallTags.value, props.hasStoreCoupon)
  // if (showGetCouponsByAbt.value && hasPlatformInMallTags.value == '1' && props.hasStoreCoupon) {
  //   return true
  // }
  if (isPlatForm.value && showGetCouponsByAbt.value && props.hasStoreCoupon && props.storeType == '1') {
    return true
  }
  return false
})


</script>

<style lang="less" scoped>
.store-header-container {
  background-color: #FFFFFF;
  line-height: 17px;
  padding: 16/75rem 20/75rem 8/75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.new-cartrow-vision {
    padding-left: 16/75rem;
  }
  .store-header_left {
    display: flex;
    flex:1;
    align-items: center;
    overflow: hidden;
  }
  .store-header-content {
    position: relative;
    flex: 1;
    overflow: hidden;
  }
  .store-header {
    cursor: pointer;
    display: flex;
    align-items: center;
    .store-icon {
      margin-right: 4/75rem;
    }
    .store-title {
      color: #222222;
      font-size: 14px;
      padding: 0 4/75rem 0;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      position: relative;
    }
    .seller-store-icon {
      display: inline-block;
      width: 32/75rem;
    }
  }
  .store-get-coupon {
    margin-left: 8/37.5rem;
    white-space: nowrap;
  }
  .trend-icon {
    width: 37/37.5rem;
    height: 12/37.5rem;
    margin-left: 2/37.5rem;
  }
}
</style>
