<template>
  <div
    class="bottom-common" 
    @click="handleViewMore"
  >
    <Icon
      name="sui_icon_activity_18px"
    />
    <span
      class="tips"
      v-html="tips"
    ></span>
    <span class="view-more">
      {{ language.SHEIN_KEY_PWA_22740 }}
    </span>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { mapState } from 'vuex'
import { template } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

export default {
  name: 'BottomCommon',
  components: {
    Icon,
  },
  inject: ['fixedUptt'],
  props: {
    list: {
      type: Array,
      default: () => ([])
    },
    showWay: {
      type: String,
      default: '-',
    }
  },
  computed: {
    ...mapState('cart_v2', ['language']),
    tips() {
      return template(`<em>${this.list?.length}</em>`, this.language.SHEIN_KEY_PWA_30499)
    },
  },
  mounted() {
    this.sendSa('expose_cartpromotionadd.page_cart')
    this.fixedUptt()
  },
  methods: {
    sendSa(daId) {
      daEventCenter.triggerNotice({
        id: daId,
        data: {
          show_way: this.showWay,
          newuser_label: '-',
          is_member_gift: 0,
        }
      })
    },
    handleViewMore() {
      this.sendSa('click_cartpromotionadd.page_cart')
      this.$emit('openHub')
    },
  }
}
</script>

<style lang="less" scoped>
.bottom-common {
  position: relative;
  display: flex;
  align-items: center;
  background: url("//img.ltwebstatic.com/images3_acp/2024/03/26/f0/17114409959d41d7c4ef5f82a99e6f236c4e86916f.png") no-repeat;
  background-size: cover;
  height: 48/37.5rem;
  padding: 10/37.5rem 12/37.5rem;
  .tips {
    flex: 1;
    padding: 0 12/37.5rem 0 8/37.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /deep/ em {
      font-weight: bold;
      color: #FA6338;
    }
  }
  .view-more {
    padding: 3/37.5rem 6/37.5rem;
    border: 0.5px solid rgba(0, 0, 0, 0.50);
    border-radius: 12px;
    font-weight: bold;
  }
}
</style>
