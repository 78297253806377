<template>
  <div
    v-if="show"
    key="cart-newGoodsRecommendTip"
    class="cart-newGoodsRecommendTip not-fsp-element"
  >
    <div
      v-if="hasNewRecommendInfo"
      v-expose="{
        id: 'expose_new_recommend.page_cart',
        data: {
          style: getSaStyleByType('tip'),
        },
      }"
      class="cart-newGoodsRecommendTip__wrapper"
    >
      <div
        class="cart-newGoodsRecommendTip__left"
        @click.stop="onClickView('body')"
      >
        <div
          v-if="newRecommendExposureItems.length"
          class="cart-newGoodsRecommendTip__left-img"
        >
          <div class="cart-newGoodsRecommendTip__left-img-new">
            <img
              v-if="newRecommendExposureItems[0] && newRecommendExposureItems[0].goodsImg"
              :src="newRecommendExposureItems[0].goodsImg"
            />
            <span>
              <span>{{ language.SHEIN_KEY_PWA_34564 }}</span>
            </span>
          </div>
          <img
            v-if="newRecommendExposureItems[1] && newRecommendExposureItems[1].goodsImg"
            :src="newRecommendExposureItems[1].goodsImg"
            class="cart-newGoodsRecommendTip__left-img-more"
          />
        </div>
        <div class="cart-newGoodsRecommendTip__left-content">
          <div class="cart-newGoodsRecommendTip__left-title">
            {{ newRecommendExposure.mainText }}
          </div>
          <div class="cart-newGoodsRecommendTip__left-sub">
            {{ newRecommendExposure.subText }}
          </div>
        </div>
        <div
          class="cart-newGoodsRecommendTip__left-view"
          @click.stop="onClickView('view')"
        >
          {{ newRecommendExposure.btnText }}
        </div>
      </div>
      <div
        class="cart-newGoodsRecommendTip__right"
        @click="onClickClose"
      >
        <sui_icon_close_16px_2
          size="18px"
          color="#959595"
          class="cart-newGoodsRecommendTip__right-close"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { sui_icon_close_16px_2 } from '@shein-aidc/icon-vue2'
import { useStore } from '../../hooks/useStore'
import { useNewGoodsRecommend } from './useNewGoodsRecommend'
import { getSaStyleByType } from './utils'
import { useCacheClose } from './useCacheClose'

const { state } = useStore()
const language = computed(() => state.language)

const { isCacheClose, handleClickClose } = useCacheClose({ cacheKey: 'cart-newGoodsRecommendTip__isClose' })

const { hasNewRecommendInfo, newRecommendExposure, newRecommendExposureItems, openNewRecommendDrawer } = useNewGoodsRecommend()

const show = computed(() => !isCacheClose.value)

const onClickView = (type) => {
  daEventCenter.triggerNotice({
    id: 'click_new_recommend.page_cart',
    extraData: {
      style: getSaStyleByType('tip'),
      button_type: type,
    },
  })

  openNewRecommendDrawer({ type: 'tip' })
}

const onClickClose = () => {
  daEventCenter.triggerNotice({
    id: 'click_new_recommend.page_cart',
    extraData: {
      style: getSaStyleByType('tip'),
      button_type: 'close',
    },
  })

  handleClickClose()
}
</script>

<style lang="less">
.cart-newGoodsRecommendTip {
  width: 100%;
  height: 44px;
  background: linear-gradient(180deg, #DFF9DD 0%, #EDFAEC 100%);

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 6px 12px;
    min-width: 0;
  }

  &__left-img {
    flex-shrink: 0;
    position: relative;
    margin-right: 8px;
    width: 40px;
    height: 32px;
  }

  &__left-img-new {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 32px;
    height: 32px;
    border: 1px solid #fff;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0px rgba(#000, 0.16);
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > span {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: 10px;
      line-height: normal;
      text-align: center;
      color: #fff;
      background: @sui_color_new;
      opacity: 0.85;

      > span {
        display: inline-block;
        transform: scale(0.9);
      }
    }
  }

  &__left-img-more {
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 1;
    width: 26px;
    height: 26px;
    object-fit: cover;
    border: 1px solid #fff;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0px rgba(#000, 0.16);
    overflow: hidden;
  }

  &__left-content {
    flex: 1;
    min-width: 0;
    line-height: normal;
    color: @sui_color_success;
  }

  &__left-title {
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__left-sub {
    font-size: 11px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__left-view {
    flex-shrink: 0;
    padding: 0 8px;
    min-width: 40px;
    max-width: 56px;
    height: 24px;
    line-height: 24px;
    font-size: 11px;
    font-weight: 590;
    text-align: center;
    color: #fff;
    background: @sui_color_brand;
    border-radius: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__right {
    display: flex;
    align-items: center;
    padding: 6px 12px 6px 0;
  }
}
</style>
