import { useDiscountLimitTagEvent } from './useDiscountLimitTag.js'
import { useDiscountNormalTagEvent } from './useDiscountNormalTag.js'
import { useEstimatedPriceEvent } from './useEstimatedPrice.js'
import { useGoodsQtyEvent } from'./useGoodsQty.js'
import { useGoodsSaleAttrEvent } from './useGoodsSaleAttr.js'
import { usePromotionTagEvent } from './usePromotionTag.js'
import { useBehaviorLabelEvent } from './useBehaviorLabel.js'

export function useItemEvents() {
  const discountLimitTagEvent = useDiscountLimitTagEvent()
  const discountNormalTagEvent = useDiscountNormalTagEvent()
  const estimatedPriceEvent = useEstimatedPriceEvent()
  const goodsQtyEvent = useGoodsQtyEvent()
  const goodsSaleAttrEvent = useGoodsSaleAttrEvent()
  const promotionTagEvent = usePromotionTagEvent()
  const behaviorLabelEvent = useBehaviorLabelEvent()

  return {
    discountLimitTagEvent,
    discountNormalTagEvent,
    estimatedPriceEvent,
    goodsQtyEvent,
    goodsSaleAttrEvent,
    promotionTagEvent,
    behaviorLabelEvent,
  }
}
