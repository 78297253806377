<template>
  <div 
    class="warehouse-container"
    :class="`j-warehouse-container j-warehouse-container-${mallCode}`"
  >
    <div class="warehouse-content">
      <slot></slot>
      <div class="warehouse-title">
        <span class="mall-name">{{ mallName }}</span>
        <s-label
          v-if="mallDesc"
          type="success"
          style="font-weight: normal"
        >
          {{ mallDesc }}
        </s-label>
        
        <span
          v-if="mallQuickOperation && mallQuickOperation.switchTips"
          v-expose="{
            id: 'expose_mall_quickoperation.page_cart',
            data: {
              mall_code: mallCode
            }
          }"
          v-tap="{
            id: 'click_mall_quickoperation.page_cart',
            data: {
              mall_code: mallCode
            }
          }"
          class="quick-anchor"
          @click="emits('anchorToMall')"
        >
          <span class="text">
            {{ mallQuickOperation.switchTips }}
          </span>
          <img
            :src="mallQuickOperation.switchIcon" 
            width="12" 
            height="12"
          />
        </span>
      </div>
    </div>
    <mall-shipping
      :mall-code="mallCode"
      :language="language"
      :shipping-activity-data="shippingActivityData"
      :locasl="locals"
    />
    <mall-full-court-map
      v-if="fullPromotion.fullCourtMap && fullPromotion.fullCourtMap.length"
      :mall-code="mallCode"
      :language="language"
      :full-court-map="fullPromotion.fullCourtMap"
      :locasl="locals"
    />
    <template v-for="promotion in fullPromotion.additionGoodsPromotionList">
      <full-gift-header
        v-if="promotion.data.type_id == 4"
        :key="promotion.data.promotion_id"
        :promotion-info="promotion"
        :language="language"
        :mall-code="mallCode"
        :locals="locals"
        :is-single-mall="false"
      />
      <full-add-on-header
        v-if="promotion.data.type_id == 22"
        :key="promotion.data.promotion_id"
        :promotion-info="promotion"
        :language="language"
        :mall-code="mallCode"
        :locals="locals"
        :is-single-mall="false"
      />
    </template>
  </div>
</template>
<script>
export default {
  name: 'MallHeader',
}
</script>
<script setup>
import MallShipping from './MallShipping.vue'
import MallFullCourtMap from './MallFullCourtMap.vue'
import FullGiftHeader from './FullGiftHeader.vue'
import FullAddOnHeader from './FullAddOnHeader.vue'
import { computed, defineProps } from 'vue'

const props = defineProps({
  mallName: {
    type: String,
    default: ''
  },
  mallDesc: {
    type: String,
    default: ''
  },
  promotionData: {
    type: Array,
    default: () => ([])
  },
  shippingActivityData: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  },
  mallCode: {
    type: String,
    default: ''
  },
  mallQuickOperation: {
    type: Object,
    default: () => ({})
  },
})

const fullPromotion = computed(() => {
  let promotionList = []
  let additionGoodsPromotionList = []
  props.promotionData?.forEach(item => {
    item?.contentData?.forEach(content => {
      if([14, 21, 26].includes(+content?.groupHeadInfo?.data?.type_id)){
        promotionList.push(content.groupHeadInfo)
      } else {
        additionGoodsPromotionList.push(content.groupHeadInfo)
      }
    })
  })
  return {
    fullCourtMap: promotionList,
    additionGoodsPromotionList
  }
})

const emits = defineEmits(['anchorToMall'])

</script>

<style lang="less" scoped>
.warehouse-container{
  background-color: #FFFFFF;
  .warehouse-title{
    font-weight: 700;
    color: #222222;
    font-size: 16px;
    flex: 1;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
    .mall-name{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      margin-right: 8/75rem;
    }
    .quick-anchor {
      margin-left: auto;
      display: inline-flex;
      align-items: center;
      max-width: 148/37.5rem;
      /* stylelint-disable-next-line selector-max-specificity */
      .text {
        font-size: 12px;
        font-weight: 510;
        margin-right: 2/37.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .warehouse-content{
    padding: 20/75rem;
    display: flex;
    align-items: center;
    background-image: url("/pwa_dist/images/cart/sui_img_mall_bg-dd599b1e8f.png");
    background-color: #f6f6f6;
    background-size: cover;
  }
}
</style>
