var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('s-checkbox-tag',{staticClass:"filter-check-tag",class:{
    'style-default': _vm.labelInfo.style == '0',
    'style-a': _vm.labelInfo.style == '1',
    'style-b': !_vm.inModal && _vm.labelInfo.style == '2',
    'style-b-modal': _vm.inModal && _vm.labelInfo.style == '2',
    'scan-lights': _vm.showScanLights && _vm.labelInfo.groupClassId == 'coupon'
  },attrs:{"style-type":"white","hide-icon":true,"cancel-mark":_vm.cancelMark,"label":_vm.labelInfo.filterTagId}},[_c('div',{staticClass:"filter-label"},[(_vm.showPrefixIcon)?[(_vm.prefixIcon)?_c(_setup.Icon,{staticClass:"prefix-icon custom-prefix-icon",attrs:{"size":"12px","name":_vm.prefixIcon}}):_c('img',{staticClass:"label-icon",attrs:{"src":_setup.getLabelIcon}})]:_vm._e(),_vm._v(" "),(_vm.showDivider)?_c('i',{staticClass:"divider"}):_vm._e(),_vm._v(" "),_c('TransitionGroup',{staticClass:"transition-tag",attrs:{"name":"slide","tag":"div"}},_vm._l((_setup.slideItems),function(item){return _c('div',{key:item,staticClass:"label-tip"},[_vm._v("\n        "+_vm._s(_vm.labelInfo.tip)+"\n      ")])}),0),_vm._v(" "),(_vm.showSuffixIcon && _vm.checked && ['1', '2'].includes(_vm.labelInfo.style))?_c(_setup.Icon,{staticClass:"suffix-icon",attrs:{"name":"sui_icon_close_16px","size":"14px"}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }