import { computed } from 'vue'
import { useStore } from '../../hooks/useStore'

export const useNewGoodsRecommend = (newGoodsRecommendFlagId) => {
  const { state, commit } = useStore()
  const newRecommendInfo = computed(() => state.recommendState.newRecommendInfo)
  const hasNewRecommendInfo = computed(() => !!newRecommendInfo.value)
  const newRecommendExposure = computed(() => newRecommendInfo.value?.newRecommendExposure || {})
  const newRecommendItemList = computed(() => newRecommendInfo.value?.newRecommendItemList || [])
  const newRecommendExposureTarget = computed(() => {
    let target
    if (newGoodsRecommendFlagId?.value) {
      target = newRecommendItemList.value.find(f => f.cartItem.id === newGoodsRecommendFlagId.value)
    } else {
      target = newRecommendItemList.value[0]
    }
    return target || {}
  })
  const newRecommendExposureItems = computed(() => {
    return (newRecommendExposureTarget.value?.recommendItems || []).slice(0, 4)
  })

  const openNewRecommendDrawer = ({ type, selectedCartItemId = '' } = {}) => {
    commit('cart_v2/changeNewGoodsRecommendParams', {
      show: true,
      type,
      selectedCartItemId,
    })
  }

  return {
    hasNewRecommendInfo,
    newRecommendExposure,
    newRecommendItemList,
    newRecommendExposureTarget,
    newRecommendExposureItems,
    openNewRecommendDrawer,
  }
}
