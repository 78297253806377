var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"cardGoodsItem",class:[
    'cart-list__item cart-list-item',
    `j-cart-item-${_vm.item.id}`,
  ]},[(_setup.showNewGoodsRecommendPopover)?_c(_setup.NewGoodsRecommendPopover):_vm._e(),_vm._v(" "),_c('div',{ref:"swiperContainer",class:[
      'cart-swiper',
      { 'not-allow__touch-move': !_setup.allowTouchMove }
    ],on:{"&scroll":function($event){return _setup.scrollEventHandle.apply(null, arguments)}}},[_c('div',{staticClass:"cart-swiper-slide item-content"},[_c('div',{class:['cart-item-v2', 'cart-list__item-container'],style:(_setup.itemStyle)},[(_setup.batchActive)?_c('div',{staticClass:"cart-list__item-checkbox"},[_c('SCheckboxGroup',{staticClass:"batch-checkbox",model:{value:(_setup.state.batchEditItemIds),callback:function ($$v) {_vm.$set(_setup.state, "batchEditItemIds", $$v)},expression:"state.batchEditItemIds"}},[_c('div',{on:{"click":_setup.handleClickItemBatchCheck}},[_c('SCheckbox',{attrs:{"label":_vm.item.id,"disabled":_vm.item.isNewcomerItem,"margin-r":_setup.isNewCartrowVision ? 16 / 75 + 'rem' : 20 / 75 + 'rem'}})],1)])],1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_setup.batchActive && !_vm.notNeedCheckbox),expression:"!batchActive && !notNeedCheckbox"}],staticClass:"cart-list__item-checkbox",style:({ opacity: (_vm.item.aggregateProductBusiness.isPresent == '1' || _vm.item.isPayMemberGift == '1') ? 0 : 1 }),on:{"click":_setup.onClickCheckbox}},[_c('s-checkbox',{attrs:{"label":_vm.item.id,"disabled":_vm.item.isDisabled,"value":_setup.checked,"margin-r":_setup.isNewCartrowVision ? 16 / 75 + 'rem' : 20 / 75 + 'rem'},on:{"change":(res) => _setup.handleCheckedItem(res, _vm.item)}})],1),_vm._v(" "),_c('div',{staticClass:"cart-list__item-content"},[_c(_setup.BMainCartItem,{key:_vm.item.id,staticClass:"bs-cart-item",attrs:{"constant":_setup.constant,"language":_setup.language,"use":_setup.use,"data":_vm.item,"config":_setup.config},on:{"expose":_setup.handleExposeItem}})],1)])]),_vm._v(" "),_c('div',{ref:"cartSwiperOperationTab",class:[
        'operation-tab',
        'j-operation-tab',
        'cart-swiper-slide',
      ]},[(_setup.isShowSwiperSimilar)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:(_setup.expose_left_findsimilar(_vm.item)),expression:"expose_left_findsimilar(item)"}],ref:"findSimilar",staticClass:"opertation-item similar",attrs:{"aria-hidden":"true"},on:{"click":_setup.onSwiperSimilar}},[_c('span',{staticClass:"opertation-text"},[_vm._v(_vm._s(_setup.language.SHEIN_KEY_PWA_16789))])]):_vm._e(),_vm._v(" "),(_setup.isShowSwiperM2W)?_c('div',{ref:"moveToWishlistButton",staticClass:"opertation-item move-to-wishlist",attrs:{"aria-hidden":"true"},on:{"click":_setup.onSwiperM2W}},[_c('span',{staticClass:"opertation-text"},[_vm._v(_vm._s(_setup.language.SHEIN_KEY_PWA_33673))])]):_vm._e(),_vm._v(" "),(_setup.isShowSwiperShare)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:(_setup.expose_share(_vm.item)),expression:"expose_share(item)"}],ref:"shareButton",staticClass:"opertation-item share",attrs:{"aria-hidden":"true"},on:{"click":_setup.onSwiperShare}},[_c('span',{staticClass:"opertation-text"},[_vm._v(_vm._s(_setup.language.SHEIN_KEY_PWA_14817))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"opertation-item delete",attrs:{"aria-hidden":"true"},on:{"click":_setup.onSwiperDelete}},[_c('span',{staticClass:"opertation-text"},[_vm._v(_vm._s(_setup.language.SHEIN_KEY_PWA_15502))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }